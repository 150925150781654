import React from 'react';
import {Redirect} from 'react-router-dom';
import {selectUserProfile} from '_redux/user/user.selectors';
import {useSelector} from 'react-redux';
import {getIndexPageRedirectRoute} from '_helpers/utils';

const IndexPage: React.FC = () => {
  const user = useSelector(selectUserProfile);
  const redirectTo = getIndexPageRedirectRoute(user);
  return <Redirect to={redirectTo} />;
};

export default IndexPage;
