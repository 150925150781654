import {combineReducers} from 'redux';
import {MemberProfile} from '_redux/user/auth/user-auth.types';
import {
  PatientsUpdateStateActions,
  MemberProfileUpdateStateActions,
  PatientsRequestingStateActions,
  PatientsSearchUpdateStateActions,
  PatientsSearchingStateActions,
  PatientNotesUpdateStateAction,
  MemberProfilePrescribersStateAction,
  PatientDetailsPdfStateActions,
  PatientInsuranceStateActions,
} from './patients.types';
import {
  FETCH_PATIENTS_SUCCESS,
  FETCH_PATIENTS_FAILURE,
  VIEW_PATIENT_SUCCESS,
  VIEW_PATIENT_START,
  VIEW_PATIENT_FAILURE,
  GET_STATE_PRESCRIBERS_SUCCESS,
  UPDATE_PATIENT_SUCCESS,
  FETCH_PATIENT_NOTES_START,
  FETCH_PATIENT_NOTES_SUCCESS,
  FETCH_PATIENT_NOTES_FAILURE,
  SEARCH_PATIENTS_SUCCESS,
  SEARCH_PATIENTS_START,
  SEARCH_PATIENTS_FAILURE,
  CLEAR_PATIENTS_SEARCH_RESULT,
  CREATE_PATIENT_NOTE_SUCCESS,
  CLEAR_PATIENT,
  CLEAR_STATE_PRESCRIBERS,
  CLEAR_PATIENT_PDF_DETAILS,
  VIEW_PATIENT_DETAILS_PDF,
  CLEAR_PATIENT_NOTES,
  UPDATE_PATIENT_INSURANCE_START,
  UPDATE_PATIENT_INSURANCE_SUCCESS,
  UPDATE_PATIENT_INSURANCE_FAILURE,
} from './patients.constants';
import {NotesDictionary, NotesIdList} from './notes/patients-notes.types';
import {PrescribersInPatientState} from './profile/patients-profile.types';
import {sortPatientsByCategory} from './patients.utils';

export type PatientsByCategoryState = {
  subscribed: {
    [key: string]: MemberProfile[];
  };
  unverified: {
    [key: string]: MemberProfile[];
  };
  'not-subscribed': {
    [key: string]: MemberProfile[];
  };
  failed: {
    [key: string]: MemberProfile[];
  };
  all: {
    [key: string]: MemberProfile[];
  };
};

type MemberProfileState = MemberProfile | null;

type PatientNotesState = {
  entities: NotesDictionary;
  totalNotes: number;
};

function patientsByCategory(
  state: PatientsByCategoryState = {
    subscribed: {},
    unverified: {},
    'not-subscribed': {},
    failed: {},
    all: {},
  },
  {type, payload}: PatientsUpdateStateActions,
): PatientsByCategoryState {
  switch (type) {
    case FETCH_PATIENTS_FAILURE:
    case FETCH_PATIENTS_SUCCESS: {
      if (payload.category === 'all' && payload.role === 'admin') {
        return sortPatientsByCategory(payload);
      }
      return {
        ...state,
        [payload.category]: {
          ...state[payload.category],
          [payload.numRequest + 1]: payload.patients,
        },
      };
    }
    default:
      return state;
  }
}

function patientsSearchResults(
  state: MemberProfile[] = [],
  {type, payload}: PatientsSearchUpdateStateActions,
): MemberProfile[] {
  switch (type) {
    case SEARCH_PATIENTS_SUCCESS:
    case CLEAR_PATIENTS_SEARCH_RESULT:
      return payload;
    default:
      return state;
  }
}

function memberProfile(
  state: MemberProfileState = null,
  {type, payload}: MemberProfileUpdateStateActions,
): MemberProfileState {
  switch (type) {
    case VIEW_PATIENT_SUCCESS:
    case UPDATE_PATIENT_SUCCESS:
    case CLEAR_PATIENT:
      return payload;
    default:
      return state;
  }
}

function patientQuestionnairePdf(
  state: Blob | null = null,
  {type, payload}: PatientDetailsPdfStateActions,
): Blob | null {
  switch (type) {
    case VIEW_PATIENT_DETAILS_PDF:
    case CLEAR_PATIENT_PDF_DETAILS:
      return payload;
    default:
      return state;
  }
}

function patientStatePrescribers(
  state: PrescribersInPatientState = [],
  {type, payload}: MemberProfilePrescribersStateAction,
): PrescribersInPatientState {
  switch (type) {
    case GET_STATE_PRESCRIBERS_SUCCESS:
    case CLEAR_STATE_PRESCRIBERS:
      return payload;
    default:
      return state;
  }
}

function patientNotesById(
  state: PatientNotesState = {entities: {}, totalNotes: 0},
  action: PatientNotesUpdateStateAction,
): PatientNotesState {
  switch (action.type) {
    case FETCH_PATIENT_NOTES_SUCCESS:
    case CREATE_PATIENT_NOTE_SUCCESS:
      return {
        ...state,
        entities: {...state.entities, ...action.payload.entities.notes},
        totalNotes: action.payload.totalNotes,
      };
    case CLEAR_PATIENT_NOTES:
      return {entities: {}, totalNotes: 0};
    default:
      return state;
  }
}

function allPatientNotesIds(
  state: NotesIdList = [],
  action: PatientNotesUpdateStateAction,
): NotesIdList {
  switch (action.type) {
    case FETCH_PATIENT_NOTES_SUCCESS:
    case CREATE_PATIENT_NOTE_SUCCESS:
      return Array.from(new Set([...state, ...action.payload.result]));
    case CLEAR_PATIENT_NOTES:
      return [];
    default:
      return state;
  }
}

function patientsRequesting(
  state = false,
  {type}: PatientsRequestingStateActions,
): boolean {
  switch (type) {
    case VIEW_PATIENT_START:
    case FETCH_PATIENT_NOTES_START:
      return true;
    case VIEW_PATIENT_SUCCESS:
    case VIEW_PATIENT_FAILURE:
    case FETCH_PATIENT_NOTES_SUCCESS:
    case FETCH_PATIENT_NOTES_FAILURE:
      return false;
    default:
      return state;
  }
}

function patientsSearching(
  state = false,
  {type}: PatientsSearchingStateActions,
): boolean {
  switch (type) {
    case SEARCH_PATIENTS_START:
      return true;
    case SEARCH_PATIENTS_SUCCESS:
    case SEARCH_PATIENTS_FAILURE:
      return false;
    default:
      return state;
  }
}

function patientInsurance(
  state = {isLoading: false},
  action: PatientInsuranceStateActions,
) {
  switch (action.type) {
    case UPDATE_PATIENT_INSURANCE_START:
      return {isLoading: true};
    case UPDATE_PATIENT_INSURANCE_SUCCESS:
      return {isLoading: false};
    case UPDATE_PATIENT_INSURANCE_FAILURE:
      return {isLoading: false};
    default:
      return state;
  }
}

const patientNotes = combineReducers({
  byId: patientNotesById,
  allIds: allPatientNotesIds,
});

const currentPatientReducer = combineReducers({
  profile: memberProfile,
  notes: patientNotes,
  pdf: patientQuestionnairePdf,
  statePrescribers: patientStatePrescribers,
});

const patientsReducer = combineReducers({
  lists: patientsByCategory,
  current: currentPatientReducer,
  requesting: patientsRequesting,
  searchResults: patientsSearchResults,
  searching: patientsSearching,
  insurance: patientInsurance,
});

export default patientsReducer;
