import styled from 'styled-components';

const BaseLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .pushable:not(body) {
    transform: none;
  }

  .pushable:not(body) > .ui.sidebar,
  .pushable:not(body) > .fixed,
  .pushable:not(body) > .pusher:after {
    position: fixed;
  }
`;

export default BaseLayoutContainer;
