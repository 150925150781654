import Dexie from 'dexie';
import {Post} from '_redux/posts/posts.types';
import {NormalizedAvailabilityInfo} from '_redux/appointments/appointments.types';
import log from 'loglevel';
import {isIndexedDBAvailable} from './dexie-utils';

export class AppCache extends Dexie {
  posts!: Dexie.Table<Post, number>;
  availabilityInfo!: Dexie.Table<
    NormalizedAvailabilityInfo & {
      email: string;
    },
    string
  >;

  constructor() {
    super('WellniteDB');

    if (!isIndexedDBAvailable) {
      log.info("This browser doesn't support IndexedDB");
      return;
    }

    this.version(1).stores({
      posts: 'userPost.postId, userPost.date',
      moreData: '',
      chat: 'messageId, chatOwner, timeOfMessage',
    });

    this.version(2).stores({
      patients: 'email, date',
    });

    this.version(3).stores({
      patients: 'email, date, verified, subscriptionStatus',
    });

    this.version(4).stores({
      patients: 'email, date, verified, subscriptionStatus, doctorName',
    });

    this.version(5).stores({
      patients: 'email, date, subscriptionStatus',
    });

    this.version(6).stores({
      availabilityInfo: 'appointmentType',
    });

    this.posts = this.table('posts');
    this.availabilityInfo = this.table('availabilityInfo');
  }
}
