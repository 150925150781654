import {put, call} from 'redux-saga/effects';
import {normalize} from 'normalizr';
import * as schema from '_redux/schema';
import requestHandler from '_services/api/axios-config';
import {SagaIterator} from 'redux-saga';
import {HttpMethods} from '_services/api/axios.types';
import history from '_helpers/history';
import {toast} from 'react-toastify';
import log from 'loglevel';
import {UserRoles} from '_redux/user/auth/user-auth.types';
import {getUpdatedUserStart} from '_redux/user/auth/user-auth.actions';
import {removePatientApptsBeforeNow} from '_helpers/utils';
import {
  FetchPatientAppointmentsStartAction,
  FetchAvailabilityInfoStartAction,
  FetchProviderAppointmentsStartAction,
  BookAppointmentAction,
  CancelPatientAppointmentStartAction,
} from './appointments-actions.types';
import {
  fetchPatientAppointmentsSuccess,
  fetchPatientAppointmentsFailure,
  fetchProviderAppointmentsSuccess,
  fetchProviderAppointmentsFailure,
  fetchAvailabilityInfoSuccess,
  fetchAvailabilityInfoFailure,
  cancelPatientAppointmentSuccess,
} from './appointments.actions';
import {AppointmentTypes} from './appointments.types';

export function* fetchPatientAppointmentsStartSaga({
  payload: {numRequest},
}: FetchPatientAppointmentsStartAction): SagaIterator<void> {
  try {
    const {
      data: {
        message: {appointments},
      },
    } = yield call(requestHandler, {
      method: HttpMethods.GET,
      url: `/api/patient/acuity/appointments/?numRequest=${numRequest}`,
    });
    const filteredAppointments = yield call(
      removePatientApptsBeforeNow,
      appointments,
    );
    yield put(fetchPatientAppointmentsSuccess(filteredAppointments));
  } catch (error) {
    yield put(fetchPatientAppointmentsFailure());
  }
}

export function* fetchProviderAppointmentsStartSaga({
  payload: {userRole},
}: FetchProviderAppointmentsStartAction): SagaIterator<void> {
  try {
    const {
      data: {
        message: {appointments},
      },
    } = yield call(requestHandler, {
      method: HttpMethods.GET,
      url: `/api/${userRole}/acuity/appointments/?numRequest=0`,
    });
    yield put(fetchProviderAppointmentsSuccess(appointments));
  } catch (error) {
    yield put(fetchProviderAppointmentsFailure());
  }
}

export function* fetchAvailabilityInfoStartSaga({
  payload: {appointmentType, yearAndMonth, email, callback},
}: FetchAvailabilityInfoStartAction): SagaIterator<void> {
  try {
    const {
      data: {
        message: {appointmentTypeID, calendarAvailability},
      },
    } = yield call(requestHandler, {
      method: HttpMethods.GET,
      url: `/api/patient/acuity/available-time/?typeofAppointment=${appointmentType}&availableMonth=${yearAndMonth}&email=${email}`,
    });
    const normalizedAvailabilityInfo = yield call(
      normalize,
      calendarAvailability,
      schema.arrayOfAvailabilityInfo,
    );
    yield put(
      fetchAvailabilityInfoSuccess({
        ...normalizedAvailabilityInfo,
        appointmentType,
        appointmentTypeId: appointmentTypeID,
      }),
    );
    yield call(callback);
  } catch (error) {
    yield put(fetchAvailabilityInfoFailure());
  }
}

export function* bookAppointmentSaga({
  payload: {
    appointmentDateTimeString,
    providerId,
    appointmentType,
    calendarId,
    patientTimezone,
    appointmentTypeID,
    patientEmail,
    setSubmitting,
  },
}: BookAppointmentAction): SagaIterator<void> {
  try {
    yield call(requestHandler, {
      method: HttpMethods.POST,
      url: '/api/patient/acuity/book-appointment',
      data: {
        appointmentDateTimeString,
        [`${
          appointmentType === AppointmentTypes.video_call_with_therapist
            ? 'therapistId'
            : 'prescriberId'
        }`]: providerId,
        patientEmail,
        calendarId,
        appointmentTypeID,
        patientTimezone,
      },
    });
    if (!patientEmail) {
      yield put(getUpdatedUserStart(UserRoles.member));
      history.push('/dashboard');
    }
    toast.success('Appointment booked successfully');
    yield call(setSubmitting, false);
  } catch (error) {
    yield call(setSubmitting, false);
  }
}

export function* cancelPatientAppointmentStartSaga({
  payload: appointmentID,
}: CancelPatientAppointmentStartAction): SagaIterator<void> {
  try {
    yield call(requestHandler, {
      method: HttpMethods.POST,
      url: '/api/patient/acuity/cancel-appointment',
      data: {appointmentID},
    });
    yield put(cancelPatientAppointmentSuccess(appointmentID));
    toast.success('Appointment cancelled successfully');
  } catch (error) {
    log.warn(error);
  }
}
