import React, {FC} from 'react';
import {Rating} from 'semantic-ui-react';
import * as Yup from 'yup';
import {useFormik, FormikProvider} from 'formik';
import {ModalTitle, RatingContainer} from '../rating.styles';

type props = {
  onSubmit: (values: {rating: {AudioVideo: number}}) => {};
  store: {
    rating: {AudioVideo: number};
  };
};

const schema = Yup.object()
  .shape({
    rating: Yup.object().shape({
      AudioVideo: Yup.number(),
    }),
  })
  .required();

const RateAudioVideo: FC<props> = ({onSubmit, store}) => {
  const formik = useFormik({
    validationSchema: schema,
    initialValues: store,
    onSubmit: values => {
      onSubmit(values);
    },
  });

  const handleRate = (e: any, {rating, maxRating}: any) => {
    formik.setFieldValue('rating.AudioVideo', rating, true);
    console.warn(e, maxRating);
  };

  return (
    <FormikProvider value={formik}>
      <form id="rating-form" onSubmit={formik.handleSubmit}>
        <ModalTitle>How was your audio and video experience?</ModalTitle>
        <RatingContainer>
          <Rating
            icon="star"
            defaultRating={formik?.values?.rating?.AudioVideo ?? 0}
            maxRating={5}
            onRate={handleRate}
            size="massive"
          />
        </RatingContainer>
      </form>
    </FormikProvider>
  );
};

export default RateAudioVideo;
