const httpMethodsToRetry = ['GET', 'HEAD', 'PUT', 'OPTIONS', 'DELETE'];

const statusCodesToRetry = [
  // https://en.wikipedia.org/wiki/List_of_HTTP_status_codes
  // 1xx - Retry (Informational, request still processing)
  // 2xx - Do not retry (Success)
  // 3xx - Do not retry (Redirect)
  // 4xx - Do not retry (Client errors)
  // 429 - Retry ("Too Many Requests")
  // 5xx - Retry (Server errors)
  [100, 199],
  [429, 429],
  [500, 599],
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const shouldRetryRequest = (error: any): boolean => {
  let retryRequest = true;
  const originalRequest = error.config;
  if (!originalRequest || originalRequest.retry) retryRequest = false;
  if (!error.response && originalRequest.noResponseRetry) retryRequest = false;
  if (httpMethodsToRetry.indexOf(originalRequest.method.toUpperCase()) < 0)
    retryRequest = false;
  if (error.response && error.response.status && !originalRequest.retry) {
    let isInRange = false;
    for (const [min, max] of statusCodesToRetry) {
      const status = error.response.status;
      if (status >= min && status <= max) {
        isInRange = true;
        break;
      }
    }
    if (!isInRange) retryRequest = false;
  }
  if (originalRequest.retry) retryRequest = false;
  return retryRequest;
};
