import {
  CLEAR_PATIENT_NOTES,
  CREATE_PATIENT_NOTE_START,
  CREATE_PATIENT_NOTE_SUCCESS,
  FETCH_PATIENT_NOTES_FAILURE,
  FETCH_PATIENT_NOTES_START,
  FETCH_PATIENT_NOTES_SUCCESS,
  MIGRATE_PATIENT_NOTES_START,
} from '../patients.constants';
import {PatientsActionTypes} from '../patients.types';
import {
  CreatePatientNoteCredentials,
  FetchPatientNotesCredentials,
  MigratePatientNotesTypes,
  NormalizedNotes,
} from './patients-notes.types';

export function fetchPatientNotesStart({
  patientEmail,
  userRole,
  numRequest,
  lastRequest,
}: FetchPatientNotesCredentials): PatientsActionTypes {
  return {
    type: FETCH_PATIENT_NOTES_START,
    payload: {patientEmail, userRole, numRequest, lastRequest},
  };
}

export function fetchPatientNotesSuccess(
  notes: NormalizedNotes,
): PatientsActionTypes {
  return {
    type: FETCH_PATIENT_NOTES_SUCCESS,
    payload: notes,
  };
}

export function fetchPatientNotesFailure(): PatientsActionTypes {
  return {
    type: FETCH_PATIENT_NOTES_FAILURE,
  };
}

export function createPatientNoteStart({
  chiefComplaint,
  patientNotes,
  plan,
  prescribeMedication,
  pharmacyService,
  medications,
  date,
  noteCreatorName,
  patientEmail,
  medicationList,
  closeModal,
  setSubmitting,
  resetForm,
  userRole,
  icdCodes,
}: CreatePatientNoteCredentials): PatientsActionTypes {
  return {
    type: CREATE_PATIENT_NOTE_START,
    payload: {
      chiefComplaint,
      patientNotes,
      plan,
      medicationList,
      prescribeMedication,
      pharmacyService,
      noteCreatorName,
      medications,
      date,
      patientEmail,
      closeModal,
      setSubmitting,
      resetForm,
      userRole,
      icdCodes,
    },
  };
}

export function createPatientNoteSuccess(
  notes: NormalizedNotes,
): PatientsActionTypes {
  return {
    type: CREATE_PATIENT_NOTE_SUCCESS,
    payload: notes,
  };
}

export function clearPatientNotes(): PatientsActionTypes {
  return {
    type: CLEAR_PATIENT_NOTES,
  };
}

export function migrateNotesStart(
  payload: MigratePatientNotesTypes,
): PatientsActionTypes {
  return {
    type: MIGRATE_PATIENT_NOTES_START,
    payload,
  };
}
