import {adminRolePermissions} from './admin.constants';
import {AccessLevelData} from './admin.types';

function getAdminRoleAccessTypeAndStatus(
  rolePermissions: string[],
  permissions: string[],
): AccessLevelData[] {
  const accessLevel = [];
  for (const accessLevelType of rolePermissions) {
    if (permissions.includes(accessLevelType)) {
      accessLevel.push({
        accessLevelType,
        permission: true,
      });
    } else {
      accessLevel.push({
        accessLevelType,
        permission: false,
      });
    }
  }
  return accessLevel;
}

export function getRolesAndPermissionsStatus(
  roles: string[],
  permissions: string[],
): AccessLevelData[] {
  if (roles.includes('super_admin')) {
    return getAdminRoleAccessTypeAndStatus(
      adminRolePermissions.super_admin,
      permissions,
    );
  }

  let selectedRolesPermissions: string[] = [];
  for (const role of roles) {
    selectedRolesPermissions = [
      ...selectedRolesPermissions,
      ...adminRolePermissions[role],
    ];
  }
  return getAdminRoleAccessTypeAndStatus(selectedRolesPermissions, permissions);
}

export function handleRoleChange(
  roles: string[],
  permissions: string[],
  roleToRemove: string,
): string[] {
  let permissionsOfRemainingRoles: string[] = [];
  const result = [];
  for (const role of roles) {
    if (role !== roleToRemove) {
      permissionsOfRemainingRoles = [
        ...permissionsOfRemainingRoles,
        ...adminRolePermissions[role],
      ];
    }
  }

  for (const permission of permissions) {
    if (permissionsOfRemainingRoles.includes(permission))
      result.push(permission);
  }

  return result;
}
