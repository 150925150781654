import {put, call} from 'redux-saga/effects';
import {normalize} from 'normalizr';
import * as schema from '_redux/schema';
import log from 'loglevel';
import requestHandler from '_services/api/axios-config';
import {SagaIterator} from 'redux-saga';
import {toast} from 'react-toastify';
import {getRolesAndPermissionsStatus} from '_components/admin/admin.utils';
import history from '_helpers/history';
import {HttpMethods} from '_services/api/axios.types';
import {
  DeleteAdminStartAction,
  CreateAdministratorAction,
  FetchAdministratorStartAction,
  EditAdministratorAction,
} from './admin-administrator-model-actions.types';
import {
  fetchAdminsSuccess,
  fetchAdminsFailure,
  deleteAdminSuccess,
  fetchAdminsStart,
  fetchAdminSuccess,
} from './admin-administrator-model.actions';

export function* fetchAdminsSaga(): SagaIterator<void> {
  try {
    const {
      data: {message: admins},
    } = yield call(requestHandler, {
      method: HttpMethods.GET,
      url: '/api/admin/admin/admin-all',
    });
    const normalizedAdmins = yield call(
      normalize,
      admins,
      schema.arrayOfAdmins,
    );
    yield put(
      fetchAdminsSuccess({...normalizedAdmins, filter: 'administrators'}),
    );
  } catch (error) {
    yield put(fetchAdminsFailure());
  }
}

export function* deleteAdminSaga({
  payload: adminId,
}: DeleteAdminStartAction): SagaIterator<void> {
  try {
    yield call(requestHandler, {
      method: HttpMethods.DELETE,
      url: `/api/admin/admin/delete/${adminId}`,
    });
    yield put(deleteAdminSuccess({id: adminId, filter: 'administrators'}));
    toast.success('Administrator deleted');
  } catch (error) {
    log.warn(error);
  }
}

export function* createAdminSaga({
  payload: {
    name: fullName,
    email,
    password,
    gender,
    adminRoles,
    accessLevel,
    setSubmitting,
    closeModal,
  },
}: CreateAdministratorAction): SagaIterator<void> {
  try {
    const accessLevelInfo = yield call(
      getRolesAndPermissionsStatus,
      adminRoles as string[],
      accessLevel as string[],
    );
    yield call(requestHandler, {
      method: HttpMethods.POST,
      url: '/api/admin/admin/register',
      data: {
        fullName,
        email: email.toLowerCase(),
        password,
        gender,
        adminRoles,
        accessLevel: accessLevelInfo,
      },
    });
    yield put(fetchAdminsStart());
    yield call(closeModal);
    toast.success('Administrator added');
  } catch (error) {
    yield call(setSubmitting, false);
  }
}

export function* fetchAdminSaga({
  payload: id,
}: FetchAdministratorStartAction): SagaIterator<void> {
  try {
    const {
      data: {message: administrator},
    } = yield call(requestHandler, {
      method: HttpMethods.GET,
      url: `/api/admin/admin/admin-single/${id}`,
    });
    yield put(fetchAdminSuccess(administrator));
  } catch (error) {
    log.warn(error);
  }
}

export function* editAdminSaga({
  payload: {
    name: fullName,
    email,
    gender,
    adminRoles,
    accessLevel,
    adminId,
    setSubmitting,
  },
}: EditAdministratorAction): SagaIterator<void> {
  try {
    const accessLevelInfo = yield call(
      getRolesAndPermissionsStatus,
      adminRoles as string[],
      accessLevel as string[],
    );
    yield call(requestHandler, {
      method: HttpMethods.POST,
      url: '/api/admin/admin/edit',
      data: {
        fullName,
        email: email.toLowerCase(),
        gender,
        adminRoles,
        accessLevel: accessLevelInfo,
        adminId,
      },
    });
    history.push('/admin/administrators');
    toast.success('Administrator edited');
  } catch (error) {
    log.warn(error);
    yield call(setSubmitting, false);
  }
}
