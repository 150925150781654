export const FETCH_PATIENTS_START = 'FETCH_PATIENTS_START';
export const FETCH_PATIENTS_SUCCESS = 'FETCH_PATIENTS_SUCCESS';
export const FETCH_PATIENTS_FAILURE = 'FETCH_PATIENTS_FAILURE';

export const DOWNLOAD_PATIENT_DETAILS = 'DOWNLOAD_PATIENT_DETAILS';
export const VIEW_PATIENT_DETAILS_PDF = 'VIEW_PATIENT_DETAILS_PDF';
export const CLEAR_PATIENT_PDF_DETAILS = 'CLEAR_PATIENT_PDF_DETAILS';

export const VIEW_PATIENT_START = 'VIEW_PATIENT_START';
export const VIEW_PATIENT_SUCCESS = 'VIEW_PATIENT_SUCCESS';
export const VIEW_PATIENT_FAILURE = 'VIEW_PATIENT_FAILURE';

export const GET_STATE_PRESCRIBERS_START = 'GET_STATE_PRESCRIBERS_START';
export const GET_STATE_PRESCRIBERS_SUCCESS = 'GET_STATE_PRESCRIBERS_SUCCESS';

export const UPDATE_PATIENT_START = 'UPDATE_PATIENT_START';
export const SET_MEMBER_RECURRING_CHARGE = 'SET_MEMBER_RECURRING_CHARGE';
export const SET_MEMBER_CURRENT_CHARGE = 'SET_MEMBER_CURRENT_CHARGE';
export const UPDATE_PATIENT_PRESCRIBER_START =
  'UPDATE_PATIENT_PRESCRIBER_START';
export const UPDATE_PATIENT_THERAPIST_START = 'UPDATE_PATIENT_THERAPIST_START';
export const UPDATE_PATIENT_SUCCESS = 'UPDATE_PATIENT_SUCCESS';
export const DELETE_PATIENT_START = 'DELETE_PATIENT_START';

export const FETCH_PATIENT_NOTES_START = 'FETCH_PATIENT_NOTES_START';
export const FETCH_PATIENT_NOTES_SUCCESS = 'FETCH_PATIENT_NOTES_SUCCESS';
export const FETCH_PATIENT_NOTES_FAILURE = 'FETCH_PATIENT_NOTES_FAILURE';

export const CREATE_PATIENT_NOTE_START = 'CREATE_PATIENT_NOTE_START';
export const CREATE_PATIENT_NOTE_SUCCESS = 'CREATE_PATIENT_NOTE_SUCCESS';

export const SEARCH_PATIENTS_START = 'SEARCH_PATIENTS_START';
export const SEARCH_PATIENTS_SUCCESS = 'SEARCH_PATIENTS_SUCCESS';
export const SEARCH_PATIENTS_FAILURE = 'SEARCH_PATIENTS_FAILURE';

export const CLEAR_PATIENTS_SEARCH_RESULT = 'CLEAR_PATIENTS_SEARCH_RESULT';
export const CLEAR_PATIENT = 'CLEAR_PATIENT';
export const CLEAR_PATIENT_NOTES = 'CLEAR_PATIENT_NOTES';
export const CLEAR_STATE_PRESCRIBERS = 'CLEAR_STATE_PRESCRIBERS';

export const CANCEL_PATIENT_PLAN = 'CANCEL_PATIENT_PLAN';

export const UPDATE_PATIENT_PLAN = 'UPDATE_PATIENT_PLAN';

export const MIGRATE_PATIENT_NOTES_START = 'MIGRATE_PATIENT_NOTES_START';
export const MIGRATE_PATIENT_NOTES_SUCCESS = 'MIGRATE_PATIENT_NOTES_SUCCESS';
export const MIGRATE_PATIENT_NOTES_FAILURE = 'MIGRATE_PATIENT_NOTES_FAILURE';

export const UPDATE_PATIENT_INSURANCE_START = 'UPDATE_PATIENT_INSURANCE_START';
export const UPDATE_PATIENT_INSURANCE_SUCCESS =
  'UPDATE_PATIENT_INSURANCE_SUCCESS';
export const UPDATE_PATIENT_INSURANCE_FAILURE =
  'UPDATE_PATIENT_INSURANCE_FAILURE';
