export const CREATE_POST_START = 'CREATE_POST_START';
export const CREATE_POST_SUCCESS = 'CREATE_POST_SUCCESS';
export const CREATE_POST_FAILURE = 'CREATE_POST_FAILURE';

export const SHARE_POST_START = 'SHARE_POST_START';
export const SHARE_POST_SUCCESS = 'SHARE_POST_SUCCESS';
export const SHARE_POST_FAILURE = 'SHARE_POST_FAILURE';

export const FETCH_POSTS_REQUESTING = 'FETCH_POSTS_REQUESTING';

export const FETCH_POSTS_START = 'FETCH_POSTS_START';
export const FETCH_POSTS_SUCCESS = 'FETCH_POSTS_SUCCESS';

export const FETCH_CACHE_POSTS_START = 'FETCH_CACHE_POSTS_START';
export const FETCH_CACHE_POSTS_SUCCESS = 'FETCH_CACHE_POSTS_SUCCESS';

export const FETCH_API_POSTS_START = 'FETCH_API_POSTS_START';
export const FETCH_API_POSTS_SUCCESS = 'FETCH_API_POSTS_SUCCESS';

export const DELETE_POST_START = 'DELETE_POST_START';
export const DELETE_POST_SUCCESS = 'DELETE_POST_SUCCESS';

export const DELETE_UNPUBLISHED_POST_START = 'DELETE_UNPUBLISHED_POST_START';
export const DELETE_UNPUBLISHED_POST_SUCCESS =
  'DELETE_UNPUBLISHED_POST_SUCCESS';
export const DELETE_UNPUBLISHED_POST_FAILURE =
  'DELETE_UNPUBLISHED_POST_FAILURE';

export const DELETE_CACHE_POSTS = 'DELETE_CACHE_POSTS';

export const SET_POST_LAST_FETCHED = 'SET_POST_LAST_FETCHED';
export const SET_HAS_MORE_POSTS = 'SET_HAS_MORE_POSTS';

export const TIME_TO_STALE = 3 * 60 * 1000;
export const SET_TO_STALE = 5 * 60 * 1000;
export const TTL_CACHE = 30 * 60 * 1000;
