import React, {FC, useState} from 'react';
import {useDispatch} from 'react-redux';
import {GoBackIcon, GoForwardIcon} from '_assets/svgIcon';
import {Modal} from 'semantic-ui-react';
import {saveProviderVideoCallLeaveLogStart} from '_redux/user/video/user-video.actions';
import {rateVideoCall} from '_redux/admin/therapists/admin-therapist-model.actions';
import {formSteps} from './steps';
import {ButtonContainer, Button} from './rating.styles';

const initialValues = {
  rating: {
    AudioVideo: 0,
  },
};

type FormNavigationProps = {
  isLast: boolean;
  isFirst: boolean;
  handleBackClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
};

type props = {
  roomId: string;
};

export const FormNavigationButtons: FC<FormNavigationProps> = ({
  isLast,
  isFirst,
  handleBackClick,
}) => {
  return (
    <ButtonContainer>
      {isFirst || isLast ? (
        <div />
      ) : (
        <Button onClick={handleBackClick}>
          <section>
            <GoBackIcon fillColor="#315eff" strokeColor="blue" />
            <span style={{paddingLeft: 5}}>Previous</span>
          </section>
        </Button>
      )}
      {isLast ? (
        <Button type="submit" form="rating-form">
          Submit
        </Button>
      ) : (
        <Button type="submit" form="rating-form">
          <section>
            <span style={{paddingRight: 5}}>Next</span>
            <GoForwardIcon fillColor="#315eff" strokeColor="blue" />
          </section>
        </Button>
      )}
    </ButtonContainer>
  );
};

const Rating: FC<props> = ({roomId}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formState, setFormState] = useState(initialValues);
  const CurrentTab = formSteps[currentStep];

  const isLastStep = () => currentStep === Object.keys(formSteps).length;
  const isFirstStep = () => currentStep === 1;

  const isLast = isLastStep();
  const isFirst = isFirstStep();

  const dispatch = useDispatch();

  const handleBackClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    currentStep > 1 && setCurrentStep(prevStep => prevStep - 1);
  };

  const onSubmit = (formData: {rating: {AudioVideo: number}}) => {
    const rating = {...formState.rating, ...formData.rating};
    setFormState({rating});

    const updateData = {
      roomId,
      rating,
    };
    dispatch(saveProviderVideoCallLeaveLogStart(updateData));

    if (currentStep >= 1 && !isLast) setCurrentStep(prev => prev + 1);
    else {
      dispatch(rateVideoCall(''));
    }
  };

  return (
    <Modal size="mini" open={true} onClose={() => {}}>
      <Modal.Content>
        <CurrentTab onSubmit={onSubmit} store={formState} />
        <FormNavigationButtons
          isLast={isLast}
          isFirst={isFirst}
          handleBackClick={handleBackClick}
        />
      </Modal.Content>
    </Modal>
  );
};

export default Rating;
