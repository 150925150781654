export const DELETE_PRESCRIBER_START = 'DELETE_PRESCRIBER_START';
export const DELETE_PRESCRIBER_SUCCESS = 'DELETE_PRESCRIBER_SUCCESS';

export const DELETE_ADMINISTRATOR_START = 'DELETE_ADMINISTRATOR_START';
export const DELETE_ADMINISTRATOR_SUCCESS = 'DELETE_ADMINISTRATOR_SUCCESS';

export const DELETE_COACH_START = 'DELETE_COACH_START';
export const DELETE_COACH_SUCCESS = 'DELETE_COACH_SUCCESS';

export const DELETE_MEDICATION_START = 'DELETE_MEDICATION_START';
export const DELETE_MEDICATION_SUCCESS = 'DELETE_MEDICATION_SUCCESS';

export const DELETE_THERAPIST_START = 'DELETE_THERAPIST_START';
export const DELETE_THERAPIST_SUCCESS = 'DELETE_THERAPIST_SUCCESS';

export const VERIFY_THERAPIST = 'VERIFY_THERAPIST';

export const FETCH_PRESCRIBERS_START = 'FETCH_PRESCRIBERS_START';
export const FETCH_PRESCRIBERS_SUCCESS = 'FETCH_PRESCRIBERS_SUCCESS';
export const FETCH_PRESCRIBERS_FAILURE = 'FETCH_PRESCRIBERS_FAILURE';

export const FETCH_PRESCRIBERS_PAGINATED_START =
  'FETCH_PRESCRIBERS_PAGINATED_START';
export const FETCH_PRESCRIBERS_PAGINATED_SUCCESS =
  'FETCH_PRESCRIBERS_PAGINATED_SUCCESS';
export const FETCH_PRESCRIBERS_PAGINATED_FAILURE =
  'FETCH_PRESCRIBERS_PAGINATED_FAILURE';

export const FETCH_PRESCRIBER_START = 'FETCH_PRESCRIBER_START';
export const FETCH_PRESCRIBER_SUCCESS = 'FETCH_PRESCRIBER_SUCCESS';

export const EDIT_PRESCRIBER = 'EDIT_PRESCRIBER';

export const CLEAR_PRESCRIBER = 'CLEAR_PRESCRIBER';

export const FETCH_ADMINISTRATORS_START = 'FETCH_ADMINISTRATORS_START';
export const FETCH_ADMINISTRATORS_SUCCESS = 'FETCH_ADMINISTRATORS_SUCCESS';
export const FETCH_ADMINISTRATORS_FAILURE = 'FETCH_ADMINISTRATORS_FAILURE';

export const FETCH_ADMINISTRATOR_START = 'FETCH_ADMINISTRATOR_START';
export const FETCH_ADMINISTRATOR_SUCCESS = 'FETCH_ADMINISTRATOR_SUCCESS';

export const EDIT_ADMINISTRATOR = 'EDIT_ADMINISTRATOR';

export const CLEAR_ADMINISTRATOR = 'CLEAR_ADMINISTRATOR';

export const FETCH_COACHES_START = 'FETCH_COACHES_START';
export const FETCH_COACHES_SUCCESS = 'FETCH_COACHES_SUCCESS';
export const FETCH_COACHES_FAILURE = 'FETCH_COACHES_FAILURE';

export const FETCH_COACH_START = 'FETCH_COACH_START';
export const FETCH_COACH_SUCCESS = 'FETCH_COACH_SUCCESS';

export const EDIT_COACH = 'EDIT_COACH';

export const CLEAR_COACH = 'CLEAR_COACH';

export const FETCH_MEDICATIONS_START = 'FETCH_MEDICATIONS_START';
export const FETCH_MEDICATIONS_SUCCESS = 'FETCH_MEDICATIONS_SUCCESS';
export const FETCH_MEDICATIONS_FAILURE = 'FETCH_MEDICATIONS_FAILURE';

export const CREATE_MEDICATION = 'CREATE_MEDICATION';

export const FETCH_MEDICATION_START = 'FETCH_MEDICATION_START';
export const FETCH_MEDICATION_SUCCESS = 'FETCH_MEDICATION_SUCCESS';

export const EDIT_MEDICATION = 'EDIT_MEDICATION';

export const CLEAR_MEDICATION = 'CLEAR_MEDICATION';

export const FETCH_THERAPISTS_START = 'FETCH_THERAPISTS_START';
export const FETCH_THERAPISTS_SUCCESS = 'FETCH_THERAPISTS_SUCCESS';
export const FETCH_THERAPISTS_FAILURE = 'FETCH_THERAPISTS_FAILURE';

export const FETCH_THERAPISTS_PAGINATED_START =
  'FETCH_THERAPISTS_PAGINATED_START';
export const FETCH_THERAPISTS_PAGINATED_SUCCESS =
  'FETCH_THERAPISTS_PAGINATED_SUCCESS';
export const FETCH_THERAPISTS_PAGINATED_FAILURE =
  'FETCH_THERAPISTS_PAGINATED_FAILURE';

export const FETCH_THERAPIST_START = 'FETCH_THERAPIST_START';
export const FETCH_THERAPIST_SUCCESS = 'FETCH_THERAPIST_SUCCESS';

export const UPDATE_PAYMENT_SERVICE_START = 'UPDATE_PAYMENT_SERVICE_START';
export const UPDATE_PAYMENT_SERVICE_SUCCESS = 'UPDATE_PAYMENT_SERVICE_SUCCESS';
export const UPDATE_PAYMENT_SERVICE_FAILURE = 'UPDATE_PAYMENT_SERVICE_FAILURE';

export const EDIT_THERAPIST = 'EDIT_THERAPIST';

export const CLEAR_THERAPIST = 'CLEAR_THERAPIST';

export const CREATE_COACH_START = 'CREATE_COACH_START';
export const CREATE_COACH_SUCCESS = 'CREATE_COACH_SUCCESS';

export const CREATE_ADMINISTRATOR = 'CREATE_ADMINISTRATOR';

export const CREATE_THERAPIST_START = 'CREATE_THERAPIST_START';
export const CREATE_THERAPIST_SUCCESS = 'CREATE_THERAPIST_SUCCESS';

export const CREATE_PRESCRIBER_START = 'CREATE_PRESCRIBER_START';
export const CREATE_PRESCRIBER_SUCCESS = 'CREATE_PRESCRIBER_SUCCESS';

export const RATE_VIDEO_CALL = 'RATE_VIDEO_CALL';
