import styled from 'styled-components';

export const ModalTitle = styled.p`
  text-align: center;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 500;
  color: #333;
  margin-bottom: 2.5rem;
`;
export const RatingContainer = styled.section`
  width: 100%;
  justify-content: center;
  display: flex;
`;

export const ButtonContainer = styled.section`
  margin-top: 15;
  justify-content: space-between;
  display: flex;
`;

export const Button = styled.button`
  background: transparent;
  border: none;
  &:focus {
    outline: none;
  }

  section {
    display: flex;
    justify-content: center;
  }
`;
