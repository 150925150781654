import styled from 'styled-components';
import {Menu} from 'semantic-ui-react';

const DesktopMenu = styled(Menu)`
  background-color: #fff !important;
  padding: 0.5rem 0rem !important;
  border: none !important;
  border-radius: 0 !important;

  @media only screen and (max-width: 992px) and (min-width: 767px) {
    & > .container .item {
      padding: 0.4rem !important;
    }
  }
`;

export default DesktopMenu;
