import React, {useEffect, Suspense} from 'react';
import {Router} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import Cookies from 'js-cookie';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import {selectUserSessionIsRequesting} from '_redux/user/user.selectors';
import {checkUserSessionStart} from '_redux/user/auth/user-auth.actions';
import {UserRoles} from '_redux/user/auth/user-auth.types';
import Loader from '_components/common/loader.component';
import history from '_helpers/history';
import ResponsiveContainer from '_layout/base-layout/base-layout.component';
import {ErrorBoundary} from '_components/common/error-boundary.component';
import AppContainer from 'app.styles';
import AppTheme from 'AppTheme';
import CacheServiceProvider from '_components/common/context/dexie-db.context';
import AllRoutes from 'Routes';
import './semantic/dist/semantic.min.css';
import {useFirebaseListener} from '_components/common/hooks/useFirebaseListener';
import Rating from '_pages/rating';
import {selectRatingRoom} from '_redux/admin/admin.selectors';

const App: React.FC = () => {
  const userRole = Cookies.get('userRole');
  const dispatch = useDispatch();

  const selectedRoomId = useSelector(selectRatingRoom);
  const sessionRequesting = useSelector(selectUserSessionIsRequesting);
  useEffect(() => {
    if (userRole && sessionRequesting) {
      dispatch(checkUserSessionStart(UserRoles[userRole]));
    }
  }, [sessionRequesting, userRole, dispatch]);

  useFirebaseListener('notifications');

  return (
    <AppTheme>
      <AppContainer>
        <Router history={history}>
          <ErrorBoundary>
            {userRole && sessionRequesting ? (
              <Loader />
            ) : (
              <Suspense fallback={<Loader />}>
                <ToastContainer />
                <CacheServiceProvider>
                  <ResponsiveContainer>
                    <AllRoutes />
                    {selectedRoomId ? <Rating roomId={selectedRoomId} /> : null}
                  </ResponsiveContainer>
                </CacheServiceProvider>
              </Suspense>
            )}
          </ErrorBoundary>
        </Router>
      </AppContainer>
    </AppTheme>
  );
};

export default App;

// const renderCommunity: React.FC<RouteComponentProps> = props => {
//   if (currentUser) {
//     if (isMember(currentUser) || isAdmin(currentUser)) {
//       if (isMember(currentUser) && !currentUser.paidOrTrialAccess?.access) {
//         if (currentUser.paidOrTrialAccess?.message) {
//           toast.error(currentUser.paidOrTrialAccess.message)
//         }
//         const redirectRoute = getPaymentPlanRedirectRoute(currentUser)
//         return <Redirect to={redirectRoute} />
//       }
//       return <CommunityPage {...props} />
//     }
//     return <Redirect to="/" />
//   }

//   return (
//     <Redirect
//       to={{
//         pathname: '/login',
//         state: {from: props.location},
//       }}
//     />
//   )
// }
