import {PatientsResponse} from './list/patients-list.types';
import {PatientsByCategoryState} from './patients.reducer';

const subscribedPatient = (
  verified: boolean,
  subscriptionStatus: string,
): boolean => verified && subscriptionStatus === 'subscribed';
const notSubscribedPatient = (
  verified: boolean,
  subscriptionStatus: string,
): boolean => verified && subscriptionStatus === 'not-subscribed';

const failedPatient = (
  verified: boolean,
  subscriptionStatus: string,
): boolean => verified && /^failed|canceled|expired$/.test(subscriptionStatus);
export function sortPatientsByCategory(
  payload: PatientsResponse,
): PatientsByCategoryState {
  const subscribedPatients = [];
  const unverifiedPatients = [];
  const notSubscribedPatients = [];
  const failedPatients = [];
  let key;
  for (key of payload.patients) {
    if (subscribedPatient(key.verified, key.subscriptionStatus)) {
      subscribedPatients.push(key);
    }
    if (notSubscribedPatient(key.verified, key.subscriptionStatus)) {
      notSubscribedPatients.push(key);
    }
    if (failedPatient(key.verified, key.subscriptionStatus)) {
      failedPatients.push(key);
    }
    if (!key.verified) {
      unverifiedPatients.push(key);
    }
  }
  return {
    subscribed: {1: subscribedPatients},
    unverified: {1: unverifiedPatients},
    'not-subscribed': {1: notSubscribedPatients},
    failed: {1: failedPatients},
    all: {},
  };
}
