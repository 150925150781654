import {UserRoles} from '_redux/user/auth/user-auth.types';
import {HeaderMenuItem} from './base-layout.types';

type MenuItemsListProps = Record<UserRoles, Array<HeaderMenuItem>>;

export const MENU_ITEMS: MenuItemsListProps = {
  patient: [
    {name: 'Dashboard', route: '/dashboard', icon: 'sliders horizontal'},
    {name: 'Chat', route: '/chat', icon: 'conversation'},
    // {name: 'Community', route: '/community', icon: 'group'},
    {name: 'Notifications', route: '/notifications', icon: 'bell'},
    {name: 'Share', route: '/share', icon: 'gift'},
    {name: 'Account', route: '/account', icon: 'user circle'},
  ],
  admin: [
    {name: 'Dashboard', route: '/admin/patients', icon: 'sliders horizontal'},
    {name: 'Prescribers', route: '/admin/prescribers', icon: 'user md'},
    {name: 'Admins', route: '/admin/administrators', icon: 'suitcase'},
    {name: 'Coaches', route: '/admin/coaches', icon: 'id badge'},
    {name: 'Therapists', route: '/admin/therapists', icon: 'heartbeat'},
    {name: 'Medications', route: '/admin/medications', icon: 'pills'},
    // {name: 'Community', route: '/community', icon: 'group'},
    {name: 'Account', route: '/admin/account', icon: 'user circle'},
  ],
  prescriber: [
    {
      name: 'Dashboard',
      route: '/prescriber/patients',
      icon: 'sliders horizontal',
    },
    {
      name: 'Appointments',
      route: '/prescriber/appointments',
      icon: 'calendar alternate outline',
    },
    {name: 'Account', route: '/prescriber/account', icon: 'user circle'},
  ],
  mentalhealthcoach: [
    {name: 'Notifications', route: '/notifications', icon: 'bell outline'},
    {name: 'Chat', route: '/coach/chat', icon: 'conversation'},
    {
      name: 'Appointments',
      route: '/coach/appointments',
      icon: 'calendar alternate outline',
    },
    {name: 'Account', route: '/coach/account', icon: 'user circle'},
  ],
  therapist: [
    {
      name: 'Appointments',
      route: '/therapist/appointments',
      icon: 'calendar alternate outline',
    },
    {name: 'Account', route: '/therapist/account', icon: 'user circle'},
  ],
  loggedout: [
    {name: 'Login', route: '/login', icon: 'sign-in'},
    {name: 'Forgot Password', route: '/forgot', icon: 'key'},
  ],
  unverified: [{name: 'Logout', route: '/logout', icon: 'sign-out'}],
};
