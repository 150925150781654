import {createStore, combineReducers, applyMiddleware, Middleware} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import {createLogger} from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import {ShareData} from '_components/share/share.types';
import rootSaga from './root-saga';
import userReducer from './user/user.reducer';
import {
  CurrentUserStateActions,
  UserChatProfileStateActions,
  UserRequestingStateActions,
  UserSessionRequestingStateActions,
} from './user/user.types';
import {SIGN_OUT_SUCCESS} from './user/user.constants';
import postsReducer from './posts/posts.reducer';
import {
  PostUpdateStateActions,
  PostMetaUpdateStateActions,
  FetchPostsRequestingAction,
} from './posts/posts.types';
import adminReducer from './admin/admin.reducer';
import {
  AdminRequestingStateActions,
  AdminModelUpdateStateActions,
  AdminModelItemStateActions,
} from './admin/admin.types';
import patientsReducer from './patients/patients.reducer';
import {
  PatientsUpdateStateActions,
  MemberProfileUpdateStateActions,
  PatientsRequestingStateActions,
  PatientsSearchUpdateStateActions,
  PatientsSearchingStateActions,
  PatientNotesUpdateStateAction,
  MemberProfilePrescribersStateAction,
  PatientDetailsPdfStateActions,
} from './patients/patients.types';
import appointmentsReducer from './appointments/appointments.reducer';
import {
  AppointmentsRequestingStateActions,
  AppointmentsListStateActions,
} from './appointments/appointments.types';
import {
  NotificationsUpdateStateActions,
  NotificationsRequestingStateActions,
} from './notifications/notifications.types';
import notificationsReducer from './notifications/notifications.reducer';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ReviewsWidget: Function;
    webkitAudioContext: typeof AudioContext;
    Cypress: any;
    store: AppStore;
  }

  interface Navigator {
    share(data?: ShareData): Promise<void>;
    canShare?: (data?: ShareData) => boolean;
  }
}
const sagaMiddleware = createSagaMiddleware();
const loggerMiddleware = createLogger();

type AppActions =
  | CurrentUserStateActions
  | UserChatProfileStateActions
  | UserRequestingStateActions
  | UserSessionRequestingStateActions
  | PostUpdateStateActions
  | PostMetaUpdateStateActions
  | FetchPostsRequestingAction
  | AdminRequestingStateActions
  | AdminModelUpdateStateActions
  | AdminModelItemStateActions
  | PatientsUpdateStateActions
  | MemberProfileUpdateStateActions
  | PatientsRequestingStateActions
  | PatientsSearchUpdateStateActions
  | PatientsSearchingStateActions
  | PatientNotesUpdateStateAction
  | MemberProfilePrescribersStateAction
  | PatientDetailsPdfStateActions
  | AppointmentsRequestingStateActions
  | AppointmentsListStateActions
  | NotificationsUpdateStateActions
  | NotificationsRequestingStateActions;

export const appReducer = combineReducers({
  user: userReducer,
  posts: postsReducer,
  admin: adminReducer,
  patients: patientsReducer,
  appointments: appointmentsReducer,
  notifications: notificationsReducer,
});

export type AppState = ReturnType<typeof appReducer>;

const rootReducer = (
  state: AppState | undefined,
  action: AppActions,
): AppState => {
  if (action.type === SIGN_OUT_SUCCESS) {
    state = undefined;
  }

  return appReducer(state, action);
};

let middleware: Middleware[] = [];
if (process.env.NODE_ENV === 'development') {
  middleware = [...middleware, loggerMiddleware];
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function configureStore() {
  const middlewareEnhancer = applyMiddleware(sagaMiddleware, ...middleware);

  const store = createStore(
    rootReducer,
    composeWithDevTools(middlewareEnhancer),
  );

  if (window.Cypress) {
    window.store = store;
  }

  sagaMiddleware.run(rootSaga);

  return store;
}

const store = configureStore();

export default store;

export type AppStore = typeof store;
