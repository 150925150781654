import {UserRoles} from '_redux/user/auth/user-auth.types';
import {
  FETCH_PATIENT_APPOINTMENTS_START,
  FETCH_PATIENT_APPOINTMENTS_SUCCESS,
  FETCH_PATIENT_APPOINTMENTS_FAILURE,
  FETCH_PROVIDER_APPOINTMENTS_START,
  FETCH_PROVIDER_APPOINTMENTS_SUCCESS,
  FETCH_PROVIDER_APPOINTMENTS_FAILURE,
  FETCH_AVAILABILITY_INFO_START,
  FETCH_AVAILABILITY_INFO_SUCCESS,
  FETCH_AVAILABILITY_INFO_FAILURE,
  BOOK_APPOINTMENT,
  CANCEL_PATIENT_APPOINTMENT_START,
  CANCEL_PATIENT_APPOINTMENT_SUCCESS,
} from './appointments.constants';
import {
  Appointment,
  AppointmentsActionTypes,
  NormalizedAvailabilityInfo,
  BookAppointmentCredentials,
  AppointmentTypes,
} from './appointments.types';

export function fetchPatientAppointmentsStart({
  numRequest,
}: {
  numRequest: number;
}): AppointmentsActionTypes {
  return {
    type: FETCH_PATIENT_APPOINTMENTS_START,
    payload: {numRequest},
  };
}

export function fetchPatientAppointmentsSuccess(
  appointments: Appointment[],
): AppointmentsActionTypes {
  return {
    type: FETCH_PATIENT_APPOINTMENTS_SUCCESS,
    payload: appointments,
  };
}

export function fetchPatientAppointmentsFailure(): AppointmentsActionTypes {
  return {
    type: FETCH_PATIENT_APPOINTMENTS_FAILURE,
  };
}

export function fetchProviderAppointmentsStart({
  userRole,
}: {
  userRole: UserRoles;
}): AppointmentsActionTypes {
  return {
    type: FETCH_PROVIDER_APPOINTMENTS_START,
    payload: {userRole},
  };
}

export function fetchProviderAppointmentsSuccess(
  appointments: Appointment[],
): AppointmentsActionTypes {
  return {
    type: FETCH_PROVIDER_APPOINTMENTS_SUCCESS,
    payload: appointments,
  };
}

export function fetchProviderAppointmentsFailure(): AppointmentsActionTypes {
  return {
    type: FETCH_PROVIDER_APPOINTMENTS_FAILURE,
  };
}

export function fetchAvailabilityInfoStart({
  appointmentType,
  yearAndMonth,
  email,
  callback,
}: {
  appointmentType: AppointmentTypes;
  yearAndMonth: string;
  email: string;
  callback: () => void;
}): AppointmentsActionTypes {
  return {
    type: FETCH_AVAILABILITY_INFO_START,
    payload: {appointmentType, yearAndMonth, email, callback},
  };
}

export function fetchAvailabilityInfoSuccess(
  availabilityInfo: NormalizedAvailabilityInfo,
): AppointmentsActionTypes {
  return {
    type: FETCH_AVAILABILITY_INFO_SUCCESS,
    payload: availabilityInfo,
  };
}

export function fetchAvailabilityInfoFailure(): AppointmentsActionTypes {
  return {
    type: FETCH_AVAILABILITY_INFO_FAILURE,
  };
}

export function bookAppointment({
  appointmentDateTimeString,
  providerId,
  appointmentType,
  calendarId,
  patientTimezone,
  appointmentTypeID,
  patientEmail,
  setSubmitting,
}: BookAppointmentCredentials): AppointmentsActionTypes {
  return {
    type: BOOK_APPOINTMENT,
    payload: {
      appointmentDateTimeString,
      providerId,
      appointmentType,
      calendarId,
      patientTimezone,
      appointmentTypeID,
      patientEmail,
      setSubmitting,
    },
  };
}

export function cancelPatientAppointmentStart(
  appointmentID: string,
): AppointmentsActionTypes {
  return {
    type: CANCEL_PATIENT_APPOINTMENT_START,
    payload: appointmentID,
  };
}

export function cancelPatientAppointmentSuccess(
  appointmentID: string,
): AppointmentsActionTypes {
  return {
    type: CANCEL_PATIENT_APPOINTMENT_SUCCESS,
    payload: appointmentID,
  };
}
