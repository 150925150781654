import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
import 'picturefill';
import 'picturefill/dist/plugins/mutation/pf.mutation.min';
import {Provider} from 'react-redux';
import store from '_redux/root-reducer';
import * as Sentry from '@sentry/browser';
import smoothscroll from 'smoothscroll-polyfill';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
//@ts-ignore
import dateFnsLocalizer, {defaultFormats} from 'react-widgets-date-fns';
import enUS from 'date-fns/locale/en-US';
const formats = Object.assign(defaultFormats, {
  default: 'MMMM do, yyyy',
  header: 'MMMM yyyy',
  year: 'yyyy',
});
dateFnsLocalizer({
  formats,
  locales: {'en-US': enUS},
});

Sentry.init({
  dsn:
    process.env.REACT_APP_SENTRY_API_KEY ||
    'https://10ff77969aaf4b69a5c32ca9577f4d2c@o428419.ingest.sentry.io/5373867',
  environment: process.env.NODE_ENV,
  beforeSend: (event, hint) => {
    const isNonErrorException =
      event.exception?.values?.[0].value?.startsWith(
        'Non-Error exception captured',
      ) ||
      (hint?.originalException as Error)?.message?.startsWith(
        'Non-Error exception captured',
      );

    if (isNonErrorException) {
      // We want to ignore those kind of errors
      return null;
    }
    return event;
  },
  // Will cause a deprecation warning, but the demise of `ignoreErrors` is still under discussion.
  // See: https://github.com/getsentry/raven-js/issues/73
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    // Generic error code from errors outside the security sandbox
    // You can delete this if using raven.js > 1.0, which ignores these automatically.
    'Script error.',
  ],
  blacklistUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
});

// kick off the polyfill for Safari!
smoothscroll.polyfill();

const Root: React.FC = () => (
  <Provider store={store}>
    <App />
  </Provider>
);

ReactDOM.render(<Root />, document.getElementById('root'));
