import {takeLeading, takeEvery, all, call} from 'redux-saga/effects';
import {SagaIterator} from 'redux-saga';
import {
  FETCH_PATIENT_APPOINTMENTS_START,
  FETCH_PROVIDER_APPOINTMENTS_START,
  FETCH_AVAILABILITY_INFO_START,
  BOOK_APPOINTMENT,
  CANCEL_PATIENT_APPOINTMENT_START,
} from './appointments.constants';
import {
  fetchPatientAppointmentsStartSaga,
  fetchProviderAppointmentsStartSaga,
  fetchAvailabilityInfoStartSaga,
  bookAppointmentSaga,
  cancelPatientAppointmentStartSaga,
} from './appointments.sagas';

export function* onFetchPatientAppointmentsStart(): SagaIterator<void> {
  yield takeLeading(
    FETCH_PATIENT_APPOINTMENTS_START,
    fetchPatientAppointmentsStartSaga,
  );
}

export function* onFetchProviderAppointmentsStart(): SagaIterator<void> {
  yield takeEvery(
    FETCH_PROVIDER_APPOINTMENTS_START,
    fetchProviderAppointmentsStartSaga,
  );
}

export function* onFetchAvailabilityInfoStart(): SagaIterator<void> {
  yield takeEvery(
    FETCH_AVAILABILITY_INFO_START,
    fetchAvailabilityInfoStartSaga,
  );
}

export function* onBookAppointment(): SagaIterator<void> {
  yield takeEvery(BOOK_APPOINTMENT, bookAppointmentSaga);
}

export function* onCancelPatientAppointmentStart(): SagaIterator<void> {
  yield takeEvery(
    CANCEL_PATIENT_APPOINTMENT_START,
    cancelPatientAppointmentStartSaga,
  );
}

export function* appointmentsSagas(): SagaIterator<void> {
  yield all([
    call(onFetchPatientAppointmentsStart),
    call(onFetchProviderAppointmentsStart),
    call(onFetchAvailabilityInfoStart),
    call(onBookAppointment),
    call(onCancelPatientAppointmentStart),
  ]);
}
