import {useState, useEffect, useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {selectCoachId} from '_redux/user/user.selectors';
import {getNewNotificationsFromFirebase} from '_services/firebase/firebase.utils';
import {TNotification} from '_redux/notifications/notifications.types';
import {addNewNotification} from '_redux/notifications/notifications.actions';
// import {selectUniqueId} from '_redux/user/user.selectors'

export function useFirebaseListener(
  type: string,
): 'init' | 'open' | 'closed' | 'error' {
  const uniqueId = useSelector(selectCoachId);
  const [status, setStatus] = useState<'init' | 'open' | 'closed' | 'error'>(
    'init',
  );

  const dispatch = useDispatch();
  const handleNewNotification = useCallback(
    (data: TNotification) => {
      dispatch(addNewNotification(data));
    },
    [dispatch],
  );

  useEffect(() => {
    getNewNotificationsFromFirebase({
      type,
      uniqueId,
      setStatus,
      handleNewNotification,
    });

    return () => {
      setStatus('closed');
    };
  }, [uniqueId, type, handleNewNotification]);

  return status;
}
