import {SagaIterator} from 'redux-saga';
import {put, call, fork, join} from 'redux-saga/effects';
import {saveAs} from 'file-saver';
import log from 'loglevel';
import requestHandler from '_services/api/axios-config';
import {toast} from 'react-toastify';
import {HttpMethods} from '_services/api/axios.types';
import {
  StripePaymentPlanDuration,
  StripePaymentPlanName,
} from '_redux/user/payment/user-payment.types';
import history from '_helpers/history';
import {SliceStatus} from '_redux/redux.types';
import {fetchPatientsStart} from '../list/patients-list.actions';
import {
  DownloadPatientDetailsAction,
  ViewPatientStartAction,
  GetPrescribersInStateStartAction,
  UpdatePatientStartAction,
  CancelPatientPlanAction,
  UpdatePatientPlanAction,
  DeletePatientAccountAction,
  SetMemberCurrentChargeActions,
  SetMemberRecurringChargeActions,
  UpdatePatientInsuranceStartAction,
} from './patients-profile-actions.types';
import {
  viewMemberProfileSuccess,
  viewMemberProfileFailure,
  getPrescribersInPatientStateSuccess,
  updateMemberProfileSuccess,
  viewPatientDetailsPDF,
  viewMemberProfileStart,
  updatePatientInsuranceSuccess,
  updatePatientInsuranceFailure,
} from './patients-profile.actions';
import {UpdatePatientCredentialsDetails} from './patients-profile.types';

export function* downloadPatientDetailsSaga({
  payload: {
    data: {role, email, name, canSave},
    cb,
  },
}: DownloadPatientDetailsAction): SagaIterator<void> {
  try {
    const {data} = yield call(requestHandler, {
      method: HttpMethods.GET,
      url: `/api/${role}/patients/${
        canSave ? 'download' : 'view-in-browser'
      }/${email}`,
      data: {
        responseType: 'blob',
      },
    });
    const pdfBlob = new Blob([data], {type: 'application/pdf'});
    if (canSave) saveAs(pdfBlob, `${name}__patient__information.pdf`);
    else yield put(viewPatientDetailsPDF(pdfBlob));
    cb(SliceStatus.resolved);
  } catch (error) {
    log.warn(error);
    cb(SliceStatus.rejected);
  }
}

export function* viewPatientSaga({
  payload: {role, patientEmail},
}: ViewPatientStartAction): SagaIterator<void> {
  try {
    const {
      data: {message: MemberProfile},
    } = yield call(requestHandler, {
      method: HttpMethods.GET,
      url: `/api/${role}/patients/view/${patientEmail}`,
    });
    yield put(viewMemberProfileSuccess(MemberProfile));
  } catch (error) {
    yield put(viewMemberProfileFailure());
  }
}

export function* getPrescribersInPatientStateSaga({
  payload: state,
}: GetPrescribersInStateStartAction): SagaIterator<void> {
  try {
    const {
      data: {message: prescribers},
    } = yield call(requestHandler, {
      method: HttpMethods.GET,
      url: `/api/admin/prescriber/prescribers-in-state/?state=${state}`,
    });
    yield put(getPrescribersInPatientStateSuccess(prescribers));
  } catch (error) {
    log.warn(error);
  }
}

export function* setMemberRecurringChargeSaga({
  payload: {setSubmitting, toggle, ...rest},
}: SetMemberRecurringChargeActions): SagaIterator<void> {
  const data = {...rest};
  try {
    const {
      data: {message: resp},
    } = yield call(requestHandler, {
      method: HttpMethods.POST,
      url: '/api/admin/patient/set-recurring-amount',
      data,
    });
    toast.success(resp);
  } catch (error) {
    log.warn(error);
  } finally {
    yield call(setSubmitting, false);
    yield call(toggle);
  }
}

export function* setMemberCurrentChargeSaga({
  payload: {setSubmitting, toggle, ...rest},
}: SetMemberCurrentChargeActions): SagaIterator<void> {
  try {
    const data = {...rest};
    const {
      data: {message: resp},
    } = yield call(requestHandler, {
      method: HttpMethods.POST,
      url: '/api/admin/stripe/charge-session',
      data,
    });
    toast.success(resp);
  } catch (error) {
    log.warn(error);
  } finally {
    yield call(setSubmitting, false);
    yield call(toggle);
  }
}

export function* updatePatientSaga({
  payload: {setSubmitting, ...rest},
}: UpdatePatientStartAction): SagaIterator<void> {
  try {
    let data: UpdatePatientCredentialsDetails = {...rest};
    if ('allergies' in rest || 'currentMedications' in rest) {
      const allergies = rest.allergies
        .split(',')
        .map((val: string) => val.trim());
      const currentMedications = rest.currentMedications
        .split(',')
        .map((val: string) => val.trim());
      data = {patientId: rest.patientId, allergies, currentMedications};
    }

    if ('postal' in rest) {
      const {
        patientId,
        address: streetAddress,
        postal: postalCode,
        ...shippingInfo
      } = rest;
      data = {
        shippingAddress: {
          streetAddress,
          postalCode,
          ...shippingInfo,
        },
        patientId,
      };
    }

    if ('dateOfBirth' in rest) {
      data = {
        ...rest,
        phoneNumber: rest.phone,
        dateOfBirth: `${rest.dateOfBirth.year}-${rest.dateOfBirth.month}-${rest.dateOfBirth.day}`,
      };
    }

    if ('stateOfResidence' in rest) {
      data = {
        ...rest,
        stateOfResidence: rest.stateOfResidence,
      };
    }

    const {
      data: {message: patient},
    } = yield call(requestHandler, {
      method: HttpMethods.POST,
      url: '/api/admin/patient/update',
      data,
    });
    if ('email' in rest) {
      history.replace(history.location.pathname, {
        patientEmail: rest.email,
      });
    }
    yield put(updateMemberProfileSuccess(patient));
    toast.success('Successfully updated.');
  } catch (error) {
    log.warn(error);
  } finally {
    yield call(setSubmitting, false);
  }
}

export function* updatePatientPrescriberSaga({
  payload: {setSubmitting, patientId, ...rest},
}: UpdatePatientStartAction): SagaIterator<void> {
  try {
    let data: UpdatePatientCredentialsDetails = {...rest};

    if ('prescriberId' in rest) {
      data = {
        patientId,
        prescriberId: rest.prescriberId,
      };
    }
    const {
      data: {message: patient},
    } = yield call(requestHandler, {
      method: HttpMethods.POST,
      url: '/api/admin/prescriber/assign-patient-prescriber',
      data,
    });
    yield put(updateMemberProfileSuccess(patient));
    toast.success('Successfully updated.');
  } catch (error) {
    log.warn(error);
  } finally {
    yield call(setSubmitting, false);
  }
}

export function* updatePatientTherapistSaga({
  payload: {setSubmitting, patientId, ...rest},
}: UpdatePatientStartAction): SagaIterator<void> {
  try {
    let data: UpdatePatientCredentialsDetails = {...rest};
    if ('therapistId' in rest) {
      data = {
        patientId,
        therapistId: rest.therapistId,
      };
    }
    const {
      data: {message: patient},
    } = yield call(requestHandler, {
      method: HttpMethods.POST,
      url: '/api/admin/therapist/assign-patient-therapist',
      data,
    });
    yield put(updateMemberProfileSuccess(patient));
    toast.success('Successfully updated.');
  } catch (error) {
    log.warn(error);
  } finally {
    yield call(setSubmitting, false);
  }
}

export function* cancelPatientPlanSaga({
  payload: {patientId, patientEmail},
}: CancelPatientPlanAction): SagaIterator<void> {
  try {
    yield call(requestHandler, {
      method: HttpMethods.POST,
      url: '/api/admin/stripe/cancel-patient-plan',
      data: {
        patientId,
      },
    });
    const task = yield fork(requestHandler, {
      method: HttpMethods.GET,
      url: `/api/admin/patients/view/${patientEmail}`,
    });
    toast.success('Subscription has been cancelled successfully');
    const {
      data: {message: MemberProfile},
    } = yield join(task);
    yield put(viewMemberProfileSuccess(MemberProfile));
  } catch (error) {
    log.warn(error);
  }
}

export function* updatePatientPlanSaga({
  payload: {
    patientId,
    patientEmail,
    paymentPlan,
    paymentPlanDuration,
    couponCode,
    setSubmitting,
  },
}: UpdatePatientPlanAction): SagaIterator<void> {
  try {
    yield call(requestHandler, {
      method: HttpMethods.POST,
      url: '/api/admin/stripe/update-patient-plan',
      data: {
        patientId,
        couponCode,
        paymentPlan:
          paymentPlanDuration === StripePaymentPlanDuration.monthly ||
          paymentPlan === StripePaymentPlanName.together
            ? paymentPlan
            : `${paymentPlan}_${paymentPlanDuration}`,
        __subPaymentPlan: paymentPlan,
      },
    });
    const task = yield fork(requestHandler, {
      method: HttpMethods.GET,
      url: `/api/admin/patients/view/${patientEmail}`,
    });
    toast.success('Plan has been updated successfully');
    const {
      data: {message: MemberProfile},
    } = yield join(task);
    yield put(viewMemberProfileSuccess(MemberProfile));
  } catch (error) {
    log.warn(error);
  } finally {
    yield call(setSubmitting, false);
  }
}

export function* deletePatientAccountSaga({
  payload: {role, patientId, setSubmitting, setOpenModal},
}: DeletePatientAccountAction): SagaIterator<void> {
  try {
    yield call(requestHandler, {
      method: HttpMethods.POST,
      url: `/api/${role}/patient/delete`,
      data: {patientId},
    });
    yield put(fetchPatientsStart({role, category: 'all', numRequest: 0}));
    history.push('/');
    yield call(setSubmitting, false);
    toast.success('Account Deleted Successfully');
  } catch (error) {
    yield call(setSubmitting, false);
    yield call(setOpenModal, false);
  }
}

export function* updatePatientInsuranceSaga({
  payload: {role, data, setSubmitting, handleCancel},
}: UpdatePatientInsuranceStartAction): SagaIterator<void> {
  try {
    const formData = new FormData();
    formData.append('patientId', data.patientId);
    formData.append(
      'insuranceImageFront',
      data.insuranceImageFront ? data.insuranceImageFront : '',
    );
    formData.append(
      'insuranceImageBack',
      data.insuranceImageBack ? data.insuranceImageBack : '',
    );
    const {
      data: {data: resData},
    } = yield call(requestHandler, {
      method: HttpMethods.PUT,
      url: '/api/admin/patient/update-insurance-image',
      data: formData,
    });
    yield put(updatePatientInsuranceSuccess());
    yield put(viewMemberProfileStart({role, patientEmail: resData.email}));
    toast.success('Insurance Details Updated Successfully');
  } catch (error) {
    yield put(updatePatientInsuranceFailure());
  } finally {
    yield call(setSubmitting, false);
    yield call(handleCancel);
  }
}
