import {call} from 'redux-saga/effects';
import {HttpMethods} from '_services/api/axios.types';
import requestHandler from '_services/api/axios-config';
import {getProviderIP} from '_helpers/utils';
import {
  SaveProviderVideoCallJoinLogStartAction,
  SaveProviderVideoCallLeaveLogStartAction,
} from './user-video-action.types';

export function* saveProviderVideoCallJoinLogSaga({
  payload,
}: SaveProviderVideoCallJoinLogStartAction) {
  try {
    const ip: string = yield call(getProviderIP);
    yield call(requestHandler, {
      method: HttpMethods.POST,
      url: 'api/video-logs/create-call-log',
      data: {...payload, ip},
    });
  } catch (error) {
    // log.warn(error)
  }
}

export function* saveProviderVideoCallLeaveLogSaga({
  payload,
}: SaveProviderVideoCallLeaveLogStartAction) {
  try {
    yield call(requestHandler, {
      method: HttpMethods.POST,
      url: 'api/video-logs/update-call-log',
      data: payload,
    });
  } catch (error) {
    // log.warn(error)
  }
}
