import {combineReducers} from 'redux';
import {CurrentUser} from '_redux/user/auth/user-auth.types';
import {ReferralModalDisplayAction} from './auth/user-auth-actions.types';
import {SetCurrentChatChannelAction} from './chat/user-chat-actions.types';
import {
  CHECK_USER_SESSION_FAILURE,
  CHECK_USER_SESSION_START,
  CHECK_USER_SESSION_SUCCESS,
  CLEAR_NOTIFICATIONS_FAILURE,
  CLEAR_NOTIFICATIONS_START,
  CLEAR_NOTIFICATIONS_SUCCESS,
  GET_UPDATED_USER_SUCCESS,
  GET_USER_CHAT_PROFILE_FAILURE,
  GET_USER_CHAT_PROFILE_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGIN_USER_SUCCESS,
  REFERRAL_MODAL_DISPLAY,
  SET_CURRENT_CHAT_CHANNEL,
  SIGN_OUT_SUCCESS,
  STRIPE_PAYMENT_FAILURE,
  STRIPE_PAYMENT_START,
  STRIPE_PAYMENT_SUCCESS,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_STRIPE_CREDIT_CARD_FAILURE,
  UPDATE_STRIPE_CREDIT_CARD_START,
  UPDATE_STRIPE_CREDIT_CARD_SUCCESS,
  UPDATE_STRIPE_PLAN_FAILURE,
  UPDATE_STRIPE_PLAN_START,
  UPDATE_STRIPE_PLAN_SUCCESS,
} from './user.constants';
import {
  CurrentUserStateActions,
  UserChatProfileStateActions,
  UserRequestingStateActions,
  UserSessionRequestingStateActions,
} from './user.types';

type ChatChannel = {
  name: string;
  id: string;
  patientId: string;
};
function currentUser(
  state: CurrentUser = null,
  {type, payload}: CurrentUserStateActions,
): CurrentUser {
  switch (type) {
    case GET_UPDATED_USER_SUCCESS:
    case CHECK_USER_SESSION_FAILURE:
    case CHECK_USER_SESSION_SUCCESS:
    case LOGIN_USER_FAILURE:
    case LOGIN_USER_SUCCESS:
    case STRIPE_PAYMENT_SUCCESS:
    case UPDATE_ACCOUNT_SUCCESS:
    case CLEAR_NOTIFICATIONS_SUCCESS:
    case SIGN_OUT_SUCCESS:
      return payload;
    default:
      return state;
  }
}

function userChatProfile(
  state: firebase.User | null = null,
  {type, payload}: UserChatProfileStateActions,
): firebase.User | null {
  switch (type) {
    case GET_USER_CHAT_PROFILE_FAILURE:
    case GET_USER_CHAT_PROFILE_SUCCESS:
      return payload;
    default:
      return state;
  }
}

function userChatChannel(
  state: ChatChannel = {
    id: '',
    name: '',
    patientId: '',
  },
  {type, payload}: SetCurrentChatChannelAction,
): ChatChannel {
  switch (type) {
    case SET_CURRENT_CHAT_CHANNEL:
      return payload;
    default:
      return state;
  }
}

function userRequesting(
  state = false,
  {type}: UserRequestingStateActions,
): boolean {
  switch (type) {
    case STRIPE_PAYMENT_START:
    case UPDATE_STRIPE_CREDIT_CARD_START:
    case UPDATE_STRIPE_PLAN_START:
    case CLEAR_NOTIFICATIONS_START:
      return true;
    case STRIPE_PAYMENT_SUCCESS:
    case STRIPE_PAYMENT_FAILURE:
    case UPDATE_STRIPE_CREDIT_CARD_SUCCESS:
    case UPDATE_STRIPE_CREDIT_CARD_FAILURE:
    case UPDATE_STRIPE_PLAN_SUCCESS:
    case UPDATE_STRIPE_PLAN_FAILURE:
    case CLEAR_NOTIFICATIONS_SUCCESS:
    case CLEAR_NOTIFICATIONS_FAILURE:
      return false;
    default:
      return state;
  }
}

function userSessionRequesting(
  state = true,
  {type}: UserSessionRequestingStateActions,
): boolean {
  switch (type) {
    case CHECK_USER_SESSION_START:
      return true;
    case CHECK_USER_SESSION_SUCCESS:
    case CHECK_USER_SESSION_FAILURE:
    case LOGIN_USER_SUCCESS:
      return false;
    default:
      return state;
  }
}

function userReferralModal(
  state = false,
  {type}: ReferralModalDisplayAction,
): boolean {
  switch (type) {
    case REFERRAL_MODAL_DISPLAY:
      return true;
    default:
      return state;
  }
}

const userChatReducer = combineReducers({
  profile: userChatProfile,
  currentChannel: userChatChannel,
});

const userReducer = combineReducers({
  current: currentUser,
  chat: userChatReducer,
  requesting: userRequesting,
  sessionRequesting: userSessionRequesting,
  referralModal: userReferralModal,
});

export default userReducer;
