import React from 'react';
import {GridColumn} from 'semantic-ui-react';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
//@ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import styled from 'styled-components';
import StyledSegment from '_components/common/styles/styled-segment.styles';
import StyledGrid from '_components/common/styles/styled-grid-container.styles';

export type TwoColumnsLayoutProps = {
  left: React.ReactNode;
  right: React.ReactNode;
  header?: React.ReactNode;
  border?: string;
  className?: string;
};

const TwoColumnsLayout: React.FC<TwoColumnsLayoutProps> = ({
  left,
  right,
  header,
  border,
  className,
}) => (
  <StyledGrid
    container
    columns={2}
    className={className}
    reversed="mobile"
    stackable
    textAlign="center"
    verticalAlign="middle"
    css={`
      align-content: ${header ? 'center' : 'inherit'};
    `}
  >
    {header ? <GridColumn width={16}>{header}</GridColumn> : null}
    <GridColumn>
      <StyledSegment>{left}</StyledSegment>
    </GridColumn>
    <GridColumn>
      <StyledSegment border={border}>{right}</StyledSegment>
    </GridColumn>
  </StyledGrid>
);

export default TwoColumnsLayout;
