import {createSelector} from 'reselect';
import {AppState} from '_redux/root-reducer';
import {PostsState} from './posts.types';

function getPosts(state: AppState): PostsState {
  return state.posts;
}

export const selectPosts = createSelector(getPosts, posts =>
  posts.allIds.map(id => posts.byId[id]).filter(Boolean),
);

export const selectPostLastFetched = createSelector(
  getPosts,
  posts => posts.meta.lastFetched,
);

export const selectPostHasMore = createSelector(
  getPosts,
  posts => posts.meta.hasMore,
);

export const selectPostIsLoading = createSelector(
  getPosts,
  posts => posts.requesting,
);
