import {
  SAVE_PROVIDER_VIDEO_CALL_JOIN_LOG_START,
  SAVE_PROVIDER_VIDEO_CALL_JOIN_LOG_SUCCESS,
  SAVE_PROVIDER_VIDEO_CALL_JOIN_LOG_FAILURE,
  SAVE_PROVIDER_VIDEO_CALL_LEAVE_LOG_START,
  SAVE_PROVIDER_VIDEO_CALL_LEAVE_LOG_SUCCESS,
  SAVE_PROVIDER_VIDEO_CALL_LEAVE_LOG_FAILURE,
} from '../user.constants';
import {UserActionTypes} from '../user.types';
import {
  SaveProviderVideoCallJoinLog,
  SaveProviderVideoCallLeaveLog,
} from './user-video-types';

export function saveProviderVideoCallJoinLogStart(
  videoCallJoinLogData: SaveProviderVideoCallJoinLog,
): UserActionTypes {
  return {
    type: SAVE_PROVIDER_VIDEO_CALL_JOIN_LOG_START,
    payload: videoCallJoinLogData,
  };
}
export function saveProviderVideoCallJoinLogSuccess(): UserActionTypes {
  return {
    type: SAVE_PROVIDER_VIDEO_CALL_JOIN_LOG_SUCCESS,
  };
}
export function saveProviderVideoCallJoinLogFailure(): UserActionTypes {
  return {
    type: SAVE_PROVIDER_VIDEO_CALL_JOIN_LOG_FAILURE,
  };
}

export function saveProviderVideoCallLeaveLogStart(
  videoCallLeaveLogData: SaveProviderVideoCallLeaveLog,
): UserActionTypes {
  return {
    type: SAVE_PROVIDER_VIDEO_CALL_LEAVE_LOG_START,
    payload: videoCallLeaveLogData,
  };
}
export function saveProviderVideoCallLeaveLogSuccess(): UserActionTypes {
  return {
    type: SAVE_PROVIDER_VIDEO_CALL_LEAVE_LOG_SUCCESS,
  };
}
export function saveProviderVideoCallLeaveLogFailure(): UserActionTypes {
  return {
    type: SAVE_PROVIDER_VIDEO_CALL_LEAVE_LOG_FAILURE,
  };
}
