import React, {useState} from 'react';
import {
  Container,
  Icon,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  SidebarPushable,
  SidebarPusher,
} from 'semantic-ui-react';
import {PRIMARY_COLOR} from '_helpers/style-guide';
import HeaderMenuItems from './menu-items.component';
import {ContainerProps} from './base-layout.types';
import Hamburger from './hamburger.styles';

const MobileContainer: React.FC<ContainerProps> = ({children, userRole}) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const handleSidebarHide = (): void => setSidebarOpen(false);
  const handleToggle = (): void => setSidebarOpen(true);
  return (
    <Responsive
      as={SidebarPushable}
      getWidth={(): number => window.innerWidth}
      maxWidth={Responsive.onlyMobile.maxWidth}
      style={{flex: 1}}
    >
      <Sidebar
        as={Menu}
        animation="push"
        inverted
        onHide={handleSidebarHide}
        vertical
        visible={sidebarOpen}
      >
        <span
          className="closebtn"
          role="button"
          tabIndex={0}
          style={{
            marginLeft: '90%',
            color: 'white',
            fontWeight: 'bold',
            fontSize: '2.2rem',
            lineHeight: '3rem',
            cursor: 'pointer',
          }}
          onClick={handleSidebarHide}
        >
          &times;
        </span>
        <HeaderMenuItems userRole={userRole} mobile />
      </Sidebar>

      <SidebarPusher>
        <Segment
          inverted
          textAlign="center"
          vertical
          style={{padding: 0, backgroundColor: 'transparent'}}
        >
          <Container>
            <Menu
              fixed="top"
              style={{border: 'none'}}
              inverted
              pointing
              icon="labeled"
              secondary
              size="massive"
            >
              <Hamburger data-testid="hamburger-toggler" onClick={handleToggle}>
                <Icon style={{color: PRIMARY_COLOR}} name="sidebar" />
              </Hamburger>
            </Menu>
          </Container>
        </Segment>
        {children}
      </SidebarPusher>
    </Responsive>
  );
};

export default MobileContainer;
