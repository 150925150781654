import {SagaIterator} from 'redux-saga';
import {put, call, fork, select, race, take} from 'redux-saga/effects';
import {normalize} from 'normalizr';
import log from 'loglevel';
import requestHandler from '_services/api/axios-config';
// import { toFormData } from '_helpers/utils'
import {CurrentUserStateActions} from '_redux/user/user.types';
import {CacheService} from '_services/dexie/dexie-cache';
import * as schema from '_redux/schema';
import {toast} from 'react-toastify';
import {HttpMethods} from '_services/api/axios.types';
import {isIndexedDBAvailable} from '_services/dexie/dexie-utils';
import {
  getCurrentFirebaseUser,
  createPostInFirebase,
  addPostToTimelineInFirebase,
  deleteUnpublishedPostInFirebase,
  setShareApprovedInFirebase,
} from '_services/firebase/firebase.utils';
import {updateShareResponseNotificationSuccess} from '_redux/notifications/notifications.actions';
import {
  CreatePostStartAction,
  FetchPostsStartAction,
  FetchCachePostsStartAction,
  FetchApiPostsStartAction,
  DeletePostStartAction,
  SharePostStartAction,
} from './posts.types';
import {
  createPostSuccess,
  fetchPostsSuccess,
  setPostLastFetched,
  fetchCachePostsStart,
  fetchApiPostsStart,
  setHasMorePosts,
  deleteCachePosts,
  fetchPostsRequesting,
  deletePostSuccess,
  // deletePostStart,
  createPostFailure,
  sharePostSuccess,
  sharePostFailure,
  sharePostStart,
  deleteUnpublishedPostSuccess,
} from './posts.actions';
import {TIME_TO_STALE, SET_TO_STALE, TTL_CACHE} from './posts.constants';
import {selectPostLastFetched} from './posts.selectors';

let cacheDb: CacheService;
if (isIndexedDBAvailable) {
  cacheDb = new CacheService();
}

export function* setInitialPostsDataSaga({
  payload: user,
}: CurrentUserStateActions): SagaIterator<void> {
  try {
    if (!user || !/^(admin|patient)$/.test(user.role)) return;
    const postsLastFetched = localStorage.getItem('postsLastFetched');
    if (isIndexedDBAvailable) {
      const posts = yield call(cacheDb.retrievePostsFromCache, 0, Date.now());
      if (posts) {
        const normalizedPosts = yield call(
          normalize,
          posts.postInfo,
          schema.arrayOfPosts,
        );
        yield put(fetchPostsSuccess(normalizedPosts));
      }
    }
    yield put(
      setPostLastFetched(
        typeof postsLastFetched === 'string'
          ? +postsLastFetched
          : postsLastFetched,
      ),
    );
  } catch (error) {
    log.warn(error);
  }
}

export function* createPostSaga({
  payload: {
    caption = '',
    content = '',
    image = '',
    notificationId,
    entityId,
    setSubmitting,
  },
}: CreatePostStartAction): SagaIterator<void> {
  let postId = '';
  let firebaseUser: firebase.User | null = null;
  try {
    firebaseUser = yield call(getCurrentFirebaseUser);

    if (firebaseUser) {
      // Create post in firebase and return post object
      postId = yield call(
        createPostInFirebase,
        caption,
        content,
        image,
        firebaseUser,
      );
      if (notificationId && entityId) {
        // Initiat post share to community
        yield put(sharePostStart({caption, notificationId}));
        // Update Share post response in redux
        yield put(
          updateShareResponseNotificationSuccess({
            id: entityId,
            changes: {status: 'shared', caption},
          }),
        );
        yield call(addPostToTimelineInFirebase, postId, firebaseUser);
        yield call(setShareApprovedInFirebase, postId, firebaseUser);

        toast.success('You posted in Community!');
      }
      yield put(createPostSuccess());
    }
  } catch (error) {
    log.warn(error);
    if (postId && firebaseUser) {
      yield call(deleteUnpublishedPostInFirebase, postId, firebaseUser);
    }
    yield put(createPostFailure());
  } finally {
    yield call(setSubmitting, false);
  }
}

export function* sharePostSaga({
  payload: {caption, notificationId},
}: SharePostStartAction): SagaIterator<void> {
  try {
    yield call(requestHandler, {
      method: HttpMethods.POST,
      url: `/api/community/share-post/?notificationId=${notificationId}`,
      data: {caption},
    });
    yield put(sharePostSuccess());
  } catch (error) {
    yield put(sharePostFailure());
  }
}

export function* fetchPostsSaga({
  payload: {numRequest, dateOfLastPost},
}: FetchPostsStartAction): SagaIterator<void> {
  try {
    const lastFetchTimeStamp = yield select(selectPostLastFetched);
    const cacheIsStale = Date.now() - lastFetchTimeStamp > TIME_TO_STALE;
    // Component has mounted
    if (numRequest === 0) {
      // Cache is not empty
      if (lastFetchTimeStamp) {
        // Fetch from Cache
        yield put(
          fetchCachePostsStart({
            numRequest,
            dateOfLastPost,
            lastFetchedTstamp: lastFetchTimeStamp,
          }),
        );
      }
      // Cache is stale, call API
      if (cacheIsStale) {
        yield put(
          fetchApiPostsStart({
            numRequest,
            lastFetchedTstamp: lastFetchTimeStamp,
          }),
        );
      }
    } else {
      if (cacheIsStale) {
        yield put(
          fetchApiPostsStart({
            numRequest,
            lastFetchedTstamp: lastFetchTimeStamp,
          }),
        );
      }
      if (!cacheIsStale) {
        yield put(
          fetchCachePostsStart({
            numRequest,
            dateOfLastPost,
            lastFetchedTstamp: lastFetchTimeStamp,
          }),
        );
      }
    }
  } catch (err) {
    log.warn(err);
  } finally {
    localStorage.setItem('postsLastFetched', `${Date.now()}`);
  }
}

function* ifCacheHasMorePostsSaga(hasMore: boolean): SagaIterator<void> {
  if (hasMore) {
    yield put(setHasMorePosts(hasMore));
  } else {
    // Try API
    yield put(setHasMorePosts(true));
    yield put(setPostLastFetched(Date.now() + SET_TO_STALE));
  }
}

export function* fetchCachedPostsSaga({
  payload: {numRequest, dateOfLastPost, lastFetchedTstamp},
}: FetchCachePostsStartAction): SagaIterator<void> {
  try {
    if (isIndexedDBAvailable) {
      const {cache, cancel} = yield race({
        cache: call(
          cacheDb.retrievePostsFromCache,
          numRequest,
          dateOfLastPost || Date.now(),
        ),
        cancel: take('FETCH_POSTS_SUCCESS'),
      });
      if (!cancel && !cache) {
        yield put(fetchApiPostsStart({numRequest, lastFetchedTstamp}));
      } else {
        const normalizedPosts = yield call(
          normalize,
          cache.postInfo,
          schema.arrayOfPosts,
        );
        yield put(fetchPostsSuccess(normalizedPosts));
        yield call(ifCacheHasMorePostsSaga, cache.hasMore);
      }
    }
  } catch (err) {
    log.warn(err);
  }
}

export function* fetchApiPostsSaga({
  payload: {numRequest, lastFetchedTstamp},
}: FetchApiPostsStartAction): SagaIterator<void> {
  try {
    yield put(fetchPostsRequesting(true));
    const {
      data: {message: posts},
    } = yield call(requestHandler, {
      method: HttpMethods.GET,
      url: `/api/patient/community/?numRequest=${numRequest}`,
    });

    if (numRequest === 0) {
      yield put(
        setPostLastFetched(
          lastFetchedTstamp ? Date.now() + SET_TO_STALE : Date.now(),
        ),
      );
      if (typeof posts === 'string') {
        yield put(deleteCachePosts());
        yield put(setHasMorePosts(false));
        // Reset state
      } else {
        if (lastFetchedTstamp && Date.now() - lastFetchedTstamp > TTL_CACHE) {
          yield put(deleteCachePosts());
        }
        const normalizedPosts = yield call(
          normalize,
          posts.postInfo,
          schema.arrayOfPosts,
        );
        yield put(fetchPostsSuccess(normalizedPosts));
        yield put(setHasMorePosts(posts.hasMore));
      }
    } else {
      yield put(setPostLastFetched(Date.now()));
      if (typeof posts === 'string') {
        yield put(setHasMorePosts(false));
      } else {
        const normalizedPosts = yield call(
          normalize,
          posts.postInfo,
          schema.arrayOfPosts,
        );
        yield put(fetchPostsSuccess(normalizedPosts));
        yield put(setHasMorePosts(posts.hasMore));
      }
    }
  } catch (err) {
    log.warn(err);
  } finally {
    yield put(fetchPostsRequesting(false));
  }
}

export function* deleteCachedPostsSaga(): SagaIterator<void> {
  if (isIndexedDBAvailable) {
    yield fork(cacheDb.deletePostsInCache);
  }
}

export function* deletePostSaga({
  payload: postId,
}: DeletePostStartAction): SagaIterator<void> {
  try {
    yield call(requestHandler, {
      method: 'DELETE',
      url: `/api/patient/community/post/${postId}`,
    });
    yield put(deletePostSuccess(postId));
    toast.success('Post deleted');
  } catch (error) {
    log.warn(error);
  }
}

export function* deleteUnpublishedPostSaga({
  payload: postId,
}: DeletePostStartAction): SagaIterator<void> {
  try {
    const firebaseUser = yield call(getCurrentFirebaseUser);
    yield call(deleteUnpublishedPostInFirebase, postId, firebaseUser);
    yield put(deleteUnpublishedPostSuccess(postId));
    toast.success('Post deleted');
  } catch (error) {
    log.warn(error);
  }
}
