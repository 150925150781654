import {put, call, fork, select} from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';
import {SagaIterator} from 'redux-saga';
import log from 'loglevel';
import {
  getCurrentFirebaseUser,
  checkUserChatProfileExists,
  createFirebaseUser,
  createMemberProfileDocument,
  createCoachProfileDocument,
  createDoctorProfileDocument,
} from '_services/firebase/firebase.utils';
import {CreateCoachSuccessAction} from '_redux/admin/coaches/admin-coach-model-actions.types';
import {CreatePrescriberSuccessAction} from '_redux/admin/prescribers/admin-prescriber-model-actions.types';
import {CreateTherapistSuccessAction} from '_redux/admin/therapists/admin-therapist-model-actions.types';
import requestHandler from '_services/api/axios-config';
import {HttpMethods} from '_services/api/axios.types';
import {CurrentUserStateActions} from '../user.types';
import {selectUserChatProfile} from '../user.selectors';
import {UserProfile} from '../auth/user-auth.types';
import {
  getUserChatProfileSuccess,
  getUserChatProfileFailure,
} from './user-chat.actions';
import {signInOrCreateUser} from './user-chat.utils';
import {SendChatPushNotificationAction} from './user-chat-actions.types';

function* createUserProfileDocumentSaga(
  firebaseUser: firebase.User,
  user: UserProfile,
): SagaIterator<void> {
  if ('patientId' in user) {
    yield fork(createMemberProfileDocument, firebaseUser, user);
  }
  if ('mentalHealthCoachId' in user) {
    yield fork(createCoachProfileDocument, firebaseUser, user);
  }
  if ('prescriberId' in user) {
    yield fork(createDoctorProfileDocument, firebaseUser, user);
  }
}

function* checkIfProfileExistsSaga(
  firebaseUser: firebase.User,
  user: UserProfile,
): SagaIterator<void> {
  const profileExists = yield call(
    checkUserChatProfileExists,
    firebaseUser.uid,
    user.role,
  );
  if (!profileExists) {
    yield call(createUserProfileDocumentSaga, firebaseUser, user);
  }
}

export function* getUserChatProfileSaga({
  payload: user,
}: CurrentUserStateActions): SagaIterator<void> {
  try {
    if (
      !user ||
      !/^(mentalhealthcoach|patient|prescriber|therapist)$/.test(user.role)
    )
      return;
    let firebaseUser = yield call(getCurrentFirebaseUser);
    if (firebaseUser) {
      yield call(checkIfProfileExistsSaga, firebaseUser, user);
    } else {
      firebaseUser = yield call(signInOrCreateUser, user);
    }
    yield put(getUserChatProfileSuccess(firebaseUser));
  } catch (error) {
    yield put(getUserChatProfileFailure(null));
  }
}

export function* registerFirebaseUserSaga({
  payload: user,
}:
  | CreateCoachSuccessAction
  | CreatePrescriberSuccessAction
  | CreateTherapistSuccessAction): SagaIterator<void> {
  try {
    yield call(createFirebaseUser, user);
  } catch (error) {
    log.warn(error);
    Sentry.captureException(error);
  }
}

export function* createMemberProfileDocumentSaga({
  payload: user,
}: CurrentUserStateActions): SagaIterator<void> {
  try {
    const firebaseUser = yield select(selectUserChatProfile);
    if (user && 'patientId' in user) {
      yield fork(createMemberProfileDocument, firebaseUser, user);
    }
    yield put(getUserChatProfileSuccess(firebaseUser));
  } catch (error) {
    yield put(getUserChatProfileFailure(null));
  }
}

export function* sendChatPushNotificationSaga({
  payload,
}: SendChatPushNotificationAction): SagaIterator<void> {
  try {
    yield fork(requestHandler, {
      method: HttpMethods.POST,
      url: '/api/push-notifications',
      data: payload,
    });
  } catch (error) {
    log.warn(error);
  }
}
