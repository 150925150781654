import {put, call} from 'redux-saga/effects';
import {normalize} from 'normalizr';
import * as schema from '_redux/schema';
import log from 'loglevel';
import requestHandler from '_services/api/axios-config';
import {SagaIterator} from 'redux-saga';
import {toast} from 'react-toastify';
import history from '_helpers/history';
import {HttpMethods} from '_services/api/axios.types';
import {UserRoles} from '_redux/user/auth/user-auth.types';
import {PaginationData} from '../../../_components/admin/admin.types';
import {FetchPaginatedPrescribersStartAction} from '../therapists/admin-therapist-model-actions.types';
import {
  fetchPaginatedPrescribersFailure,
  fetchPaginatedPrescribersSuccess,
} from '../therapists/admin-therapist-model.actions';
import {
  DeletePrescriberStartAction,
  CreatePrescriberStartAction,
  FetchPrescriberStartAction,
  EditPrescriberAction,
} from './admin-prescriber-model-actions.types';

import {
  fetchPrescribersSuccess,
  fetchPrescribersFailure,
  deletePrescriberSuccess,
  createPrescriberSuccess,
  fetchPrescribersStart,
  fetchPrescriberSuccess,
} from './admin-prescriber-model.actions';
import {Prescriber} from './admin-prescriber-model.types';

export type FetchPaginatedPrescribersResponse = PaginationData & {
  results: Prescriber[];
};

export function* fetchPaginatedPrescribersSaga({
  payload: {countryCode, searchKeywords, page, maxResults, setSubmitting},
}: FetchPaginatedPrescribersStartAction): SagaIterator<void> {
  try {
    const {
      data: {message},
    }: {data: {message: FetchPaginatedPrescribersResponse}} = yield call(
      requestHandler,
      {
        method: HttpMethods.GET,
        url: `/api/admin/prescriber/paginated-prescribers?searchKeywords=${searchKeywords}&maxResults=${maxResults}&page=${page}${
          countryCode ? `&countryCode=${countryCode}` : ''
        }`,
      },
    );
    const normalizedPrescribers = yield call(
      normalize,
      message.results,
      schema.arrayOfPrescribers,
    );

    // Ensure the entities structure is always present
    if (!normalizedPrescribers.entities.prescribers) {
      normalizedPrescribers.entities.prescribers = {};
    }

    setSubmitting(false);
    yield put(
      fetchPaginatedPrescribersSuccess({
        ...normalizedPrescribers,
        filter: 'prescribers',
        pagination: message,
      }),
    );
  } catch (error) {
    yield put(fetchPaginatedPrescribersFailure());
  }
}

export function* fetchPrescribersSaga(): SagaIterator<void> {
  try {
    const {
      data: {message: prescribers},
    } = yield call(requestHandler, {
      method: HttpMethods.GET,
      url: '/api/admin/prescriber/all-prescribers',
    });
    const normalizedPrescribers = yield call(
      normalize,
      prescribers,
      schema.arrayOfPrescribers,
    );
    yield put(
      fetchPrescribersSuccess({
        ...normalizedPrescribers,
        filter: 'prescribers',
      }),
    );
  } catch (error) {
    log.warn(error);
    yield put(fetchPrescribersFailure());
  }
}

export function* deletePrescriberSaga({
  payload: prescriberId,
}: DeletePrescriberStartAction): SagaIterator<void> {
  try {
    yield call(requestHandler, {
      method: HttpMethods.DELETE,
      url: `/api/admin/prescriber/delete-prescriber/${prescriberId}`,
    });
    yield put(
      deletePrescriberSuccess({id: prescriberId, filter: 'prescribers'}),
    );
    if (!history.location.pathname.includes('/admin/prescribers'))
      history.push('/admin/prescribers');
    toast.success('Prescriber deleted');
  } catch (error) {
    log.warn(error);
  }
}

export function* createPrescriberSaga({
  payload: {
    title,
    name: fullName,
    email,
    password,
    insuranceType,
    calendarId,
    states,
    timezone,
    isTestProvider,
    secondaryEmail,
    setSubmitting,
    closeModal,
  },
}: CreatePrescriberStartAction): SagaIterator<void> {
  try {
    const {
      data: {message: prescriber},
    } = yield call(requestHandler, {
      method: HttpMethods.POST,
      url: '/api/admin/prescriber/register',
      data: {
        title,
        fullName,
        email: email.toLowerCase(),
        password,
        insuranceType,
        secondaryEmail,
        calendarId,
        doctorType: UserRoles.prescriber,
        states,
        timezone,
        isTestProvider,
      },
    });
    yield put(fetchPrescribersStart());
    yield put(createPrescriberSuccess(prescriber));
    yield call(closeModal);
    toast.success('Prescriber added');
  } catch (error) {
    yield call(setSubmitting, false);
  }
}

export function* fetchPrescriberSaga({
  payload: id,
}: FetchPrescriberStartAction): SagaIterator<void> {
  try {
    const {
      data: {message: prescriber},
    } = yield call(requestHandler, {
      method: HttpMethods.GET,
      url: `/api/admin/prescriber/single-prescriber?prescriberId=${id}`,
    });
    yield put(fetchPrescriberSuccess(prescriber));
  } catch (error) {
    log.warn(error);
  }
}

export function* editPrescriberSaga({
  payload: {
    title,
    name: fullName,
    email,
    insuranceType,
    calendarId,
    states,
    timezone,
    prescriberId,
    secondaryEmail,
    deelContractId,
    setSubmitting,
    isTestProvider,
  },
}: EditPrescriberAction): SagaIterator<void> {
  try {
    yield call(requestHandler, {
      method: HttpMethods.POST,
      url: `/api/admin/prescriber/edit?prescriberId=${prescriberId}`,
      data: {
        title,
        fullName,
        email: email.toLowerCase(),
        insuranceType,
        calendarId,
        states,
        secondaryEmail,
        timezone,
        prescriberId,
        isTestProvider,
        deelContractId,
      },
    });
    history.push('/admin/prescribers');
    toast.success('Prescriber edited');
  } catch (error) {
    log.warn(error);
    yield call(setSubmitting, false);
  }
}
