import React from 'react';
import {Loader, Dimmer} from 'semantic-ui-react';

const Loading: React.FC = () => (
  <Dimmer active>
    <Loader size="huge" content="Loading..." />
  </Dimmer>
);

export default Loading;
