import {combineReducers} from 'redux';
import {
  AdminProfile,
  CoachProfile,
  DoctorProfile,
  TherapistProfile,
} from '_redux/user/auth/user-auth.types';
import {PaginationData} from '../../_components/admin/admin.types';
import {
  CLEAR_ADMINISTRATOR,
  CLEAR_COACH,
  CLEAR_MEDICATION,
  CLEAR_PRESCRIBER,
  CLEAR_THERAPIST,
  DELETE_ADMINISTRATOR_SUCCESS,
  DELETE_COACH_SUCCESS,
  DELETE_MEDICATION_SUCCESS,
  DELETE_PRESCRIBER_SUCCESS,
  DELETE_THERAPIST_SUCCESS,
  FETCH_ADMINISTRATOR_SUCCESS,
  FETCH_ADMINISTRATORS_FAILURE,
  FETCH_ADMINISTRATORS_START,
  FETCH_ADMINISTRATORS_SUCCESS,
  FETCH_COACH_SUCCESS,
  FETCH_COACHES_FAILURE,
  FETCH_COACHES_START,
  FETCH_COACHES_SUCCESS,
  FETCH_MEDICATION_SUCCESS,
  FETCH_MEDICATIONS_FAILURE,
  FETCH_MEDICATIONS_START,
  FETCH_MEDICATIONS_SUCCESS,
  FETCH_PRESCRIBER_SUCCESS,
  FETCH_PRESCRIBERS_FAILURE,
  FETCH_PRESCRIBERS_PAGINATED_FAILURE,
  FETCH_PRESCRIBERS_PAGINATED_START,
  FETCH_PRESCRIBERS_PAGINATED_SUCCESS,
  FETCH_PRESCRIBERS_START,
  FETCH_PRESCRIBERS_SUCCESS,
  FETCH_THERAPIST_SUCCESS,
  FETCH_THERAPISTS_FAILURE,
  FETCH_THERAPISTS_PAGINATED_FAILURE,
  FETCH_THERAPISTS_PAGINATED_START,
  FETCH_THERAPISTS_PAGINATED_SUCCESS,
  FETCH_THERAPISTS_START,
  FETCH_THERAPISTS_SUCCESS,
  RATE_VIDEO_CALL,
} from './admin.constants';
import {
  AdminModelItemStateActions,
  AdminModelUpdateStateActions,
  AdminRequestingStateActions,
  AllModelsIds,
  ModelsById,
  ModelTypes,
  RateVideoCallActionType,
} from './admin.types';
import {Medication} from './medications/admin-medication-model.types';

export type PaginatedModels<T> = {
  pagination?: PaginationData;
  entities: T;
};

type ModelsByIdState = Record<ModelTypes, PaginatedModels<ModelsById>>;

type AllModelsIdState = Record<ModelTypes, AllModelsIds>;

type ModelRequestingState = Record<ModelTypes, boolean>;

type EditModelItemState = {
  prescriber: DoctorProfile | null;
  coach: CoachProfile | null;
  administrator: AdminProfile | null;
  medication: Medication | null;
  therapist: TherapistProfile | null;
};

function modelsById(
  state: ModelsByIdState = {
    medications: {entities: {}},
    coaches: {entities: {}},
    administrators: {entities: {}},
    prescribers: {entities: {}},
    therapists: {entities: {}},
  },
  {type, payload}: AdminModelUpdateStateActions,
): ModelsByIdState {
  switch (type) {
    case FETCH_PRESCRIBERS_SUCCESS:
    case FETCH_ADMINISTRATORS_SUCCESS:
    case FETCH_COACHES_SUCCESS:
    case FETCH_THERAPISTS_SUCCESS:
    case FETCH_THERAPISTS_PAGINATED_SUCCESS:
    case FETCH_PRESCRIBERS_PAGINATED_SUCCESS:
    case FETCH_MEDICATIONS_SUCCESS: {
      if ('entities' in payload && payload.filter in payload.entities) {
        return {
          ...state,
          [payload.filter]: {
            pagination: payload.pagination,
            entities: payload.pagination
              ? {
                  ...payload.entities[payload.filter],
                }
              : {
                  ...state[payload.filter].entities,
                  ...payload.entities[payload.filter],
                },
          },
        };
      }
      return state;
    }
    case DELETE_PRESCRIBER_SUCCESS:
    case DELETE_ADMINISTRATOR_SUCCESS:
    case DELETE_COACH_SUCCESS:
    case DELETE_THERAPIST_SUCCESS:
    case DELETE_MEDICATION_SUCCESS: {
      if (!('entities' in payload)) {
        const modelCopy = {...state[payload.filter]};
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const {[payload.id]: _, ...rest} = modelCopy;
        return {
          ...state,
          [payload.filter]: rest,
        };
      }
      return state;
    }
    default:
      return state;
  }
}

function allModelsId(
  state: AllModelsIdState = {
    medications: [],
    coaches: [],
    administrators: [],
    prescribers: [],
    therapists: [],
  },
  {type, payload}: AdminModelUpdateStateActions,
): AllModelsIdState {
  switch (type) {
    case FETCH_PRESCRIBERS_SUCCESS:
    case FETCH_ADMINISTRATORS_SUCCESS:
    case FETCH_COACHES_SUCCESS:
    case FETCH_THERAPISTS_SUCCESS:
    case FETCH_THERAPISTS_PAGINATED_SUCCESS:
    case FETCH_PRESCRIBERS_PAGINATED_SUCCESS:
    case FETCH_MEDICATIONS_SUCCESS: {
      if ('result' in payload && payload.filter in payload.entities) {
        const modelCopy = payload.pagination
          ? [...payload.result]
          : [...state[payload.filter], ...payload.result];
        const filteredArr = Array.from(new Set(modelCopy));
        return {
          ...state,
          [payload.filter]: filteredArr,
        };
      }
      return state;
    }
    case DELETE_PRESCRIBER_SUCCESS:
    case DELETE_ADMINISTRATOR_SUCCESS:
    case DELETE_COACH_SUCCESS:
    case DELETE_THERAPIST_SUCCESS:
    case DELETE_MEDICATION_SUCCESS: {
      if (!('result' in payload)) {
        const modelCopy = [...state[payload.filter]];
        const filteredArr = modelCopy.filter(id => id !== payload.id);
        return {
          ...state,
          [payload.filter]: filteredArr,
        };
      }
      return state;
    }
    default:
      return state;
  }
}

function editModelItem(
  state: EditModelItemState = {
    medication: null,
    coach: null,
    prescriber: null,
    administrator: null,
    therapist: null,
  },
  {type, payload}: AdminModelItemStateActions,
): EditModelItemState {
  switch (type) {
    case FETCH_PRESCRIBER_SUCCESS:
    case FETCH_ADMINISTRATOR_SUCCESS:
    case FETCH_COACH_SUCCESS:
    case FETCH_THERAPIST_SUCCESS:
    case FETCH_MEDICATION_SUCCESS:
      return {
        ...state,
        [`${type.split('_')[1].toLowerCase()}`]: payload,
      };
    case CLEAR_PRESCRIBER:
    case CLEAR_ADMINISTRATOR:
    case CLEAR_COACH:
    case CLEAR_MEDICATION:
    case CLEAR_THERAPIST:
      return {
        ...state,
        [`${type.split('_')[1].toLowerCase()}`]: payload,
      };
    default:
      return state;
  }
}

/* eslint-disable complexity */
function modelRequesting(
  state: ModelRequestingState = {
    medications: false,
    coaches: false,
    administrators: false,
    prescribers: false,
    therapists: false,
  },
  {type}: AdminRequestingStateActions,
): ModelRequestingState {
  switch (type) {
    case FETCH_PRESCRIBERS_START:
    case FETCH_ADMINISTRATORS_START:
    case FETCH_COACHES_START:
    case FETCH_THERAPISTS_START:
    case FETCH_MEDICATIONS_START:
    case FETCH_THERAPISTS_PAGINATED_START:
    case FETCH_PRESCRIBERS_PAGINATED_START:
      return {
        ...state,
        [`${type.split('_')[1].toLowerCase()}`]: true,
      };
    case FETCH_PRESCRIBERS_SUCCESS:
    case FETCH_PRESCRIBERS_FAILURE:
    case FETCH_ADMINISTRATORS_SUCCESS:
    case FETCH_ADMINISTRATORS_FAILURE:
    case FETCH_COACHES_SUCCESS:
    case FETCH_COACHES_FAILURE:
    case FETCH_MEDICATIONS_SUCCESS:
    case FETCH_MEDICATIONS_FAILURE:
    case FETCH_THERAPISTS_SUCCESS:
    case FETCH_THERAPISTS_FAILURE:
    case FETCH_THERAPISTS_PAGINATED_SUCCESS:
    case FETCH_THERAPISTS_PAGINATED_FAILURE:
    case FETCH_PRESCRIBERS_PAGINATED_SUCCESS:
    case FETCH_PRESCRIBERS_PAGINATED_FAILURE:
      return {
        ...state,
        [`${type.split('_')[1].toLowerCase()}`]: false,
      };
    default:
      return state;
  }
}

function rateMemberRoom(
  state: {roomId: string} = {
    roomId: '',
  },
  {type, payload}: RateVideoCallActionType,
): {roomId: string} {
  switch (type) {
    case RATE_VIDEO_CALL:
      return {
        ...state,
        roomId: payload,
      };
    default:
      return state;
  }
}

const adminModels = combineReducers({
  byId: modelsById,
  allIds: allModelsId,
  edit: editModelItem,
  requesting: modelRequesting,
  rating: rateMemberRoom,
});

const adminReducer = combineReducers({
  models: adminModels,
});

export default adminReducer;
