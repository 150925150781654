import {
  takeLatest,
  takeEvery,
  takeLeading,
  call,
  all,
} from 'redux-saga/effects';
import {SagaIterator} from 'redux-saga';
import {
  FETCH_PATIENTS_START,
  DOWNLOAD_PATIENT_DETAILS,
  VIEW_PATIENT_START,
  GET_STATE_PRESCRIBERS_START,
  UPDATE_PATIENT_START,
  UPDATE_PATIENT_PRESCRIBER_START,
  FETCH_PATIENT_NOTES_START,
  MIGRATE_PATIENT_NOTES_START,
  SEARCH_PATIENTS_START,
  CREATE_PATIENT_NOTE_START,
  CANCEL_PATIENT_PLAN,
  UPDATE_PATIENT_PLAN,
  UPDATE_PATIENT_THERAPIST_START,
  DELETE_PATIENT_START,
  SET_MEMBER_CURRENT_CHARGE,
  SET_MEMBER_RECURRING_CHARGE,
  UPDATE_PATIENT_INSURANCE_START,
} from './patients.constants';
import {
  fetchPatientsSaga,
  searchPatientsSaga,
} from './list/patients-list.sagas';
import {
  downloadPatientDetailsSaga,
  viewPatientSaga,
  getPrescribersInPatientStateSaga,
  updatePatientSaga,
  updatePatientPrescriberSaga,
  cancelPatientPlanSaga,
  updatePatientPlanSaga,
  updatePatientTherapistSaga,
  deletePatientAccountSaga,
  setMemberCurrentChargeSaga,
  setMemberRecurringChargeSaga,
  updatePatientInsuranceSaga,
} from './profile/patients-profile.sagas';
import {
  fetchPatientNotesSaga,
  createPatientNotesSaga,
  migratePatientNotesSaga,
} from './notes/patients-notes.sagas';

export function* onFetchPatientsStart(): SagaIterator<void> {
  yield takeLeading(FETCH_PATIENTS_START, fetchPatientsSaga);
}

export function* onSearchPatientsStart(): SagaIterator<void> {
  yield takeLatest(SEARCH_PATIENTS_START, searchPatientsSaga);
}

export function* onDownloadPatientDetails(): SagaIterator<void> {
  yield takeEvery(DOWNLOAD_PATIENT_DETAILS, downloadPatientDetailsSaga);
}

export function* onViewPatientStart(): SagaIterator<void> {
  yield takeLatest(VIEW_PATIENT_START, viewPatientSaga);
}

export function* onGetPrescribersInPatientStateStart(): SagaIterator<void> {
  yield takeLatest(
    GET_STATE_PRESCRIBERS_START,
    getPrescribersInPatientStateSaga,
  );
}

export function* onUpdatePatientStart(): SagaIterator<void> {
  yield takeEvery(UPDATE_PATIENT_START, updatePatientSaga);
}

export function* onSetMemberCurrentCharge(): SagaIterator<void> {
  yield takeEvery(SET_MEMBER_CURRENT_CHARGE, setMemberCurrentChargeSaga);
}

export function* onSetMemberRecurringCharge(): SagaIterator<void> {
  yield takeEvery(SET_MEMBER_RECURRING_CHARGE, setMemberRecurringChargeSaga);
}

export function* onUpdatePatientPrescriberStart(): SagaIterator<void> {
  yield takeLatest(
    UPDATE_PATIENT_PRESCRIBER_START,
    updatePatientPrescriberSaga,
  );
}

export function* onUpdatePatientTherapistStart(): SagaIterator<void> {
  yield takeLatest(UPDATE_PATIENT_THERAPIST_START, updatePatientTherapistSaga);
}

export function* onFetchPatientNotesStart(): SagaIterator<void> {
  yield takeLatest(FETCH_PATIENT_NOTES_START, fetchPatientNotesSaga);
}

export function* onCreatePatientNoteStart(): SagaIterator<void> {
  yield takeLatest(CREATE_PATIENT_NOTE_START, createPatientNotesSaga);
}

export function* onCancelPatientPlan(): SagaIterator<void> {
  yield takeLatest(CANCEL_PATIENT_PLAN, cancelPatientPlanSaga);
}

export function* onUpdatePatientPlan(): SagaIterator<void> {
  yield takeLatest(UPDATE_PATIENT_PLAN, updatePatientPlanSaga);
}

export function* onMigratePatientNotes(): SagaIterator<void> {
  yield takeLatest(MIGRATE_PATIENT_NOTES_START, migratePatientNotesSaga);
}

export function* onPatientAccountDelete(): SagaIterator<void> {
  yield takeLatest(DELETE_PATIENT_START, deletePatientAccountSaga);
}

export function* onUpdatePatientInsurance(): SagaIterator<void> {
  yield takeLatest(UPDATE_PATIENT_INSURANCE_START, updatePatientInsuranceSaga);
}

export function* patientsSagas(): SagaIterator<void> {
  yield all([
    call(onFetchPatientsStart),
    call(onSearchPatientsStart),
    call(onDownloadPatientDetails),
    call(onViewPatientStart),
    call(onGetPrescribersInPatientStateStart),
    call(onUpdatePatientStart),
    call(onUpdatePatientPrescriberStart),
    call(onUpdatePatientTherapistStart),
    call(onFetchPatientNotesStart),
    call(onCreatePatientNoteStart),
    call(onMigratePatientNotes),
    call(onCancelPatientPlan),
    call(onUpdatePatientPlan),
    call(onUpdatePatientPlan),
    call(onPatientAccountDelete),
    call(onSetMemberCurrentCharge),
    call(onSetMemberRecurringCharge),
    call(onUpdatePatientInsurance),
  ]);
}
