import React from 'react';
import styled from 'styled-components';
export interface SvgIconProps {
  width?: number;
  height?: number;
  strokeWidth?: number;
  strokeColor?: string;
  fillColor?: string;
  rotateCenter?: number;
  classes?: string;
  onClick?: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SvgIcon extends React.FC<SvgIconProps> {}

export const SvgContainer = styled.svg<{color: string}>`
  color: ${props => props.color};
  stroke: currentColor;
`;
