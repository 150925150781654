import React, {FC} from 'react';
import {Image} from 'semantic-ui-react';
import HeroImg5xWebP from '_assets/img/wellnite-auth-hero_1340.webp';
import HeroImg4xWebP from '_assets/img/wellnite-auth-hero_787.webp';
import HeroImg3xWebP from '_assets/img/wellnite-auth-hero_609.webp';
import HeroImg2xWebP from '_assets/img/wellnite-auth-hero_430.webp';
import HeroImg1xWebP from '_assets/img/wellnite-auth-hero_200.webp';
import HeroImg5x from '_assets/img/wellnite-auth-hero_1340.png';
import HeroImg4x from '_assets/img/wellnite-auth-hero_787.png';
import HeroImg3x from '_assets/img/wellnite-auth-hero_609.png';
import HeroImg2x from '_assets/img/wellnite-auth-hero_430.png';
import HeroImg1x from '_assets/img/wellnite-auth-hero_200.png';

const LoginHeroPicture: FC = () => {
  return (
    <picture>
      <source
        type="image/webp"
        srcSet={HeroImg5xWebP}
        media="(min-width: 1340px)"
      />
      <source
        type="image/webp"
        srcSet={HeroImg4xWebP}
        media="(min-width: 787px)"
      />
      <source
        type="image/webp"
        srcSet={HeroImg3xWebP}
        media="(min-width: 609px)"
      />
      <source
        type="image/webp"
        srcSet={HeroImg2xWebP}
        media="(min-width: 430px)"
      />
      <source
        type="image/webp"
        srcSet={HeroImg1xWebP}
        media="(min-width: 200px)"
      />
      <source type="image/png" srcSet={HeroImg5x} media="(min-width: 1340px)" />
      <source type="image/png" srcSet={HeroImg4x} media="(min-width: 787px)" />
      <source type="image/png" srcSet={HeroImg3x} media="(min-width: 609px)" />
      <source type="image/png" srcSet={HeroImg2x} media="(min-width: 430px)" />
      <Image src={HeroImg1x} fluid />
    </picture>
  );
};

export default LoginHeroPicture;
