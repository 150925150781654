import React from 'react';
import {Link, withRouter, RouteComponentProps} from 'react-router-dom';
import {MenuItem, Icon} from 'semantic-ui-react';
import {PRIMARY_COLOR, PRIMARY_COLOR_LIGHT} from '_helpers/style-guide';
import {UserRoles} from '_redux/user/auth/user-auth.types';
import {useSelector, useDispatch} from 'react-redux';
import {signOutStart} from '_redux/user/auth/user-auth.actions';
import styled from 'styled-components';
import {selectUnreadNotificationsCount} from '_redux/notifications/notifications.selectors';
import {selectUserProfile, selectPatientId} from '_redux/user/user.selectors';
import {HeaderMenuItem} from './base-layout.types';
import {MENU_ITEMS} from './base-layout.constants';

const NotificationIconWrapper = styled.div`
  position: relative;
  display: inline-block;
`;
export const NotificationIcon = styled.div`
  background-color: red;
  position: absolute;
  display: block;
  right: -0.825rem;
  width: 0.88rem;
  height: 0.88rem;
  border-radius: 50%;
  color: white;
  font-size: 0.7rem;
  font-weight: bold;

  @media only screen and (max-width: 768px) {
    font-size: 0.4rem;
    width: 0.5rem;
    height: 0.5rem;
    right: -16.125rem;
    top: -0.8125rem;
  }
`;
//const {unreadNotifsLength} = useUserNotifications()

type HeaderMenuItemsProps = RouteComponentProps & {
  userRole: UserRoles;
  mobile?: boolean;
};

const HeaderMenuItems: React.FC<HeaderMenuItemsProps> = ({
  userRole,
  mobile,
  location: {pathname},
}) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUserProfile);
  const uniqueId = useSelector(selectPatientId);
  const unreadNotifications = useSelector(selectUnreadNotificationsCount);
  const handleLogout = (): void => {
    dispatch(signOutStart(user!.role, uniqueId));
  };

  if (pathname.endsWith('/thanks')) {
    return <div />;
  }
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {MENU_ITEMS[UserRoles[userRole]].map(
        ({name, route, icon}: HeaderMenuItem) => {
          if (route === '/logout') {
            return (
              <MenuItem
                key={name}
                as="a"
                onClick={handleLogout}
                style={{
                  color: mobile ? PRIMARY_COLOR_LIGHT : PRIMARY_COLOR,
                  // fontFamily: ['Raleway', 'sans-serif'],
                }}
              >
                <Icon name={icon} />
                {name}
              </MenuItem>
            );
          }
          return (
            <MenuItem
              key={name}
              as={Link}
              to={route}
              style={{
                color: mobile ? PRIMARY_COLOR_LIGHT : PRIMARY_COLOR,
                // fontFamily: ['Raleway', 'sans-serif'],
              }}
              active={route === pathname}
            >
              {name === 'Notifications' ? (
                <>
                  {unreadNotifications > 0 && (
                    <NotificationIconWrapper>
                      <NotificationIcon>{unreadNotifications}</NotificationIcon>
                    </NotificationIconWrapper>
                  )}
                  <Icon name={icon} />
                  {name}
                </>
              ) : (
                <>
                  <Icon name={icon} />
                  {name}
                </>
              )}
            </MenuItem>
          );
        },
      )}
    </>
  );
};

export default withRouter(HeaderMenuItems);
