import {CoachProfile} from '_redux/user/auth/user-auth.types';
import {
  DELETE_COACH_START,
  DELETE_COACH_SUCCESS,
  FETCH_COACHES_START,
  FETCH_COACHES_SUCCESS,
  FETCH_COACHES_FAILURE,
  CREATE_COACH_START,
  CREATE_COACH_SUCCESS,
  FETCH_COACH_START,
  EDIT_COACH,
  CLEAR_COACH,
  FETCH_COACH_SUCCESS,
} from '../admin.constants';
import {AdminsActionTypes, ModelNameAndItemId} from '../admin.types';
import {
  NormalizedCoaches,
  CreateCoachCredentials,
  EditCoachCredentials,
} from './admin-coach-model.types';

export function fetchCoachesStart(): AdminsActionTypes {
  return {
    type: FETCH_COACHES_START,
  };
}

export function fetchCoachesSuccess(
  coaches: NormalizedCoaches,
): AdminsActionTypes {
  return {
    type: FETCH_COACHES_SUCCESS,
    payload: coaches,
  };
}

export function fetchCoachesFailure(): AdminsActionTypes {
  return {
    type: FETCH_COACHES_FAILURE,
  };
}

export function createCoachStart(
  payload: CreateCoachCredentials,
): AdminsActionTypes {
  return {
    type: CREATE_COACH_START,
    payload,
  };
}

export function createCoachSuccess(coach: CoachProfile): AdminsActionTypes {
  return {
    type: CREATE_COACH_SUCCESS,
    payload: coach,
  };
}

export function deleteCoachStart(coachId: string): AdminsActionTypes {
  return {
    type: DELETE_COACH_START,
    payload: coachId,
  };
}

export function deleteCoachSuccess({
  id,
  filter = 'coaches',
}: ModelNameAndItemId): AdminsActionTypes {
  return {
    type: DELETE_COACH_SUCCESS,
    payload: {id, filter},
  };
}

export function fetchCoachStart(id: string): AdminsActionTypes {
  return {
    type: FETCH_COACH_START,
    payload: id,
  };
}

export function fetchCoachSuccess(coach: CoachProfile): AdminsActionTypes {
  return {
    type: FETCH_COACH_SUCCESS,
    payload: coach,
  };
}

export function editCoach(payload: EditCoachCredentials): AdminsActionTypes {
  return {
    type: EDIT_COACH,
    payload,
  };
}

export function clearCoach(): AdminsActionTypes {
  return {
    type: CLEAR_COACH,
    payload: null,
  };
}
