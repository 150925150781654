import {combineReducers} from 'redux';
import {SliceStatus} from '_redux/redux.types';
import {
  ADD_NEW_NOTIFICATION,
  DELETE_NOTIFICATION_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  GET_NOTIFICATIONS_START,
  GET_NOTIFICATIONS_SUCCESS,
  UPDATE_NOTIFICATION_STATE_SUCCESS,
  UPDATE_NOTIFICATION_STATUS_SUCCESS,
  UPDATE_SHARE_RESPONSE_NOTIFICATION_SUCCESS,
} from './notifications.constants';
import {
  NotificationsDictionary,
  NotificationsIdList,
  NotificationsRequestingStateActions,
  NotificationsUpdateStateActions,
} from './notifications.types';

type NotificationsByIdState = {
  entities: NotificationsDictionary;
  unreadCount: number;
};

// eslint-disable-next-line complexity
function notificationsById(
  state: NotificationsByIdState = {
    entities: {},
    unreadCount: 0,
  },
  {type, payload}: NotificationsUpdateStateActions,
): NotificationsByIdState {
  switch (type) {
    case GET_NOTIFICATIONS_SUCCESS: {
      if (typeof payload !== 'string' && 'entities' in payload) {
        return {
          ...state,
          entities: {
            ...state.entities,
            ...payload.entities.notifications,
          },
          unreadCount: payload.unreadCount ?? state.unreadCount,
        };
      }
      return state;
    }
    case ADD_NEW_NOTIFICATION: {
      if (typeof payload !== 'string' && 'notificationType' in payload) {
        return {
          ...state,
          entities: {
            ...state.entities,
            [payload._id]: payload,
          },
          unreadCount: state.unreadCount + 1,
        };
      }
      return state;
    }
    case UPDATE_NOTIFICATION_STATE_SUCCESS: {
      if (typeof payload !== 'string' && 'changes' in payload) {
        return {
          ...state,
          entities: {
            ...state.entities,
            [payload.id]: {
              ...state.entities[payload.id],
              ...payload.changes,
            },
          },
          unreadCount: state.unreadCount - 1,
        };
      }
      return state;
    }
    case UPDATE_SHARE_RESPONSE_NOTIFICATION_SUCCESS: {
      if (typeof payload !== 'string' && 'changes' in payload) {
        return {
          ...state,
          entities: {
            ...state.entities,
            [payload.id]: {
              ...state.entities[payload.id],
              notification: {
                ...state.entities[payload.id].notification,
                data: {
                  ...state.entities[payload.id].notification.data,
                  ...payload.changes,
                },
              },
            },
          },
        };
      }
      return state;
    }
    case UPDATE_NOTIFICATION_STATUS_SUCCESS: {
      if (typeof payload !== 'string' && 'changes' in payload) {
        return {
          ...state,
          entities: {
            ...state.entities,
            [payload.id]: {
              ...state.entities[payload.id],
              notification: {
                ...state.entities[payload.id].notification,
                data: {
                  ...state.entities[payload.id].notification.data,
                  ...payload.changes,
                },
              },
            },
          },
        };
      }
      return state;
    }
    case DELETE_NOTIFICATION_SUCCESS: {
      if (typeof payload === 'string') {
        const stateCopy = {...state.entities};
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const {[payload]: _, ...rest} = stateCopy;
        const unreadCount =
          _.state === 'unread' ? state.unreadCount - 1 : state.unreadCount;
        return {
          ...state,
          entities: {
            ...rest,
          },
          unreadCount,
        };
      }
      return state;
    }
    default:
      return state;
  }
}

function allNotificationsIds(
  state: NotificationsIdList = [],
  {type, payload}: NotificationsUpdateStateActions,
): NotificationsIdList {
  switch (type) {
    case GET_NOTIFICATIONS_SUCCESS: {
      if (typeof payload !== 'string' && 'result' in payload) {
        const stateCopy = [...state, ...payload.result];
        return Array.from(new Set(stateCopy));
      }
      return state;
    }
    case ADD_NEW_NOTIFICATION: {
      if (typeof payload !== 'string' && '_id' in payload) {
        return [...state, payload._id];
      }
      return state;
    }
    case DELETE_NOTIFICATION_SUCCESS: {
      if (typeof payload === 'string') {
        return state.filter(id => id !== payload);
      }
      return state;
    }
    default:
      return state;
  }
}

function notificationsRequesting(
  state = SliceStatus.idle,
  {type}: NotificationsRequestingStateActions,
): SliceStatus {
  switch (type) {
    case GET_NOTIFICATIONS_START:
      return SliceStatus.pending;
    case GET_NOTIFICATIONS_SUCCESS:
      return SliceStatus.resolved;
    case GET_NOTIFICATIONS_FAILURE:
      return SliceStatus.rejected;
    default:
      return state;
  }
}

const notificationsReducer = combineReducers({
  byId: notificationsById,
  allIds: allNotificationsIds,
  requesting: notificationsRequesting,
});

export default notificationsReducer;
