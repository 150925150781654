import React from 'react';
import {Icon} from 'semantic-ui-react';
import StyledFooter from './footer.styles';

const Footer: React.FC = () => (
  <StyledFooter as="footer">
    <Icon size="large" name="facebook f" />
    <Icon size="large" name="twitter" />
    <Icon size="large" name="youtube" />
    <Icon size="large" name="linkedin" />
    <p>&copy; {new Date().getFullYear()} by Wellnite Health</p>
  </StyledFooter>
);

export default Footer;
