import {combineReducers} from 'redux';
import {
  FETCH_PATIENT_APPOINTMENTS_SUCCESS,
  FETCH_PATIENT_APPOINTMENTS_START,
  FETCH_PATIENT_APPOINTMENTS_FAILURE,
  FETCH_PROVIDER_APPOINTMENTS_START,
  FETCH_PROVIDER_APPOINTMENTS_SUCCESS,
  FETCH_PROVIDER_APPOINTMENTS_FAILURE,
  FETCH_AVAILABILITY_INFO_SUCCESS,
  FETCH_AVAILABILITY_INFO_START,
  FETCH_AVAILABILITY_INFO_FAILURE,
  CANCEL_PATIENT_APPOINTMENT_SUCCESS,
} from './appointments.constants';
import {
  Appointment,
  AppointmentsRequestingStateActions,
  AvailabilityInfoIdList,
  AvailabilityInfoDictionary,
  AppointmentsListStateActions,
} from './appointments.types';
import {FetchAvailabilityInfoSuccessAction} from './appointments-actions.types';

type AppointmentsAvailabilityInfoByIdState = {
  doctor_consultation: {
    entities: AvailabilityInfoDictionary;
    appointmentTypeId: string;
  };
  chat_with_coach: {
    entities: AvailabilityInfoDictionary;
    appointmentTypeId: string;
  };
  video_call_with_therapist: {
    entities: AvailabilityInfoDictionary;
    appointmentTypeId: string;
  };
};

type AllAppointmentsAvailabilityIdState = {
  doctor_consultation: AvailabilityInfoIdList;
  chat_with_coach: AvailabilityInfoIdList;
  video_call_with_therapist: AvailabilityInfoIdList;
};

function appointmentsAvailabilityInfoById(
  state: AppointmentsAvailabilityInfoByIdState = {
    doctor_consultation: {entities: {}, appointmentTypeId: ''},
    chat_with_coach: {entities: {}, appointmentTypeId: ''},
    video_call_with_therapist: {entities: {}, appointmentTypeId: ''},
  },
  {type, payload}: FetchAvailabilityInfoSuccessAction,
): AppointmentsAvailabilityInfoByIdState {
  switch (type) {
    case FETCH_AVAILABILITY_INFO_SUCCESS:
      return {
        ...state,
        [payload.appointmentType]: {
          entities: {
            ...state[payload.appointmentType].entities,
            ...payload.entities.availabilityInfo,
          },
          appointmentTypeId: payload.appointmentTypeId,
        },
      };
    default:
      return state;
  }
}

function allAppointmentsAvailabilityInfoIds(
  state: AllAppointmentsAvailabilityIdState = {
    doctor_consultation: [],
    chat_with_coach: [],
    video_call_with_therapist: [],
  },
  {type, payload}: FetchAvailabilityInfoSuccessAction,
): AllAppointmentsAvailabilityIdState {
  switch (type) {
    case FETCH_AVAILABILITY_INFO_SUCCESS: {
      const filteredArr = Array.from(
        new Set([...state[payload.appointmentType], ...payload.result]),
      );
      return {
        ...state,
        [payload.appointmentType]: filteredArr,
      };
    }
    default:
      return state;
  }
}

function appointmentsRequesting(
  state = false,
  {type}: AppointmentsRequestingStateActions,
): boolean {
  switch (type) {
    case FETCH_PATIENT_APPOINTMENTS_START:
    case FETCH_PROVIDER_APPOINTMENTS_START:
    case FETCH_AVAILABILITY_INFO_START:
      return true;
    case FETCH_PATIENT_APPOINTMENTS_SUCCESS:
    case FETCH_PATIENT_APPOINTMENTS_FAILURE:
    case FETCH_PROVIDER_APPOINTMENTS_SUCCESS:
    case FETCH_PROVIDER_APPOINTMENTS_FAILURE:
    case FETCH_AVAILABILITY_INFO_SUCCESS:
    case FETCH_AVAILABILITY_INFO_FAILURE:
      return false;
    default:
      return state;
  }
}

function appointmentsList(
  state: Appointment[] = [],
  {type, payload}: AppointmentsListStateActions,
): Appointment[] {
  switch (type) {
    case FETCH_PATIENT_APPOINTMENTS_SUCCESS:
    case FETCH_PROVIDER_APPOINTMENTS_SUCCESS: {
      if (typeof payload === 'string') return state;
      return payload;
    }
    case CANCEL_PATIENT_APPOINTMENT_SUCCESS: {
      if (typeof payload !== 'string') return state;
      const stateCopy = [...state];
      return stateCopy.filter(
        appointment => `${appointment.appointmentID}` !== payload,
      );
    }
    default:
      return state;
  }
}

const appointmentsAvailability = combineReducers({
  byId: appointmentsAvailabilityInfoById,
  allIds: allAppointmentsAvailabilityInfoIds,
});

const appointmentsReducer = combineReducers({
  list: appointmentsList,
  availability: appointmentsAvailability,
  requesting: appointmentsRequesting,
});

export default appointmentsReducer;
