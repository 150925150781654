/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import Helmet from 'react-helmet';
import firebase from '_services/firebase/firebase';
import Footer from '_layout/footer/footer.component';
import {connect} from 'react-redux';
import {
  selectUserRoleForHeader,
  selectUserEmail,
  selectPrescriberId,
  selectTherapistId,
} from '_redux/user/user.selectors';
import {UserRoles} from '_redux/user/auth/user-auth.types';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {toast} from 'react-toastify';
import {createStructuredSelector} from 'reselect';
import {AppState} from '_redux/root-reducer';
import log from 'loglevel';
import DesktopContainer from './desktop-layout.component';
import MobileContainer from './mobile-layout.component';
import BaseLayoutContainer from './base-layout.styles';

const toastId = `${Math.random().toString(16)}000000000`.substr(2, 8);

type ResponsiveContainerState = {
  prescriberNotification: boolean;
  therapistNotification: boolean;
  videoCallRef: firebase.database.Reference;
  videoWaitingRoomRef: firebase.database.Reference;
};

type ResponsiveContainerProps = RouteComponentProps & {
  userRole: UserRoles;
  userEmail: string | undefined;
  prescriberId: string;
  therapistId: string;
};

class ResponsiveContainer extends React.Component<
  ResponsiveContainerProps,
  ResponsiveContainerState
> {
  state: ResponsiveContainerState = {
    prescriberNotification: false,
    therapistNotification: false,
    videoCallRef: firebase.database().ref('videoCall'),
    videoWaitingRoomRef: firebase.database().ref('videoWaitingRoom'),
  };

  doctorUser = this.props.userRole === UserRoles.prescriber;
  adminUser = this.props.userRole === UserRoles.admin;
  therapistUser = this.props.userRole === UserRoles.therapist;

  componentDidMount(): void {
    const {prescriberNotification, therapistNotification} = this.state;
    const {prescriberId, therapistId} = this.props;
    if (this.doctorUser && prescriberId && !prescriberNotification) {
      this.doctorNotifListener();
    }

    if (this.therapistUser && therapistId && !therapistNotification) {
      this.therapistNotifListener();
    }
  }

  // eslint-disable-next-line complexity
  componentDidUpdate(): void {
    const {prescriberId, therapistId} = this.props;
    const {
      prescriberNotification,
      therapistNotification,
      videoCallRef,
      videoWaitingRoomRef,
    } = this.state;
    if (this.doctorUser && prescriberId && !prescriberNotification) {
      this.doctorNotifListener();
    }

    if (this.therapistUser && therapistId && !therapistNotification) {
      this.therapistNotifListener();
    }

    if (!this.doctorUser && !this.therapistUser) {
      videoCallRef.off();
    }

    if (!this.doctorUser && !this.therapistUser && prescriberNotification) {
      videoWaitingRoomRef.off();
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({prescriberNotification: false});
    }

    if (!this.doctorUser && !this.therapistUser && therapistNotification) {
      videoWaitingRoomRef.off();
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({therapistNotification: false});
    }
  }

  doctorNotifListener = (): void => {
    this.setState({prescriberNotification: true});
    const that = this;
    this.state.videoWaitingRoomRef
      .child(this.props.prescriberId)
      .on('value', snap => {
        const val = snap.val();
        let patientMsg = '';
        let patientId = '';
        if (val) {
          const [keys] = Object.entries<string>(val);
          patientMsg = keys?.[1];
          patientId = keys?.[0];
        }
        if (patientMsg) {
          try {
            new Audio('/got-it-done.mp3').play();
          } catch (error) {
            log.warn(error);
          }
          toast.info(patientMsg, {
            autoClose: false,
            toastId,
          });
          that.clearNotification(patientId);
        }
      });
  };

  therapistNotifListener = (): void => {
    this.setState({therapistNotification: true});
    const that = this;
    this.state.videoWaitingRoomRef
      .child(this.props.therapistId)
      .on('value', snap => {
        const val = snap.val();
        let patientMsg = '';
        let patientId = '';
        if (val) {
          const [keys] = Object.entries<string>(val);
          patientMsg = keys?.[1];
          patientId = keys?.[0];
        }
        if (patientMsg) {
          try {
            new Audio('/got-it-done.mp3').play();
          } catch (error) {
            log.warn(error);
          }
          toast.info(patientMsg, {
            autoClose: false,
            toastId,
          });
          that.clearNotification(patientId);
        }
      });
  };

  clearNotification = (patientId: string): void => {
    const {prescriberId, therapistId} = this.props;
    this.state.videoWaitingRoomRef
      .child(this.doctorUser ? prescriberId : therapistId)
      .child(patientId)
      .remove();
  };

  render(): JSX.Element {
    const {children, userRole} = this.props;
    return (
      <>
        <Helmet>
          {this.props.userRole === UserRoles.admin ? (
            <script
              type="text/javascript"
              src="https://wellnite-cs-team.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-egccmf/b/24/a44af77267a987a660377e5c46e0fb64/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=e3a15e3e"
            />
          ) : null}
        </Helmet>
        {this.props.location.pathname === '/together/referral' ? (
          <>{children}</>
        ) : (
          <BaseLayoutContainer>
            <DesktopContainer userRole={userRole}>{children}</DesktopContainer>
            <MobileContainer userRole={userRole}>{children}</MobileContainer>
            <Footer />
          </BaseLayoutContainer>
        )}
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector<
  AppState,
  {
    userRole: UserRoles;
    userEmail: string | undefined;
    prescriberId: string;
    therapistId: string;
  }
>({
  userRole: selectUserRoleForHeader,
  userEmail: selectUserEmail,
  prescriberId: selectPrescriberId,
  therapistId: selectTherapistId,
});

export default withRouter(connect(mapStateToProps)(ResponsiveContainer));
