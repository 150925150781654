import {createSelector} from 'reselect';
import {AppState} from '_redux/root-reducer';
import {NotificationsState} from './notifications.types';

function getNotifications(state: AppState): NotificationsState {
  return state.notifications;
}

export const selectNotifications = createSelector(
  getNotifications,
  notifications =>
    notifications.allIds
      .map(id => notifications.byId.entities[id])
      .filter(Boolean)
      .sort((a, b) => b._id.localeCompare(a._id)),
);
export const selectUnreadNotificationsCount = createSelector(
  getNotifications,
  notifications => notifications.byId.unreadCount,
);

export const selectNotificationRequesting = createSelector(
  getNotifications,
  notifications => notifications.requesting,
);

export const selectNotificationsById = createSelector(
  getNotifications,
  notifications => notifications.byId.entities,
);
