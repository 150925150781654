/* eslint-disable import/no-duplicates */
import * as firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';

const hostname = window && window.location && window.location.hostname;

const firebaseConfig = (): {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
} => {
  if (process.env.NODE_ENV === 'development')
    return {
      apiKey: 'AIzaSyDgPGtFOSLYKRtE4aljARC3XDja3NgRByg',
      authDomain: 'wellnite-chat-dev.firebaseapp.com',
      databaseURL: 'https://wellnite-chat-dev.firebaseio.com',
      projectId: 'wellnite-chat-dev',
      storageBucket: 'wellnite-chat-dev.appspot.com',
      messagingSenderId: '171655559512',
      appId: '1:171655559512:web:d87987cb7c755964',
    };

  if (hostname.includes('stage')) {
    return {
      apiKey: 'AIzaSyDmIxhPxXg4CyIotEeJGZQy6v-BbUJu9YA',
      authDomain: 'wellnite-chat-staging.firebaseapp.com',
      databaseURL: 'https://wellnite-chat-staging.firebaseio.com',
      projectId: 'wellnite-chat-staging',
      storageBucket: 'wellnite-chat-staging.appspot.com',
      messagingSenderId: '630039310277',
      appId: '1:630039310277:web:5c90f63eae717975',
    };
  }

  return {
    apiKey: 'AIzaSyBQ8dQ2T2Ot_LzthMIpXj6uwzQmoxoi9z8',
    authDomain: 'wellnite-chat-production.firebaseapp.com',
    databaseURL: 'https://wellnite-chat-production.firebaseio.com',
    projectId: 'wellnite-chat-production',
    storageBucket: 'wellnite-chat-production.appspot.com',
    messagingSenderId: '1053117207646',
    appId: '1:1053117207646:web:6df6c78fdb96e681',
  };
};

firebase.initializeApp(firebaseConfig());

export default firebase;
