import React from 'react';
import {Route, Redirect, RouteComponentProps} from 'react-router-dom';
import {UserRoles} from '_redux/user/auth/user-auth.types';
import {useSelector} from 'react-redux';
import {selectUserProfile} from '_redux/user/user.selectors';
import useQuery from '_components/common/hooks/use-query.hook';

const PrivateRoute: React.FC<{
  component: React.FC<RouteComponentProps>;
  authorizedRoles: UserRoles[];
  path: string | string[];
  exact?: boolean;
}> = ({component: Component, authorizedRoles, ...rest}) => {
  const user = useSelector(selectUserProfile);
  const query = useQuery();

  const renderPrivateComponent: React.FC<RouteComponentProps> = props => {
    const from = props.location.pathname;
    const fromWithParams = `${from}?${query.toString()}`;
    if (!user)
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: {from: fromWithParams},
          }}
        />
      );
    return authorizedRoles.includes(user.role) ? (
      <Component {...props} />
    ) : (
      <Redirect to="/" />
    );
  };
  return <Route {...rest} render={renderPrivateComponent} />;
};

export default PrivateRoute;
