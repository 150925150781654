import {
  UPDATE_ACCOUNT_START,
  UPDATE_ACCOUNT_SUCCESS,
  CLEAR_NOTIFICATIONS_START,
  CLEAR_NOTIFICATIONS_SUCCESS,
  CLEAR_NOTIFICATIONS_FAILURE,
} from '../user.constants';
import {UserActionTypes} from '../user.types';
import {UserProfile, MemberProfile} from '../auth/user-auth.types';
import {UpdateAccountCredentials} from './user-account.types';

export function updateAccountStart(
  updatedDetails: UpdateAccountCredentials,
): UserActionTypes {
  return {
    type: UPDATE_ACCOUNT_START,
    payload: updatedDetails,
  };
}

export function updateAccountSuccess(user: UserProfile): UserActionTypes {
  return {
    type: UPDATE_ACCOUNT_SUCCESS,
    payload: user,
  };
}

export function clearNotificationsStart(): UserActionTypes {
  return {
    type: CLEAR_NOTIFICATIONS_START,
  };
}

export function clearNotificationsSuccess(
  user: MemberProfile,
): UserActionTypes {
  return {
    type: CLEAR_NOTIFICATIONS_SUCCESS,
    payload: user,
  };
}

export function clearNotificationsFailure(): UserActionTypes {
  return {
    type: CLEAR_NOTIFICATIONS_FAILURE,
  };
}
