import md5 from 'crypto-js/md5';
import log from 'loglevel';
import {Post} from '_components/community/community.types';
import {
  MemberProfile,
  CoachProfile,
  DoctorProfile,
  CurrentUser,
  UserRoles,
  TherapistProfile,
} from '_redux/user/auth/user-auth.types';
import {TNotification} from '_redux/notifications/notifications.types';
import firebase from './firebase';

export async function checkUserChatProfileExists(
  uid: string,
  role: UserRoles,
): Promise<boolean> {
  try {
    const snap = await firebase
      .database()
      .ref(
        `${
          role === 'mentalhealthcoach'
            ? 'mentalHealthCoach'
            : role === 'patient'
            ? 'users'
            : `${role}s`
        }`,
      )
      .child(uid)
      .once('value');
    return snap.child('channelId').exists();
  } catch (error) {
    log.warn(error);
    return false;
  }
}

export async function createMemberProfileDocument(
  firebaseUser: firebase.User,
  additionalData: MemberProfile,
): Promise<void> {
  if (!firebaseUser) return;
  const profileExists = await checkUserChatProfileExists(
    firebaseUser.uid,
    UserRoles.member,
  );
  if (profileExists) return;
  const channelsRef = firebase.database().ref('channels');
  const channel = channelsRef.push();
  if (channel.key) {
    const newChannel = {
      id: channel.key,
      name: firebaseUser.displayName,
    };
    channelsRef.child(channel.key).update(newChannel);

    firebase
      .database()
      .ref(`users/${firebaseUser.uid}`)
      .set({
        prescriberDetails: additionalData.prescriberDetails,
        channelId: channel.key,
        fullName: firebaseUser.displayName,
        avatar: firebaseUser.photoURL,
        email: firebaseUser.email,
        patientId: additionalData.patientId,
      });
  }
}

export function createDoctorProfileDocument(
  firebaseUser: firebase.User,
  additionalData: DoctorProfile,
): void {
  if (!firebaseUser) return;
  const channelsRef = firebase.database().ref('channels');
  const channel = channelsRef.push();
  if (channel.key) {
    const newChannel = {
      id: channel.key,
      name: firebaseUser.displayName,
    };
    channelsRef.child(channel.key).update(newChannel);

    firebase
      .database()
      .ref(`prescribers/${firebaseUser.uid}`)
      .set({
        channelId: channel.key,
        fullName: firebaseUser.displayName,
        avatar: firebaseUser.photoURL,
        email: firebaseUser.email,
        prescriberId: additionalData.prescriberId,
      });
  }
}

export function createTherapistProfileDocument(
  firebaseUser: firebase.User,
  additionalData: TherapistProfile,
): void {
  if (!firebaseUser) return;
  const channelsRef = firebase.database().ref('channels');
  const channel = channelsRef.push();
  if (channel.key) {
    const newChannel = {
      id: channel.key,
      name: firebaseUser.displayName,
    };
    channelsRef.child(channel.key).update(newChannel);

    firebase
      .database()
      .ref(`therapists/${firebaseUser.uid}`)
      .set({
        channelId: channel.key,
        fullName: firebaseUser.displayName,
        avatar: firebaseUser.photoURL,
        email: firebaseUser.email,
        therapistId: additionalData.therapistId,
      });
  }
}

export function createCoachProfileDocument(
  firebaseUser: firebase.User,
  additionalData: CoachProfile,
): void {
  if (!firebaseUser) return;

  firebase
    .database()
    .ref(`mentalHealthCoach/${firebaseUser.uid}`)
    .set({
      fullName: firebaseUser.displayName,
      avatar: firebaseUser.photoURL,
      email: firebaseUser.email,
      mentalHealthCoachId: additionalData.mentalHealthCoachId,
    });
}

export function createPostInFirebase(
  caption: string,
  content: string,
  image: string,
  user: firebase.User,
): string | void {
  try {
    if (!user) return;
    const postRef = firebase
      .database()
      .ref('userPosts')
      .child(user!.uid)
      .push();
    const postId = postRef.key || '';
    const post: Post = {
      caption,
      content,
      image,
      id: postId,
      poster: {
        id: user?.uid || '',
        fullName: user?.displayName || '',
        avatar: user?.photoURL || '',
        authorId: user?.providerId || '',
      },
      timestamp: firebase.database.ServerValue.TIMESTAMP as number,
    };
    firebase
      .database()
      .ref('userPosts')
      .child(user!.uid)
      .child(postId)
      .update(post);
    // return new postId
    return postId;
  } catch (error) {
    log.warn(error);
  }
}

export function addPostToTimelineInFirebase(
  postId: string,
  user: firebase.User,
): void {
  try {
    if (!user) return;
    firebase
      .database()
      .ref('timeline')
      .child(postId)
      .set({posterUid: user!.uid, postId});
  } catch (error) {
    log.warn(error);
  }
}

export function setShareApprovedInFirebase(
  postId: string,
  user: firebase.User,
): void {
  try {
    if (!user) return;
    firebase
      .database()
      .ref('postShares')
      .child(postId)
      .set('shareApproved');
  } catch (error) {
    log.warn(error);
  }
}

export function getNewNotificationsFromFirebase({
  type,
  uniqueId,
  setStatus,
  handleNewNotification,
}: {
  type: string;
  uniqueId: string;
  setStatus: (status: 'init' | 'open' | 'closed' | 'error') => void;
  handleNewNotification: (data: TNotification) => void;
}): TNotification | void {
  try {
    setStatus('open');
    firebase
      .database()
      .ref(type)
      .child(uniqueId)
      .limitToLast(1)
      .on('child_added', notification => {
        if (notification.val()) {
          handleNewNotification(notification.val());
          deleteNotificationsFromFirebase(type, uniqueId);
        }
      });
  } catch (error) {
    setStatus('error');
    log.warn(error);
  }
}

export function deleteNotificationsFromFirebase(
  type: string,
  uniqueId: string,
): void {
  try {
    firebase
      .database()
      .ref(type)
      .child(uniqueId)
      .remove();
  } catch (error) {
    log.warn(error);
  }
}

export function deleteUnpublishedPostInFirebase(
  postId: string,
  user: firebase.User,
): string | void {
  try {
    if (!user) return;
    firebase
      .database()
      .ref('userPosts')
      .child(user!.uid ?? '')
      .child(postId)
      .remove();
  } catch (error) {
    log.warn(error);
  }
}

export function offNotificationListenerFromFirebase(
  type: string,
  uniqueId: string,
): void {
  try {
    firebase
      .database()
      .ref(type)
      .child(uniqueId)
      .off();
  } catch (error) {
    log.warn(error);
  }
}

export function deletePostFromTimelineInFirebase(
  postId: string,
  user: firebase.User,
): string | void {
  try {
    if (!user) return;
    firebase
      .database()
      .ref('timeline')
      .child(postId)
      .remove();
  } catch (error) {
    log.warn(error);
  }
}

export async function createFirebaseUser(
  user: CurrentUser,
): Promise<firebase.User | null> {
  try {
    if (!user) return null;
    const firebasePwd =
      user.role === 'mentalhealthcoach'
        ? 'mentalHealthCoachId'
        : `${user.role}Id`;
    const firebaseUser = await firebase
      .auth()
      .createUserWithEmailAndPassword(user.email, user[firebasePwd]);
    if (firebaseUser.user) {
      await firebaseUser.user.updateProfile({
        displayName: user.fullName,
        photoURL: `http://gravatar.com/avatar/${md5(user.email)}?id=identicon`,
      });

      if ('patientId' in user) {
        createMemberProfileDocument(firebaseUser.user, user);
      }

      if ('mentalHealthCoachId' in user) {
        createCoachProfileDocument(firebaseUser.user, user);
      }

      if ('prescriberId' in user) {
        createDoctorProfileDocument(firebaseUser.user, user);
      }

      if ('therapistId' in user) {
        createTherapistProfileDocument(firebaseUser.user, user);
      }
    }
    return firebaseUser.user;
  } catch (error) {
    log.warn(error);
    return null;
  }
}

export async function signInFirebaseUser(
  user: CurrentUser,
): Promise<firebase.User | null | string> {
  if (!user) return null;
  const firebasePwd =
    user.role === 'mentalhealthcoach'
      ? 'mentalHealthCoachId'
      : `${user.role}Id`;
  try {
    const firebaseUser = await firebase
      .auth()
      .signInWithEmailAndPassword(user.email, user[firebasePwd]);
    return firebaseUser.user;
  } catch (error) {
    return error.code;
  }
}

export const getCurrentFirebaseUser = (): Promise<firebase.User | null> => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(userAuth => {
      unsubscribe();
      resolve(userAuth);
    }, reject);
  });
};

export const signOutFirebaseUser = (): void => {
  firebase.auth().signOut();
};
