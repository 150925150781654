import appointmentsReducer from './appointments.reducer';
import {
  FetchPatientAppointmentsFailureAction,
  FetchPatientAppointmentsStartAction,
  FetchPatientAppointmentsSuccessAction,
  FetchAvailabilityInfoStartAction,
  FetchAvailabilityInfoSuccessAction,
  FetchAvailabilityInfoFailureAction,
  FetchProviderAppointmentsStartAction,
  FetchProviderAppointmentsSuccessAction,
  FetchProviderAppointmentsFailureAction,
  BookAppointmentAction,
  CancelPatientAppointmentStartAction,
  CancelPatientAppointmentSuccessAction,
} from './appointments-actions.types';

export type Appointment = {
  firstName: string;
  lastName: string;
  email: string;
  dateOfAppointment: string;
  timeOfAppointment: string;
  appointmentID: number;
  calendar: string;
  timeZone: string;
  duration: string;
  category: string;
  patientId?: string;
};

export enum AppointmentTypes {
  'doctor_consultation' = 'doctor_consultation',
  'chat_with_coach' = 'chat_with_coach',
  'video_call_with_therapist' = 'video_call_with_therapist',
}

export type AvailabilityInfo = {
  acuityCalendar: {availabilityDate: string; availabilityTimes: string[]}[];
  category: string;
  providerDetails: {
    email: string;
    fullName: string;
    prescriberId: string;
    mentalHealthCoachId: string;
    therapistId: string;
    acuity: {
      calendarId: number;
    };
  };
};

export type AvailabilityInfoDictionary = {
  [key: string]: AvailabilityInfo;
};

export type AvailabilityInfoIdList = string[];

export type NormalizedAvailabilityInfo = {
  entities: {
    availabilityInfo: AvailabilityInfoDictionary;
  };
  result: AvailabilityInfoIdList;
  appointmentTypeId: string;
  appointmentType: AppointmentTypes;
};

export type BookAppointmentCredentials = {
  appointmentDateTimeString: string;
  providerId: string;
  appointmentType: AppointmentTypes;
  calendarId: string;
  patientTimezone: string;
  appointmentTypeID: string;
  patientEmail: string;
  setSubmitting(isSubmitting: boolean): void;
};

export type AppointmentsRequestingStateActions =
  | FetchPatientAppointmentsStartAction
  | FetchPatientAppointmentsSuccessAction
  | FetchPatientAppointmentsFailureAction
  | FetchProviderAppointmentsStartAction
  | FetchProviderAppointmentsSuccessAction
  | FetchProviderAppointmentsFailureAction
  | FetchAvailabilityInfoStartAction
  | FetchAvailabilityInfoSuccessAction
  | FetchAvailabilityInfoFailureAction;

export type AppointmentsListStateActions =
  | FetchPatientAppointmentsSuccessAction
  | FetchProviderAppointmentsSuccessAction
  | CancelPatientAppointmentSuccessAction;

export type AppointmentsState = ReturnType<typeof appointmentsReducer>;

export type AppointmentsActionTypes =
  | AppointmentsRequestingStateActions
  | AppointmentsListStateActions
  | BookAppointmentAction
  | CancelPatientAppointmentStartAction;
