import {schema} from 'normalizr';
import {isAfter} from 'date-fns';
import {dateTimeStringCompatibility} from '_services/datetime/datetime.utils';
import {AvailabilityInfo} from './appointments/appointments.types';

export const note = new schema.Entity(
  'notes',
  {},
  {
    idAttribute: (value): string => {
      return value.noteId;
    },
  },
);

export const arrayOfNotes = new schema.Array(note);

export const post = new schema.Entity(
  'posts',
  {},
  {
    idAttribute: (value): string => {
      return value.userPost.postId;
    },
  },
);
export const arrayOfPosts = new schema.Array(post);

export const medication = new schema.Entity(
  'medications',
  {},
  {
    idAttribute: (value): string => {
      return value.medicationId;
    },
  },
);

export const arrayOfMedications = new schema.Array(medication);

export const coach = new schema.Entity(
  'coaches',
  {},
  {
    idAttribute: (value): string => {
      return value.mentalHealthCoachId;
    },
  },
);

export const arrayOfCoaches = new schema.Array(coach);

export const admin = new schema.Entity(
  'administrators',
  {},
  {
    idAttribute: (value): string => {
      return value.adminId;
    },
  },
);

export const arrayOfAdmins = new schema.Array(admin);

export const prescriber = new schema.Entity(
  'prescribers',
  {},
  {
    idAttribute: (value): string => {
      return value.prescriberId;
    },
  },
);

export const arrayOfPrescribers = new schema.Array(prescriber);

export const therapist = new schema.Entity(
  'therapists',
  {},
  {
    idAttribute: (value): string => {
      return value.therapistId;
    },
  },
);

export const arrayOfTherapists = new schema.Array(therapist);

export const availabilityInfo = new schema.Entity(
  'availabilityInfo',
  {},
  {
    idAttribute: (value): string => {
      return (
        value?.providerDetails?.prescriberId ??
        value?.providerDetails?.mentalHealthCoachId ??
        value?.providerDetails?.therapistId
      );
    },
    mergeStrategy: (
      entityA: AvailabilityInfo,
      entityB: AvailabilityInfo,
    ): AvailabilityInfo => {
      const dayOne = entityA.acuityCalendar?.[0]?.availabilityDate;
      const dayTwo = entityB.acuityCalendar?.[0]?.availabilityDate;
      let acuityCalendar: {
        availabilityDate: string;
        availabilityTimes: string[];
      }[] = [];
      if (dayOne && dayTwo) {
        const formattedFirstDate = dateTimeStringCompatibility(dayOne);
        const formattedLastDate = dateTimeStringCompatibility(dayTwo);
        acuityCalendar = isAfter(
          new Date(formattedFirstDate),
          new Date(formattedLastDate),
        )
          ? [...entityB.acuityCalendar, ...entityA.acuityCalendar]
          : [...entityA.acuityCalendar, ...entityB.acuityCalendar];
      } else {
        acuityCalendar = [...entityA.acuityCalendar, ...entityB.acuityCalendar];
      }
      return {
        ...entityA,
        ...entityB,
        acuityCalendar,
      };
    },
  },
);

export const arrayOfAvailabilityInfo = new schema.Array(availabilityInfo);

export const notification = new schema.Entity(
  'notifications',
  {},
  {
    idAttribute: (value): string => {
      return value._id;
    },
  },
);

export const arrayOfNotifications = new schema.Array(notification);
