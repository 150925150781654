import {
  takeLatest,
  takeLeading,
  takeEvery,
  all,
  call,
} from 'redux-saga/effects';
import {SagaIterator} from 'redux-saga';
import {
  CREATE_COACH_SUCCESS,
  CREATE_PRESCRIBER_SUCCESS,
  CREATE_THERAPIST_SUCCESS,
} from '_redux/admin/admin.constants';
import {
  CHECK_USER_SESSION_START,
  LOGIN_USER_START,
  CHECK_USER_SESSION_SUCCESS,
  LOGIN_USER_SUCCESS,
  STRIPE_PAYMENT_START,
  STRIPE_PAYMENT_SUCCESS,
  UPDATE_ACCOUNT_START,
  CLEAR_NOTIFICATIONS_START,
  SIGN_OUT_START,
  REQUEST_RESET_PASSWORD_TOKEN,
  REQUEST_RESET_PASSWORD,
  VALIDATE_RESET_PASSWORD_TOKEN,
  GET_UPDATED_USER_START,
  UPDATE_STRIPE_CREDIT_CARD_START,
  UPDATE_STRIPE_PLAN_START,
  SEND_CHAT_PUSH_NOTIFICATION,
  SAVE_PROVIDER_VIDEO_CALL_JOIN_LOG_START,
  SAVE_PROVIDER_VIDEO_CALL_LEAVE_LOG_START,
} from './user.constants';
import {
  getUpdatedUserSaga,
  checkUserSessionSaga,
  loginUserSaga,
  signOutStartSaga,
  requestResetPasswordTokenSaga,
  validateResetPasswordTokenSaga,
  requestResetPasswordSaga,
} from './auth/user-auth.sagas';
import {
  getUserChatProfileSaga,
  registerFirebaseUserSaga,
  createMemberProfileDocumentSaga,
  sendChatPushNotificationSaga,
} from './chat/user-chat.sagas';
import {
  createStripeSubscriptionSaga,
  updateStripeCreditCardSaga,
  updateStripePlanSaga,
} from './payment/user-payment.sagas';
import {
  updateAccountSaga,
  clearNotificationsStartSaga,
} from './account/user-account.sagas';
import {
  saveProviderVideoCallJoinLogSaga,
  saveProviderVideoCallLeaveLogSaga,
} from './video/user-video-saga';

export function* onGetUpdatedUserStart(): SagaIterator<void> {
  yield takeLatest(GET_UPDATED_USER_START, getUpdatedUserSaga);
}

export function* onCheckUserSessionStart(): SagaIterator<void> {
  yield takeLatest(CHECK_USER_SESSION_START, checkUserSessionSaga);
}

export function* onLoginUserStart(): SagaIterator<void> {
  yield takeLatest(LOGIN_USER_START, loginUserSaga);
}

export function* onUserSessionStarted(): SagaIterator<void> {
  yield takeLatest(
    [CHECK_USER_SESSION_SUCCESS, LOGIN_USER_SUCCESS],
    getUserChatProfileSaga,
  );
}

export function* onRegisterUserSuccess(): SagaIterator<void> {
  yield takeLatest(
    [CREATE_COACH_SUCCESS, CREATE_PRESCRIBER_SUCCESS, CREATE_THERAPIST_SUCCESS],
    registerFirebaseUserSaga,
  );
}

export function* onSignOutStart(): SagaIterator<void> {
  yield takeLeading(SIGN_OUT_START, signOutStartSaga);
}

export function* onStripePaymentStart(): SagaIterator<void> {
  yield takeLatest(STRIPE_PAYMENT_START, createStripeSubscriptionSaga);
}

export function* onUpdateStripeCreditCardStart(): SagaIterator<void> {
  yield takeLatest(UPDATE_STRIPE_CREDIT_CARD_START, updateStripeCreditCardSaga);
}

export function* onUpdateStripePlanStart(): SagaIterator<void> {
  yield takeLatest(UPDATE_STRIPE_PLAN_START, updateStripePlanSaga);
}

export function* onStripePaymentSuccess(): SagaIterator<void> {
  yield takeLatest(STRIPE_PAYMENT_SUCCESS, createMemberProfileDocumentSaga);
}

export function* onUpdateAccountStart(): SagaIterator<void> {
  yield takeEvery(UPDATE_ACCOUNT_START, updateAccountSaga);
}

export function* onSendChatPushNotificationStart(): SagaIterator<void> {
  yield takeEvery(SEND_CHAT_PUSH_NOTIFICATION, sendChatPushNotificationSaga);
}

export function* onClearNotificationsStart(): SagaIterator<void> {
  yield takeLatest(CLEAR_NOTIFICATIONS_START, clearNotificationsStartSaga);
}

export function* onRequestResetPasswordToken(): SagaIterator<void> {
  yield takeLatest(REQUEST_RESET_PASSWORD_TOKEN, requestResetPasswordTokenSaga);
}

export function* onValidateResetPasswordToken(): SagaIterator<void> {
  yield takeLatest(
    VALIDATE_RESET_PASSWORD_TOKEN,
    validateResetPasswordTokenSaga,
  );
}

export function* onRequestResetPassword(): SagaIterator<void> {
  yield takeLatest(REQUEST_RESET_PASSWORD, requestResetPasswordSaga);
}

export function* onSaveProviderVideoCallJoinLogStart(): SagaIterator<void> {
  yield takeLatest(
    SAVE_PROVIDER_VIDEO_CALL_JOIN_LOG_START,
    saveProviderVideoCallJoinLogSaga,
  );
}
export function* onSaveProviderVideoCallLeaveLogStart(): SagaIterator<void> {
  yield takeLatest(
    SAVE_PROVIDER_VIDEO_CALL_LEAVE_LOG_START,
    saveProviderVideoCallLeaveLogSaga,
  );
}
export function* userSagas(): SagaIterator<void> {
  yield all([
    call(onGetUpdatedUserStart),
    call(onCheckUserSessionStart),
    call(onLoginUserStart),
    call(onUserSessionStarted),
    call(onRegisterUserSuccess),
    call(onSignOutStart),
    call(onUpdateStripeCreditCardStart),
    call(onUpdateStripePlanStart),
    call(onStripePaymentStart),
    call(onStripePaymentSuccess),
    call(onUpdateAccountStart),
    call(onClearNotificationsStart),
    call(onRequestResetPasswordToken),
    call(onValidateResetPasswordToken),
    call(onRequestResetPassword),
    call(onSendChatPushNotificationStart),
    call(onSaveProviderVideoCallJoinLogStart),
    call(onSaveProviderVideoCallLeaveLogStart),
  ]);
}
