import {put, call} from 'redux-saga/effects';
import log from 'loglevel';
import requestHandler from '_services/api/axios-config';
import {SagaIterator} from 'redux-saga';
import {toast} from 'react-toastify';
import {toFormData} from '_helpers/utils';
import {HttpMethods} from '_services/api/axios.types';
import {UpdateAccountDetailsFormProps} from '../../../_components/account/account.types';
import {UpdateAccountStartAction} from './user-account-actions.types';
import {
  updateAccountSuccess,
  clearNotificationsSuccess,
  clearNotificationsFailure,
} from './user-account.actions';

export function* updateAccountSaga({
  payload: {role, setSubmitting, ...rest},
}: UpdateAccountStartAction): SagaIterator<void> {
  const formKeys = Object.keys(rest);
  rest = rest as UpdateAccountDetailsFormProps;

  let key;

  const filledValues = {} as {companyShouldPay: string};
  for (key of formKeys) {
    if (rest[key]) {
      if (key === 'email' && rest[key]) {
        filledValues[key] = rest[key].toLowerCase();
      } else if (key === 'phone' && rest[key]) {
        // eslint-disable-next-line dot-notation
        filledValues['phone'] = rest[key];
      } else {
        filledValues[key] = rest[key];
      }
      if (rest.companyShouldPay) {
        filledValues.companyShouldPay = 'yes';
      } else if (!rest.companyShouldPay) {
        filledValues.companyShouldPay = 'no';
      }
    }
  }

  const data = yield call(toFormData, filledValues);
  try {
    const {
      data: {message: user},
    } = yield call(requestHandler, {
      method: HttpMethods.POST,
      url: `/api/${role}/profile`,
      data,
    });

    yield put(updateAccountSuccess(user));

    toast.success('Account has been updated!');
  } catch (err) {
    log.warn(err);
  } finally {
    yield call(setSubmitting, false);
  }
}

export function* clearNotificationsStartSaga(): SagaIterator<void> {
  try {
    const {
      data: {message: user},
    } = yield call(requestHandler, {
      method: HttpMethods.POST,
      url: '/api/patient/notifications',
    });
    yield put(clearNotificationsSuccess(user));
    toast.success('Notifications deleted!');
  } catch (error) {
    yield put(clearNotificationsFailure());
  }
}
