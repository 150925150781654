import styled from 'styled-components';
import {MenuItem} from 'semantic-ui-react';

const Hamburger = styled(MenuItem)`
  &&&&&&&& {
    border-radius: 50% !important;
  }

  padding: 0.6rem 0 0 !important;
  min-width: 4rem !important;
  margin: 0 1rem !important;
  background-color: #fff !important;
`;

export default Hamburger;
