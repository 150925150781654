import {formatToTimeZone} from 'date-fns-timezone/dist/formatToTimeZone';
import {format, getDate, getYear, isEqual, isAfter} from 'date-fns';
import {utcToZonedTime, format as formatTZ} from 'date-fns-tz';

const setTimeToTimeZone = (
  time: string | number | Date,
  formatZone: string,
  zone: {
    timeZone: string;
  },
): string => formatToTimeZone(time, formatZone, zone);

export const dateTimeStringCompatibility = (date: string): string => {
  let nth = 0;
  return `${date.replace(/-/g, match => {
    nth += 1;
    return nth < 3 ? '/' : match;
  })} 00:00:00`;
};

export const getFormattedOffset = (
  value: string,
  index: number,
  char: string,
): string => `${value.substring(0, index)}${char}${value.substring(index)}`;

export const replaceAllCharacters = (
  target: string,
  search: string,
  replace: string,
): string => target.split(search).join(replace);

export const roundDownToNearestFive = (value: number): number =>
  Math.floor(value / 5) * 5;

export const getDropdownValues = ({
  year,
  month,
  day,
  hour,
  minute,
  region,
  zone,
  identifier,
}: {
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  region: string;
  zone: string;
  identifier: string;
}): {
  description: string;
  image: string;
  offset: string;
  region: string;
  value: string;
  time: string;
  text: string;
  zone: string;
} => {
  const actualMonth = month + 1;
  const image = region.toLowerCase();
  const time = `${year}-${actualMonth}-${day} ${hour}:${minute}`;

  const formatToTimeZoneObject = {
    timeZone: identifier,
    convertTimeZone: true,
  };

  const formattedToTimeZone = setTimeToTimeZone(
    time,
    'HH:mm a',
    formatToTimeZoneObject,
  );

  const offset = setTimeToTimeZone(time, 'ZZ', formatToTimeZoneObject);

  const formattedToTimeZoneOffset = getFormattedOffset(offset, 3, ':');

  const search = [zone, region, offset, formattedToTimeZoneOffset];
  return {
    description: `${formattedToTimeZone} (GMT ${offset})`,
    image,
    offset: formattedToTimeZoneOffset,
    region,
    value: search.join('|'),
    time: formattedToTimeZone,
    text: `${zone} - ${region}`,
    zone,
  };
};

const getYearFromDate = (date: Date): number => getYear(date);
const getDayOfMonthFromDate = (date: Date): number => getDate(date);
const getFormattedMonthNumber = (date: Date): number =>
  Number(format(date, 'M'));

export const getDefaultNow = (): {
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  second: number;
  millisecond: number;
} => {
  const now = new Date();
  return {
    year: getYearFromDate(now),
    month: getFormattedMonthNumber(now),
    day: getDayOfMonthFromDate(now),
    hour: now.getHours(),
    minute: now.getMinutes(),
    second: now.getSeconds(),
    millisecond: now.getMilliseconds(),
  };
};

export const removePastOpeningsFromCalendar = (
  availableDays: {
    availabilityDate: string;
    availabilityTimes: string[];
  }[],
): {
  availabilityDate: string;
  availabilityTimes: string[];
}[] => {
  const now = new Date(new Date().setHours(0, 0, 0, 0));
  const filteredAvailableDays = availableDays.filter(({availabilityDate}) => {
    const formattedDate = dateTimeStringCompatibility(availabilityDate);
    return (
      isEqual(new Date(formattedDate), now) ||
      isAfter(new Date(formattedDate), now)
    );
  });
  if (filteredAvailableDays.length) {
    const formattedFirstDate = dateTimeStringCompatibility(
      filteredAvailableDays[0].availabilityDate,
    );
    if (isEqual(new Date(formattedFirstDate), now)) {
      const filteredTodayTimes = filteredAvailableDays[0].availabilityTimes.filter(
        t => {
          let nth = 0;
          const safariFix = t
            .replace(/-/g, match => {
              nth += 1;
              return nth < 3 ? '/' : match;
            })
            .replace('T', ' ');
          return isAfter(new Date(safariFix), new Date());
        },
      );

      const finalRes = filteredAvailableDays.slice(1);
      finalRes.unshift({
        ...filteredAvailableDays[0],
        availabilityTimes: filteredTodayTimes,
      });
      return finalRes;
    }
  }
  return filteredAvailableDays;
};
export const convertDateToDiffTimeZone = (
  time: string,
  timeZone: string,
): string => {
  let nth = 0;
  const safariFix = time
    .replace(/-/g, match => {
      nth += 1;
      return nth < 3 ? '/' : match;
    })
    .replace('T', ' ');
  const utcDate = utcToZonedTime(new Date(safariFix), timeZone);
  return formatTZ(utcDate, 'h:mm aaaa', {timeZone});
};
