import {all, call} from 'redux-saga/effects';
import {SagaIterator} from 'redux-saga';
import {userSagas} from './user/user.watchers';
import {postsSagas} from './posts/posts.watchers';
import {adminSagas} from './admin/admin.watchers';
import {patientsSagas} from './patients/patients.watchers';
import {appointmentsSagas} from './appointments/appointments.watchers';
import {notificationsSagas} from './notifications/notifications.watchers';

export default function* rootSaga(): SagaIterator<void> {
  yield all([
    call(userSagas),
    call(postsSagas),
    call(adminSagas),
    call(patientsSagas),
    call(appointmentsSagas),
    call(notificationsSagas),
  ]);
}
