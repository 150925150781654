import React from 'react';
import {Helmet} from 'react-helmet';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
//@ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import styled from 'styled-components';
import LoginOverview from '_components/login/login.component';
import Hero from '_components/common/hero.component';
import TwoColumnsLayout from '_layout/two-columns/two-columns-layout.component';
import PlanDescriptions from '_components/plans/plan-description-list.component';
import {useLocation} from 'react-router';
import LoginHeroPicture from '_components/login/login-hero-picture.component';

const STRINGS_LIST = ['Exceptional care designed for you.'];

const LoginPage: React.FC = () => {
  const location = useLocation<{
    from?: string;
  }>();
  const from =
    location.state &&
    'from' in location.state &&
    typeof location.state.from === 'string'
      ? location.state.from
      : '';
  return (
    <>
      <Helmet>
        <title>Login | Wellnite</title>
        <script>
          {`
            window.ReviewsWidget('#widget', {
                    store: 'wellnite-com',
                    widget: 'prestige-card-carousel',
                    maxReviews: 21,

                    contentMode: 'third-party;product;company;',
                    sku: 'all-product-reviews',

                    structuredData: {
                      product: '',
                      company: '',
                    },

                    richSnippets: {
                      product: true,
                      company: true,
                    },

                    carouselOptions: {
                      transitionSpeed: 320,
                      draggableOnDesktop: true,
                      showSliderArrows: false,
                      autoScroll: true,
                      autoScrollTime: 10,
                      showTitle: true,
                      titleText: 'Our Customers Love Us',
                      showSubTitle: false,
                      starsInSubTitle: true,
                      starsAfterName: false,
                      hideDates: false,
                      hideThirdParty: true,
                    },

                    styles: {
                      starColor: '#0046F5',
                      primaryColor: '#FFFFFF',
                      textColor: '#010101',
                      neutralColor: '#0046F5',
                      borderRadius: '30px',
                      boxShadow:
                        '3px 8px 9px rgba(0,70,245,0.18), 2px 6px 6px rgba(0,70,245,0.19)',
                      logoColor: '#000',
                      subTitleStar: '#0046F5',
                    },
                  })
          `}
        </script>
      </Helmet>
      <TwoColumnsLayout
        left={
          <Hero
            picture={<LoginHeroPicture />}
            children={
              <>
                <PlanDescriptions descriptions={STRINGS_LIST} />
                <div id="widget" css="height: 338px" />
              </>
            }
          />
        }
        right={<LoginOverview redirectTo={from} />}
        border="inherit"
        className="reviews-io-container"
      />
    </>
  );
};
export default LoginPage;
