export async function checkIndexedDBStorageSpace(
  percentage: number,
): Promise<boolean> {
  if (navigator.storage && navigator.storage.estimate) {
    const {quota, usage} = await navigator.storage.estimate();
    if (quota && usage) {
      const spaceUsed = Math.floor(Number(((usage / quota) * 100).toFixed(2)));
      return spaceUsed < percentage;
    }
  }
  return false;
}

export const isIndexedDBAvailable = 'indexedDB' in window;
