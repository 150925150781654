import {
  PostsActionsTypes,
  // CreatePostData,
  NormalizedPosts,
  FetchPostsCredential,
  CreatePostData,
  SharePostData,
} from './posts.types';
import {
  CREATE_POST_START,
  CREATE_POST_SUCCESS,
  FETCH_POSTS_SUCCESS,
  FETCH_POSTS_START,
  SET_POST_LAST_FETCHED,
  FETCH_CACHE_POSTS_START,
  FETCH_API_POSTS_START,
  SET_HAS_MORE_POSTS,
  DELETE_CACHE_POSTS,
  FETCH_POSTS_REQUESTING,
  DELETE_POST_START,
  DELETE_POST_SUCCESS,
  CREATE_POST_FAILURE,
  SHARE_POST_START,
  SHARE_POST_SUCCESS,
  SHARE_POST_FAILURE,
  DELETE_UNPUBLISHED_POST_START,
  DELETE_UNPUBLISHED_POST_SUCCESS,
} from './posts.constants';

export function setPostLastFetched(
  lastFetched: number | null,
): PostsActionsTypes {
  return {
    type: SET_POST_LAST_FETCHED,
    payload: lastFetched,
  };
}

export function createPostStart({
  caption,
  content,
  image,
  notificationId,
  entityId,
  setSubmitting,
}: CreatePostData): PostsActionsTypes {
  return {
    type: CREATE_POST_START,
    payload: {
      caption,
      content,
      image,
      notificationId,
      entityId,
      setSubmitting,
    },
  };
}

export function sharePostStart({
  caption,
  notificationId,
  setSubmitting,
}: SharePostData): PostsActionsTypes {
  return {
    type: SHARE_POST_START,
    payload: {
      caption,
      notificationId,
      setSubmitting,
    },
  };
}

export function sharePostSuccess(): PostsActionsTypes {
  return {
    type: SHARE_POST_SUCCESS,
  };
}

export function sharePostFailure(): PostsActionsTypes {
  return {
    type: SHARE_POST_FAILURE,
  };
}

export function createPostFailure(): PostsActionsTypes {
  return {
    type: CREATE_POST_FAILURE,
  };
}

export function createPostSuccess(): PostsActionsTypes {
  return {
    type: CREATE_POST_SUCCESS,
  };
}

export function fetchPostsRequesting(requesting: boolean): PostsActionsTypes {
  return {
    type: FETCH_POSTS_REQUESTING,
    payload: requesting,
  };
}

export function fetchPostsStart({
  numRequest,
  dateOfLastPost,
}: FetchPostsCredential): PostsActionsTypes {
  return {
    type: FETCH_POSTS_START,
    payload: {numRequest, dateOfLastPost},
  };
}

export function fetchCachePostsStart({
  numRequest,
  dateOfLastPost,
  lastFetchedTstamp,
}: FetchPostsCredential): PostsActionsTypes {
  return {
    type: FETCH_CACHE_POSTS_START,
    payload: {
      numRequest,
      dateOfLastPost,
      lastFetchedTstamp,
    },
  };
}

export function fetchApiPostsStart({
  numRequest,
  lastFetchedTstamp,
}: FetchPostsCredential): PostsActionsTypes {
  return {
    type: FETCH_API_POSTS_START,
    payload: {
      numRequest,
      lastFetchedTstamp,
    },
  };
}

export function fetchPostsSuccess(posts: NormalizedPosts): PostsActionsTypes {
  return {
    type: FETCH_POSTS_SUCCESS,
    payload: posts,
  };
}

export function deleteCachePosts(): PostsActionsTypes {
  return {
    type: DELETE_CACHE_POSTS,
  };
}

export function setHasMorePosts(hasMore: boolean): PostsActionsTypes {
  return {
    type: SET_HAS_MORE_POSTS,
    payload: hasMore,
  };
}

export function deletePostStart(postId: string): PostsActionsTypes {
  return {
    type: DELETE_POST_START,
    payload: postId,
  };
}

export function deletePostSuccess(postId: string): PostsActionsTypes {
  return {
    type: DELETE_POST_SUCCESS,
    payload: postId,
  };
}

export function deleteUnpublishedPostStart(postId: string): PostsActionsTypes {
  return {
    type: DELETE_UNPUBLISHED_POST_START,
    payload: postId,
  };
}

export function deleteUnpublishedPostSuccess(
  postId: string,
): PostsActionsTypes {
  return {
    type: DELETE_UNPUBLISHED_POST_SUCCESS,
    payload: postId,
  };
}
