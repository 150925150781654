import {DoctorProfile} from '_redux/user/auth/user-auth.types';
import {AdminsActionTypes, ModelNameAndItemId} from '../admin.types';
import {
  FETCH_PRESCRIBERS_START,
  FETCH_PRESCRIBERS_SUCCESS,
  FETCH_PRESCRIBERS_FAILURE,
  DELETE_PRESCRIBER_START,
  DELETE_PRESCRIBER_SUCCESS,
  CREATE_PRESCRIBER_START,
  CREATE_PRESCRIBER_SUCCESS,
  FETCH_PRESCRIBER_START,
  FETCH_PRESCRIBER_SUCCESS,
  CLEAR_PRESCRIBER,
  EDIT_PRESCRIBER,
} from '../admin.constants';
import {
  NormalizedPrescribers,
  CreatePrescriberCredentials,
  EditPrescriberCredentials,
} from './admin-prescriber-model.types';

export function fetchPrescribersStart(): AdminsActionTypes {
  return {
    type: FETCH_PRESCRIBERS_START,
  };
}

export function fetchPrescribersSuccess(
  prescribers: NormalizedPrescribers,
): AdminsActionTypes {
  return {
    type: FETCH_PRESCRIBERS_SUCCESS,
    payload: prescribers,
  };
}

export function fetchPrescribersFailure(): AdminsActionTypes {
  return {
    type: FETCH_PRESCRIBERS_FAILURE,
  };
}

export function deletePrescriberStart(prescriberId: string): AdminsActionTypes {
  return {
    type: DELETE_PRESCRIBER_START,
    payload: prescriberId,
  };
}

export function deletePrescriberSuccess({
  id,
  filter = 'prescribers',
}: ModelNameAndItemId): AdminsActionTypes {
  return {
    type: DELETE_PRESCRIBER_SUCCESS,
    payload: {id, filter},
  };
}

export function createPrescriberStart(
  payload: CreatePrescriberCredentials,
): AdminsActionTypes {
  return {
    type: CREATE_PRESCRIBER_START,
    payload,
  };
}

export function createPrescriberSuccess(
  prescriber: DoctorProfile,
): AdminsActionTypes {
  return {
    type: CREATE_PRESCRIBER_SUCCESS,
    payload: prescriber,
  };
}

export function fetchPrescriberStart(id: string): AdminsActionTypes {
  return {
    type: FETCH_PRESCRIBER_START,
    payload: id,
  };
}

export function fetchPrescriberSuccess(
  prescriber: DoctorProfile,
): AdminsActionTypes {
  return {
    type: FETCH_PRESCRIBER_SUCCESS,
    payload: prescriber,
  };
}

export function editPrescriber(
  payload: EditPrescriberCredentials,
): AdminsActionTypes {
  return {
    type: EDIT_PRESCRIBER,
    payload,
  };
}

export function clearPrescriber(): AdminsActionTypes {
  return {
    type: CLEAR_PRESCRIBER,
    payload: null,
  };
}
