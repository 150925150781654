import {CacheService} from '_services/dexie/dexie-cache';
import React, {createContext} from 'react';
import {isIndexedDBAvailable} from '_services/dexie/dexie-utils';

let cacheDb: CacheService | null = null;

if (isIndexedDBAvailable) {
  cacheDb = new CacheService();
}

export const CacheServiceContext = createContext(cacheDb);

const CacheServiceProvider: React.FC = ({children}) => (
  <CacheServiceContext.Provider value={cacheDb}>
    {children}
  </CacheServiceContext.Provider>
);

export default CacheServiceProvider;
