import {MenuItem} from 'semantic-ui-react';
import styled from 'styled-components';
import {PRIMARY_COLOR} from '_helpers/style-guide';

const DesktopMenuHeader = styled(MenuItem)`
  &::before {
    width: 0px !important;
  }

  font-size: 2rem !important;
  color: ${PRIMARY_COLOR}!important;
  border-left: none !important;
  /* font-family: 'Montserrat', sans-serif !important; */
`;

export default DesktopMenuHeader;
