import {
  signInFirebaseUser,
  createFirebaseUser,
} from '_services/firebase/firebase.utils';
import {UserProfile} from '../auth/user-auth.types';

export async function signInOrCreateUser(
  user: UserProfile,
): Promise<firebase.User | null> {
  const firebaseUserOrErrorCode = await signInFirebaseUser(user);
  if (typeof firebaseUserOrErrorCode === 'string') {
    return firebaseUserOrErrorCode === 'auth/user-not-found'
      ? createFirebaseUser(user)
      : null;
  }
  return firebaseUserOrErrorCode;
}
