import {SagaIterator} from 'redux-saga';
import {
  takeLatest,
  takeLeading,
  takeEvery,
  all,
  call,
} from 'redux-saga/effects';
import {
  CHECK_USER_SESSION_SUCCESS,
  LOGIN_USER_SUCCESS,
} from '_redux/user/user.constants';
import {
  CREATE_POST_START,
  FETCH_POSTS_START,
  FETCH_CACHE_POSTS_START,
  DELETE_CACHE_POSTS,
  FETCH_API_POSTS_START,
  DELETE_POST_START,
  SHARE_POST_START,
  DELETE_UNPUBLISHED_POST_START,
} from './posts.constants';
import {
  // createPostSaga,
  setInitialPostsDataSaga,
  fetchPostsSaga,
  fetchCachedPostsSaga,
  deleteCachedPostsSaga,
  fetchApiPostsSaga,
  deletePostSaga,
  createPostSaga,
  sharePostSaga,
  deleteUnpublishedPostSaga,
} from './posts.sagas';

export function* onUserSessionStarted(): SagaIterator<void> {
  yield takeLatest(
    [CHECK_USER_SESSION_SUCCESS, LOGIN_USER_SUCCESS],
    setInitialPostsDataSaga,
  );
}

export function* onCreatePostStart(): SagaIterator<void> {
  yield takeLatest(CREATE_POST_START, createPostSaga);
}

export function* onSharePostStart(): SagaIterator<void> {
  yield takeLatest(SHARE_POST_START, sharePostSaga);
}

export function* onFetchPostsStart(): SagaIterator<void> {
  yield takeLeading(FETCH_POSTS_START, fetchPostsSaga);
}

export function* onFetchCachePostsStart(): SagaIterator<void> {
  yield takeLatest(FETCH_CACHE_POSTS_START, fetchCachedPostsSaga);
}

export function* onFetchApiPostsStart(): SagaIterator<void> {
  yield takeLatest(FETCH_API_POSTS_START, fetchApiPostsSaga);
}

export function* onDeleteCachePostsStart(): SagaIterator<void> {
  yield takeLatest(DELETE_CACHE_POSTS, deleteCachedPostsSaga);
}

export function* onDeletePostStart(): SagaIterator<void> {
  yield takeEvery(DELETE_POST_START, deletePostSaga);
}

export function* onDeleteUnpublishedPostStart(): SagaIterator<void> {
  yield takeEvery(DELETE_UNPUBLISHED_POST_START, deleteUnpublishedPostSaga);
}

export function* postsSagas(): SagaIterator<void> {
  yield all([
    // call(onUserSessionStarted),
    call(onCreatePostStart),
    call(onSharePostStart),
    call(onFetchPostsStart),
    call(onFetchCachePostsStart),
    call(onFetchApiPostsStart),
    call(onDeleteCachePostsStart),
    call(onDeletePostStart),
    call(onDeleteUnpublishedPostStart),
  ]);
}
