import {AdminProfile} from '_redux/user/auth/user-auth.types';
import {
  DELETE_ADMINISTRATOR_START,
  DELETE_ADMINISTRATOR_SUCCESS,
  FETCH_ADMINISTRATORS_START,
  FETCH_ADMINISTRATORS_SUCCESS,
  FETCH_ADMINISTRATORS_FAILURE,
  CREATE_ADMINISTRATOR,
  FETCH_ADMINISTRATOR_START,
  FETCH_ADMINISTRATOR_SUCCESS,
  EDIT_ADMINISTRATOR,
  CLEAR_ADMINISTRATOR,
} from '../admin.constants';
import {AdminsActionTypes, ModelNameAndItemId} from '../admin.types';
import {
  NormalizedAdmins,
  CreateAdministratorCredentials,
  EditAdministratorCredentials,
} from './admin-administrator-model.types';

export function fetchAdminsStart(): AdminsActionTypes {
  return {
    type: FETCH_ADMINISTRATORS_START,
  };
}

export function fetchAdminsSuccess(
  admins: NormalizedAdmins,
): AdminsActionTypes {
  return {
    type: FETCH_ADMINISTRATORS_SUCCESS,
    payload: admins,
  };
}

export function fetchAdminsFailure(): AdminsActionTypes {
  return {
    type: FETCH_ADMINISTRATORS_FAILURE,
  };
}

export function deleteAdminStart(adminId: string): AdminsActionTypes {
  return {
    type: DELETE_ADMINISTRATOR_START,
    payload: adminId,
  };
}

export function deleteAdminSuccess({
  id,
  filter = 'administrators',
}: ModelNameAndItemId): AdminsActionTypes {
  return {
    type: DELETE_ADMINISTRATOR_SUCCESS,
    payload: {id, filter},
  };
}

export function createAdministrator({
  name,
  email,
  password,
  gender,
  adminRoles,
  accessLevel,
  setSubmitting,
  closeModal,
}: CreateAdministratorCredentials): AdminsActionTypes {
  return {
    type: CREATE_ADMINISTRATOR,
    payload: {
      name,
      email,
      password,
      gender,
      adminRoles,
      accessLevel,
      setSubmitting,
      closeModal,
    },
  };
}

export function fetchAdminStart(id: string): AdminsActionTypes {
  return {
    type: FETCH_ADMINISTRATOR_START,
    payload: id,
  };
}

export function fetchAdminSuccess(
  administrator: AdminProfile,
): AdminsActionTypes {
  return {
    type: FETCH_ADMINISTRATOR_SUCCESS,
    payload: administrator,
  };
}

export function editAdmin({
  name,
  email,
  gender,
  adminRoles,
  accessLevel,
  adminId,
  setSubmitting,
}: EditAdministratorCredentials): AdminsActionTypes {
  return {
    type: EDIT_ADMINISTRATOR,
    payload: {
      name,
      email,
      gender,
      adminRoles,
      accessLevel,
      adminId,
      setSubmitting,
    },
  };
}

export function clearAdmin(): AdminsActionTypes {
  return {
    type: CLEAR_ADMINISTRATOR,
    payload: null,
  };
}
