import {MemberProfile} from '_redux/user/auth/user-auth.types';
import {
  FETCH_PATIENTS_START,
  FETCH_PATIENTS_SUCCESS,
  FETCH_PATIENTS_FAILURE,
  SEARCH_PATIENTS_START,
  SEARCH_PATIENTS_SUCCESS,
  SEARCH_PATIENTS_FAILURE,
  CLEAR_PATIENTS_SEARCH_RESULT,
} from '../patients.constants';
import {PatientsActionTypes} from '../patients.types';
import {
  FetchPatientsCredentials,
  PatientsResponse,
  SearchPatientsCredentials,
} from './patients-list.types';

export function fetchPatientsStart({
  numRequest,
  category,
  role,
}: FetchPatientsCredentials): PatientsActionTypes {
  return {
    type: FETCH_PATIENTS_START,
    payload: {numRequest, category, role},
  };
}

export function fetchPatientsSuccess({
  patients,
  numRequest,
  category,
  role,
}: PatientsResponse): PatientsActionTypes {
  return {
    type: FETCH_PATIENTS_SUCCESS,
    payload: {patients, numRequest, category, role},
  };
}

export function fetchPatientsFailure({
  patients,
  numRequest,
  category,
  role,
}: PatientsResponse): PatientsActionTypes {
  return {
    type: FETCH_PATIENTS_FAILURE,
    payload: {patients, numRequest, category, role},
  };
}

export function searchPatientsStart({
  searchTerm,
  userRole,
}: SearchPatientsCredentials): PatientsActionTypes {
  return {
    type: SEARCH_PATIENTS_START,
    payload: {
      searchTerm,
      userRole,
    },
  };
}

export function searchPatientsSuccess(
  patients: MemberProfile[],
): PatientsActionTypes {
  return {
    type: SEARCH_PATIENTS_SUCCESS,
    payload: patients,
  };
}

export function searchPatientsFailure(): PatientsActionTypes {
  return {
    type: SEARCH_PATIENTS_FAILURE,
  };
}

export function clearPatientsSearchResults(): PatientsActionTypes {
  return {
    type: CLEAR_PATIENTS_SEARCH_RESULT,
    payload: [],
  };
}
