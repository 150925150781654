import {TherapistProfile} from '_redux/user/auth/user-auth.types';
import {
  DELETE_THERAPIST_START,
  DELETE_THERAPIST_SUCCESS,
  FETCH_THERAPISTS_START,
  FETCH_THERAPISTS_SUCCESS,
  FETCH_THERAPISTS_FAILURE,
  CREATE_THERAPIST_START,
  EDIT_THERAPIST,
  CLEAR_THERAPIST,
  FETCH_THERAPIST_START,
  FETCH_THERAPIST_SUCCESS,
  CREATE_THERAPIST_SUCCESS,
  RATE_VIDEO_CALL,
  VERIFY_THERAPIST,
  UPDATE_PAYMENT_SERVICE_START,
  UPDATE_PAYMENT_SERVICE_SUCCESS,
  UPDATE_PAYMENT_SERVICE_FAILURE,
  FETCH_THERAPISTS_PAGINATED_START,
  FETCH_THERAPISTS_PAGINATED_SUCCESS,
  FETCH_THERAPISTS_PAGINATED_FAILURE,
  FETCH_PRESCRIBERS_PAGINATED_START,
  FETCH_PRESCRIBERS_PAGINATED_SUCCESS,
  FETCH_PRESCRIBERS_PAGINATED_FAILURE,
} from '../admin.constants';
import {
  AdminsActionTypes,
  ModelNameAndItemId,
  RateVideoCallActionType,
} from '../admin.types';
import {NormalizedPrescribers} from '../prescribers/admin-prescriber-model.types';
import {
  NormalizedTherapists,
  CreateTherapistCredentials,
  EditTherapistCredentials,
} from './admin-therapist-model.types';
import {
  FetchPaginatedPrescribersPayload,
  FetchPaginatedTherapistsPayload,
} from './admin-therapist-model-actions.types';

export function fetchTherapistsStart(countryCode?: string): AdminsActionTypes {
  return {
    type: FETCH_THERAPISTS_START,
    ...(countryCode ? {payload: countryCode} : null),
  };
}

export function fetchTherapistsSuccess(
  therapists: NormalizedTherapists,
): AdminsActionTypes {
  return {
    type: FETCH_THERAPISTS_SUCCESS,
    payload: therapists,
  };
}

export function fetchTherapistsFailure(): AdminsActionTypes {
  return {
    type: FETCH_THERAPISTS_FAILURE,
  };
}

export function fetchPaginatedPrescribersStart(
  payload: FetchPaginatedPrescribersPayload,
): AdminsActionTypes {
  return {
    type: FETCH_PRESCRIBERS_PAGINATED_START,
    payload,
  };
}

export function fetchPaginatedPrescribersSuccess(
  prescribers: NormalizedPrescribers,
): AdminsActionTypes {
  return {
    type: FETCH_PRESCRIBERS_PAGINATED_SUCCESS,
    payload: prescribers,
  };
}

export function fetchPaginatedPrescribersFailure(): AdminsActionTypes {
  return {
    type: FETCH_PRESCRIBERS_PAGINATED_FAILURE,
  };
}

export function fetchPaginatedTherapistsStart(
  payload: FetchPaginatedTherapistsPayload,
): AdminsActionTypes {
  return {
    type: FETCH_THERAPISTS_PAGINATED_START,
    payload,
  };
}

export function fetchPaginatedTherapistsSuccess(
  therapists: NormalizedTherapists,
): AdminsActionTypes {
  return {
    type: FETCH_THERAPISTS_PAGINATED_SUCCESS,
    payload: therapists,
  };
}

export function fetchPaginatedTherapistsFailure(): AdminsActionTypes {
  return {
    type: FETCH_THERAPISTS_PAGINATED_FAILURE,
  };
}

export function createTherapistStart(
  payload: CreateTherapistCredentials,
): AdminsActionTypes {
  return {
    type: CREATE_THERAPIST_START,
    payload,
  };
}

export function createTherapistSuccess(
  therapist: TherapistProfile,
): AdminsActionTypes {
  return {
    type: CREATE_THERAPIST_SUCCESS,
    payload: therapist,
  };
}

export function deleteTherapistStart(therapistId: string): AdminsActionTypes {
  return {
    type: DELETE_THERAPIST_START,
    payload: therapistId,
  };
}

export function deleteTherapistSuccess({
  id,
  filter = 'therapists',
}: ModelNameAndItemId): AdminsActionTypes {
  return {
    type: DELETE_THERAPIST_SUCCESS,
    payload: {id, filter},
  };
}

export function fetchTherapistStart(id: string): AdminsActionTypes {
  return {
    type: FETCH_THERAPIST_START,
    payload: id,
  };
}

export function fetchTherapistSuccess(
  therapist: TherapistProfile,
): AdminsActionTypes {
  return {
    type: FETCH_THERAPIST_SUCCESS,
    payload: therapist,
  };
}

export function updatePaymentServiceStart(payload: {
  email: string;
  method: string;
}): AdminsActionTypes {
  return {
    type: UPDATE_PAYMENT_SERVICE_START,
    payload,
  };
}

export function updatePaymentServiceSuccess(): AdminsActionTypes {
  return {
    type: UPDATE_PAYMENT_SERVICE_SUCCESS,
  };
}

export function updatePaymentServiceFailure(payload: {
  error: string;
}): AdminsActionTypes {
  return {
    type: UPDATE_PAYMENT_SERVICE_FAILURE,
    payload,
  };
}

export function editTherapist(
  payload: EditTherapistCredentials,
): AdminsActionTypes {
  return {
    type: EDIT_THERAPIST,
    payload,
  };
}

export function verifyTherapist(therapistId: string): AdminsActionTypes {
  return {
    type: VERIFY_THERAPIST,
    payload: therapistId,
  };
}

export function clearTherapist(): AdminsActionTypes {
  return {
    type: CLEAR_THERAPIST,
    payload: null,
  };
}

export function rateVideoCall(roomId: string): RateVideoCallActionType {
  return {
    type: RATE_VIDEO_CALL,
    payload: roomId,
  };
}
