import styled from 'styled-components';
import {PRIMARY_COLOR, PRIMARY_LIGHT_GREY_COLOR} from '_helpers/style-guide';
import {Container} from 'semantic-ui-react';

const StyledFooter = styled(Container)`
  flex-shrink: 0;
  padding: 0 3rem 1rem;
  background-color: ${PRIMARY_COLOR};
  color: ${PRIMARY_LIGHT_GREY_COLOR};

  p {
    padding-left: 1rem;
    padding-top: 1rem;
  }

  .icon {
    padding: 1.2rem;
  }

  @media only screen and (max-width: 767px) {
    margin-left: 0 !important;
    margin-right: 0 !important;
    text-align: center;
    padding: 1rem;
  }
`;

export default StyledFooter;
