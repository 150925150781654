import {
  DELETE_MEDICATION_START,
  DELETE_MEDICATION_SUCCESS,
  FETCH_MEDICATIONS_START,
  FETCH_MEDICATIONS_SUCCESS,
  FETCH_MEDICATIONS_FAILURE,
  CREATE_MEDICATION,
  FETCH_MEDICATION_START,
  FETCH_MEDICATION_SUCCESS,
  EDIT_MEDICATION,
  CLEAR_MEDICATION,
} from '../admin.constants';
import {AdminsActionTypes, ModelNameAndItemId} from '../admin.types';
import {
  NormalizedMedications,
  CreateMedicationCredentials,
  Medication,
  EditMedicationCredentials,
} from './admin-medication-model.types';

export function fetchMedicationsStart(): AdminsActionTypes {
  return {
    type: FETCH_MEDICATIONS_START,
  };
}

export function fetchMedicationsSuccess(
  medications: NormalizedMedications,
): AdminsActionTypes {
  return {
    type: FETCH_MEDICATIONS_SUCCESS,
    payload: medications,
  };
}

export function fetchMedicationsFailure(): AdminsActionTypes {
  return {
    type: FETCH_MEDICATIONS_FAILURE,
  };
}

export function createMedication({
  medicationName,
  setSubmitting,
  closeModal,
}: CreateMedicationCredentials): AdminsActionTypes {
  return {
    type: CREATE_MEDICATION,
    payload: {medicationName, setSubmitting, closeModal},
  };
}

export function deleteMedicationStart(medicationId: string): AdminsActionTypes {
  return {
    type: DELETE_MEDICATION_START,
    payload: medicationId,
  };
}

export function deleteMedicationSuccess({
  id,
  filter = 'medications',
}: ModelNameAndItemId): AdminsActionTypes {
  return {
    type: DELETE_MEDICATION_SUCCESS,
    payload: {id, filter},
  };
}

export function fetchMedicationStart(id: string): AdminsActionTypes {
  return {
    type: FETCH_MEDICATION_START,
    payload: id,
  };
}

export function fetchMedicationSuccess(
  medication: Medication,
): AdminsActionTypes {
  return {
    type: FETCH_MEDICATION_SUCCESS,
    payload: medication,
  };
}

export function editMedication({
  medicationName,
  medicationId,
  setSubmitting,
}: EditMedicationCredentials): AdminsActionTypes {
  return {
    type: EDIT_MEDICATION,
    payload: {
      medicationName,
      medicationId,
      setSubmitting,
    },
  };
}

export function clearMedication(): AdminsActionTypes {
  return {
    type: CLEAR_MEDICATION,
    payload: null,
  };
}
