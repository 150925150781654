import React, {lazy} from 'react';
import {Route, Switch} from 'react-router-dom';
import NoMatch from '_components/common/no-match.component';
import PrivateRoute from '_components/hoc/private-route.component';
import PublicRoute from '_components/hoc/public-route.component';
import {UserRoles} from '_redux/user/auth/user-auth.types';
import IndexPage from './_pages/index/index.component';
import LoginPage from './_pages/login/login.component';

const ReferralPage = lazy(() =>
  import('_pages/referral/referral.component').catch(() =>
    import('_pages/referral/referral.component'),
  ),
);
const TogetherReferralPage = lazy(() =>
  import('_pages/referral/togetherReferral.component').catch(() =>
    import('_pages/referral/togetherReferral.component'),
  ),
);
const ScheduleAppointmentPage = lazy(() =>
  import('_pages/appointment/schedule-appointment.component').catch(() =>
    import('_pages/appointment/schedule-appointment.component'),
  ),
);

const PaymentPage = lazy(() =>
  import('./_pages/payment/payment.component').catch(() =>
    import('./_pages/payment/payment.component'),
  ),
);
const ThankYouPage = lazy(() =>
  import('./_pages/thank-you/thank-you.component').catch(() =>
    import('./_pages/thank-you/thank-you.component'),
  ),
);
const ForgotPage = lazy(() =>
  import('./_pages/forgot/forgot.component').catch(() =>
    import('./_pages/forgot/forgot.component'),
  ),
);
const ValidateResetTokenPage = lazy(() =>
  import(
    './_pages/validate-reset-token/validate-reset-token.component'
  ).catch(() =>
    import('./_pages/validate-reset-token/validate-reset-token.component'),
  ),
);

const AccountPage = lazy(() =>
  import('./_pages/account/account.component').catch(() =>
    import('./_pages/account/account.component'),
  ),
);

const AdminPage = lazy(() =>
  import('./_pages/admin/admin.component').catch(() =>
    import('./_pages/admin/admin.component'),
  ),
);
const PrescriberPage = lazy(() =>
  import('./_pages/prescriber/prescriber.component').catch(() =>
    import('./_pages/prescriber/prescriber.component'),
  ),
);
const TherapistPage = lazy(() =>
  import('./_pages/therapist/therapist.component').catch(() =>
    import('./_pages/therapist/therapist.component'),
  ),
);
const SharePage = lazy(() =>
  import('./_pages/share/share.component').catch(() =>
    import('./_pages/share/share.component'),
  ),
);
const CoachPage = lazy(() =>
  import('./_pages/coach/coach.component').catch(() =>
    import('./_pages/coach/coach.component'),
  ),
);
const NotificationsPage = lazy(() =>
  import('./_pages/notifications/notifications.component').catch(() =>
    import('./_pages/notifications/notifications.component'),
  ),
);

const AllRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/" component={IndexPage} />
      <PublicRoute exact path="/referral" component={ReferralPage} />
      <PublicRoute
        exact
        path="/together/referral"
        component={TogetherReferralPage}
      />
      <PublicRoute exact path="/login" component={LoginPage} />
      <PrivateRoute
        exact
        path="/(basic|mindfulness)/freetrial/register/stripe"
        authorizedRoles={[UserRoles.member]}
        component={PaymentPage}
      />
      <PrivateRoute
        exact
        path="/(basic|mindfulness)/freetrial/thanks"
        authorizedRoles={[UserRoles.member]}
        component={ThankYouPage}
      />
      <PrivateRoute
        exact
        path="/(together|insured|uninsured|mindfulness)/register/stripe"
        authorizedRoles={[UserRoles.member]}
        component={PaymentPage}
      />
      <PrivateRoute
        exact
        path="/(together|insured|uninsured|mindfulness)/thanks"
        authorizedRoles={[UserRoles.member]}
        component={ThankYouPage}
      />
      <PublicRoute path="/forgot" component={ForgotPage} />
      <PublicRoute
        exact
        path="/reset/:token"
        component={ValidateResetTokenPage}
      />
      {/* <PrivateRoute
        exact
        path={['/chat', '/coach/chat']}
        authorizedRoles={[UserRoles.member, UserRoles.mentalhealthcoach]}
        component={ChatPage}
      /> */}
      <PrivateRoute
        exact
        path="/share"
        authorizedRoles={[UserRoles.member]}
        component={SharePage}
      />
      <PrivateRoute
        exact
        path={[
          '/account',
          '/admin/account',
          '/prescriber/account',
          '/coach/account',
          '/therapist/account',
        ]}
        authorizedRoles={[
          UserRoles.member,
          UserRoles.admin,
          UserRoles.prescriber,
          UserRoles.mentalhealthcoach,
          UserRoles.therapist,
        ]}
        component={AccountPage}
      />

      <PrivateRoute
        path="/notifications"
        authorizedRoles={[UserRoles.mentalhealthcoach]}
        component={NotificationsPage}
      />

      <PrivateRoute
        path="/admin"
        authorizedRoles={[UserRoles.admin]}
        component={AdminPage}
      />
      <PrivateRoute
        path="/prescriber"
        authorizedRoles={[UserRoles.prescriber]}
        component={PrescriberPage}
      />
      <PrivateRoute
        path="/therapist"
        authorizedRoles={[UserRoles.therapist]}
        component={TherapistPage}
      />
      <PrivateRoute
        exact
        path="/schedule-appointment"
        authorizedRoles={[UserRoles.member]}
        component={ScheduleAppointmentPage}
      />
      <PrivateRoute
        exact
        path="/coach/appointments"
        authorizedRoles={[UserRoles.mentalhealthcoach]}
        component={CoachPage}
      />
      <Route component={NoMatch} />
    </Switch>
  );
};

export default AllRoutes;
