import {put, call} from 'redux-saga/effects';
import {normalize} from 'normalizr';
import * as schema from '_redux/schema';
import log from 'loglevel';
import requestHandler from '_services/api/axios-config';
import {SagaIterator} from 'redux-saga';
import {toast} from 'react-toastify';
import history from '_helpers/history';
import {HttpMethods} from '_services/api/axios.types';
import {
  CreateMedicationAction,
  FetchMedicationStartAction,
  EditMedicationAction,
  DeleteMedicationStartAction,
} from './admin-medication-model-actions.types';
import {
  deleteMedicationSuccess,
  fetchMedicationsSuccess,
  fetchMedicationsFailure,
  fetchMedicationsStart,
  fetchMedicationSuccess,
} from './admin-medication-model.actions';

export function* fetchMedicationsSaga(): SagaIterator<void> {
  try {
    const {
      data: {message: medications},
    } = yield call(requestHandler, {
      method: HttpMethods.GET,
      url: '/api/admin/medication/view-all-medication',
    });
    const normalizedMedications = yield call(
      normalize,
      medications,
      schema.arrayOfMedications,
    );
    yield put(
      fetchMedicationsSuccess({
        ...normalizedMedications,
        filter: 'medications',
      }),
    );
  } catch (error) {
    yield put(fetchMedicationsFailure());
  }
}

export function* deleteMedicationSaga({
  payload: medicationId,
}: DeleteMedicationStartAction): SagaIterator<void> {
  try {
    yield call(requestHandler, {
      method: HttpMethods.DELETE,
      url: `/api/admin/medication/delete-medication/${medicationId}`,
    });
    yield put(
      deleteMedicationSuccess({id: medicationId, filter: 'medications'}),
    );
    toast.success('Medication deleted');
  } catch (error) {
    log.warn(error);
  }
}

export function* createMedicationSaga({
  payload: {medicationName, setSubmitting, closeModal},
}: CreateMedicationAction): SagaIterator<void> {
  try {
    yield call(requestHandler, {
      method: HttpMethods.POST,
      url: '/api/admin/medication/add-medication',
      data: {medicationName},
    });
    yield put(fetchMedicationsStart());
    yield call(closeModal);
    toast.success('Medication added');
  } catch (error) {
    yield call(setSubmitting, false);
  }
}

export function* fetchMedicationSaga({
  payload: id,
}: FetchMedicationStartAction): SagaIterator<void> {
  try {
    const {
      data: {message: medication},
    } = yield call(requestHandler, {
      method: HttpMethods.GET,
      url: `/api/admin/medication/view-single-medication/${id}`,
    });
    yield put(fetchMedicationSuccess(medication));
  } catch (error) {
    log.warn(error);
  }
}

export function* editMedicationSaga({
  payload: {medicationName, medicationId, setSubmitting},
}: EditMedicationAction): SagaIterator<void> {
  try {
    yield call(requestHandler, {
      method: HttpMethods.POST,
      url: '/api/admin/medication/edit-medication',
      data: {
        medicationName,
        medicationId,
      },
    });
    history.push('/admin/medications');
    toast.success('Medication edited');
  } catch (error) {
    log.warn(error);
    yield call(setSubmitting, false);
  }
}
