import {
  DELETE_NOTIFICATION_START,
  DELETE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_STATE_START,
  UPDATE_NOTIFICATION_STATE_SUCCESS,
  GET_NOTIFICATIONS_START,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  ADD_NEW_NOTIFICATION,
  UPDATE_NOTIFICATION_START,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_STATUS_SUCCESS,
  UPDATE_SHARE_RESPONSE_NOTIFICATION_START,
  UPDATE_SHARE_RESPONSE_NOTIFICATION_SUCCESS,
} from './notifications.constants';
import {
  NormalizedNotifications,
  NotificationsActionTypes,
  TNotification,
} from './notifications.types';
import {
  UpdateNotificationStartAction,
  UpdateNotificationSuccessAction,
  UpdateNotificationStatusSuccessAction,
  UpdateShareResponseNotificationSuccessAction,
} from './notifications-actions.types';

export function getNotificationsStart(payload: {
  page: number;
  limit: number;
}): NotificationsActionTypes {
  return {
    type: GET_NOTIFICATIONS_START,
    payload,
  };
}

export function getNotificationsSuccess(
  notifications: NormalizedNotifications,
): NotificationsActionTypes {
  return {
    type: GET_NOTIFICATIONS_SUCCESS,
    payload: notifications,
  };
}

export function getNotificationsFailure(): NotificationsActionTypes {
  return {
    type: GET_NOTIFICATIONS_FAILURE,
  };
}

export function addNewNotification(
  notification: TNotification,
): NotificationsActionTypes {
  return {
    type: ADD_NEW_NOTIFICATION,
    payload: notification,
  };
}

export function updateNotificationStateStart(payload: {
  notificationId: string;
  entityId: string;
}): NotificationsActionTypes {
  return {
    type: UPDATE_NOTIFICATION_STATE_START,
    payload,
  };
}

export function updateNotificationStateSuccess(changeData: {
  id: string;
  changes: {state: 'unread' | 'read'};
}): NotificationsActionTypes {
  return {
    type: UPDATE_NOTIFICATION_STATE_SUCCESS,
    payload: changeData,
  };
}

export function updateShareResponseNotificationStart(changeData: {
  notificationId: string;
  changes: {caption?: string; status?: string};
}): NotificationsActionTypes {
  return {
    type: UPDATE_SHARE_RESPONSE_NOTIFICATION_START,
    payload: changeData,
  };
}

export function deleteNotificationStart(payload: {
  notificationId: string;
  entityId: string;
}): NotificationsActionTypes {
  return {
    type: DELETE_NOTIFICATION_START,
    payload,
  };
}

export function deleteNotificationSuccess(
  entityId: string,
): NotificationsActionTypes {
  return {
    type: DELETE_NOTIFICATION_SUCCESS,
    payload: entityId,
  };
}

export function updateNotificationStart(payload: {
  notificationId: string;
  entityId: string;
  status: string;
  comment?: string;
  setSubmitting: (isSubmitting: boolean) => void;
}): UpdateNotificationStartAction {
  return {
    type: UPDATE_NOTIFICATION_START,
    payload,
  };
}

export function updateNotificationSuccess(): UpdateNotificationSuccessAction {
  return {
    type: UPDATE_NOTIFICATION_SUCCESS,
  };
}

export function updateNotificationStatusSuccess(payload: {
  id: string;
  changes: Record<string, string>;
}): UpdateNotificationStatusSuccessAction {
  return {
    type: UPDATE_NOTIFICATION_STATUS_SUCCESS,
    payload,
  };
}

export function updateShareResponseNotificationSuccess(payload: {
  id: string;
  changes: Record<string, string>;
}): UpdateShareResponseNotificationSuccessAction {
  return {
    type: UPDATE_SHARE_RESPONSE_NOTIFICATION_SUCCESS,
    payload,
  };
}
