export const adminRoles = [
  {
    label: 'Super Administrator',
    value: 'super_admin',
  },
  {
    label: 'Community Administrator',
    value: 'community_admin',
  },
  {
    label: 'Prescriber Administrator',
    value: 'prescriber_admin',
  },
  {
    label: 'Admin Administrator',
    value: 'admin_admin',
  },
  {
    label: 'Therapist Administrator',
    value: 'therapist_admin',
  },
  {
    label: 'Patient Administrator',
    value: 'patient_admin',
  },
  {
    label: 'New User Administrator',
    value: 'new_user_admin',
  },
];

export const accessLevels = [
  {
    label: 'Download Patient Data',
    value: 'patient_download',
    disabledRegex: /^super_admin$/,
  },
  {
    label: 'View Patient',
    value: 'patient_view',
    disabledRegex: /^(super_admin|patient_admin)$/,
  },
  {
    label: 'Delete Patient',
    value: 'patient_delete',
    disabledRegex: /^(super_admin|patient_admin)$/,
  },
  {
    label: "Patient's Prescriber",
    value: 'patient_prescriber',
    disabledRegex: /^(super_admin|patient_admin)$/,
  },
  {
    label: "Patient's Therapist",
    value: 'patient_therapist',
    disabledRegex: /^(super_admin|patient_admin)$/,
  },
  {
    label: 'Update Patient',
    value: 'patient_update',
    disabledRegex: /^(super_admin|patient_admin)$/,
  },
  {
    label: 'Create Patient Note',
    value: 'patient_create_note',
    disabledRegex: /^(super_admin|patient_admin)$/,
  },
  {
    label: 'View Patient Note',
    value: 'patient_view_note',
    disabledRegex: /^(super_admin|patient_admin)$/,
  },
  {
    label: 'Update Patient Note',
    value: 'patient_update_note',
    disabledRegex: /^(super_admin|patient_admin)$/,
  },
  {
    label: 'Delete Post',
    value: 'community_post_delete',
    disabledRegex: /^(super_admin|community_admin)$/,
  },
  {
    label: 'Delete Post Comment',
    value: 'community_reply_delete',
    disabledRegex: /^(super_admin|community_admin)$/,
  },
  {
    label: 'Create Prescriber',
    value: 'prescriber_create',
    disabledRegex: /^(super_admin|prescriber_admin)$/,
  },
  {
    label: 'View Prescriber',
    value: 'prescriber_view',
    disabledRegex: /^(super_admin|prescriber_admin)$/,
  },
  {
    label: 'Delete Prescriber',
    value: 'prescriber_delete',
    disabledRegex: /^(super_admin|prescriber_admin)$/,
  },
  {
    label: 'Create Therapist',
    value: 'therapist_create',
    disabledRegex: /^(super_admin|therapist_admin)$/,
  },
  {
    label: 'View Therapist',
    value: 'therapist_view',
    disabledRegex: /^(super_admin|therapist_admin)$/,
  },
  {
    label: 'Delete Therapist',
    value: 'therapist_delete',
    disabledRegex: /^(super_admin|therapist_admin)$/,
  },
  {
    label: 'Create Administrator',
    value: 'admin_create',
    disabledRegex: /^(super_admin|admin_admin)$/,
  },
  {
    label: 'View Administrator',
    value: 'admin_view',
    disabledRegex: /^(super_admin|admin_admin)$/,
  },
  {
    label: 'Delete Administrator',
    value: 'admin_delete',
    disabledRegex: /^(super_admin|admin_admin)$/,
  },
  {
    label: 'New User Notification',
    value: 'new_user_notification',
    disabledRegex: /^(super_admin|new_user_admin)$/,
  },
];

export const adminRolePermissions = {
  super_admin: [
    'patient_view',
    'patient_delete',
    'patient_download',
    'patient_prescriber',
    'patient_update',
    'patient_therapist',
    'patient_create_note',
    'patient_view_note',
    'patient_update_note',
    'community_post_delete',
    'community_reply_delete',
    'prescriber_create',
    'prescriber_view',
    'prescriber_delete',
    'admin_create',
    'admin_view',
    'admin_delete',
    'new_user_notification',
    'therapist_create',
    'therapist_view',
    'therapist_delete',
  ],
  community_admin: ['community_post_delete', 'community_reply_delete'],
  patient_admin: [
    'patient_view',
    'patient_delete',
    'patient_prescriber',
    'patient_therapist',
    'patient_update',
    'patient_create_note',
    'patient_view_note',
    'patient_update_note',
  ],
  prescriber_admin: [
    'prescriber_create',
    'prescriber_view',
    'prescriber_delete',
  ],
  therapist_admin: [
    'therapist_create',
    'therapist_view',
    'therapist_delete',
    'patient_therapist',
  ],
  admin_admin: ['admin_create', 'admin_view', 'admin_delete'],
  new_user_admin: ['new_user_notification'],
};

export const adminInternationalTherapistQuestions = {
  mentalHealthRole: 'Mental Health Role',
  credentialType: 'Credential Type',
  organization: 'Organization',
  membershipType: 'Membership Type',
  membershipAbbreviation: 'Membership Abbreviation',
  nameOrId: 'Name or ID number in Registry',
  memberSince: 'Member Since',
  licenseIssuer: 'License Issuer',
  licenseType: 'License Type',
  licenseAbbreviation: 'License Abbreviation',
  licenseNumber: 'License Number',
  licenseIssueDate: 'License Issue Date',
  accreditingInstitution: 'Accrediting Institution',
  certificationName: 'Certification Name',
  certificationAbbreviation: 'Certification Abbreviation',
  certificateNumber: 'Certificate Number',
  year: 'Year',
  departmentOrSubject: 'Department/Subject',
  abbreviation: 'Abbreviation',
  diplomaName: 'Diploma Name',
  degreeName: 'Degree Name',
};

export const countries = [
  {
    name: 'Australia',
    isoCode: 'AU',
    phoneCode: '61',
    currency: 'AUD',
    currencySymbol: '$',
    latitude: '-27.00000000',
    longitude: '133.00000000',
    language: 'English',
    languageCode: 'en',
    locale: 'en-au',
  },
  // {
  //   name: 'Brazil',
  //   isoCode: 'BR',
  //   phoneCode: '55',
  //   currency: 'BRL',
  //   currencySymbol: 'R$',
  //   latitude: '-10.00000000',
  //   longitude: '-55.00000000',
  //   language: 'Portuguese',
  //   languageCode: 'pt',
  //   locale: 'pt-br',
  // },
  {
    name: 'Canada',
    isoCode: 'CA',
    phoneCode: '1',
    currency: 'CAD',
    currencySymbol: '$',
    latitude: '60.00000000',
    longitude: '-95.00000000',
    language: 'English',
    languageCode: 'en',
    locale: 'en-ca',
  },
  {
    name: 'Chile',
    isoCode: 'CL',
    phoneCode: '56',
    currency: 'CLP',
    currencySymbol: '$',
    latitude: '-30.00000000',
    longitude: '-71.00000000',
    language: 'Spanish',
    languageCode: 'es',
    locale: 'es-cl',
  },
  {
    name: 'Colombia',
    isoCode: 'CO',
    phoneCode: '57',
    currency: 'COP',
    currencySymbol: '$',
    latitude: '4.00000000',
    longitude: '-72.00000000',
    language: 'Spanish',
    languageCode: 'es',
    locale: 'es-co',
  },
  {
    name: 'Mexico',
    isoCode: 'MX',
    phoneCode: '52',
    currency: 'MXN',
    currencySymbol: '$',
    latitude: '23.00000000',
    longitude: '-102.00000000',
    language: 'Spanish',
    languageCode: 'es',
    locale: 'es-mx',
  },
  {
    name: 'New Zealand',
    isoCode: 'NZ',
    phoneCode: '64',
    currency: 'NZD',
    currencySymbol: '$',
    latitude: '-41.00000000',
    longitude: '174.00000000',
    language: 'English',
    languageCode: 'en',
    locale: 'en-nz',
  },
  {
    name: 'South Africa',
    isoCode: 'ZA',
    phoneCode: '27',
    currency: 'ZAR',
    currencySymbol: 'R',
    latitude: '-29.00000000',
    longitude: '24.00000000',
    language: 'English',
    languageCode: 'en',
    locale: 'en-za',
  },
  {
    name: 'Spain',
    isoCode: 'ES',
    phoneCode: '34',
    currency: 'EUR',
    currencySymbol: '€',
    latitude: '40.00000000',
    longitude: '-4.00000000',
    language: 'Spanish',
    languageCode: 'es',
    locale: 'es-es',
  },
  {
    name: 'United States',
    isoCode: 'US',
    phoneCode: '1',
    currency: 'USD',
    currencySymbol: '$',
    latitude: '37.00000000',
    longitude: '-95.00000000',
    language: 'English',
    languageCode: 'en',
    locale: 'en-us',
  },
];

export const countryCodeOptions = countries.map(country => ({
  key: country.isoCode,
  text: country.name,
  value: country.isoCode,
}));
