import React from 'react';
import {Route, Redirect, RouteComponentProps} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {selectUserProfile} from '_redux/user/user.selectors';

const PublicRoute: React.FC<{
  component: React.FC<RouteComponentProps>;
  path: string;
  exact?: boolean;
}> = ({component: Component, ...rest}) => {
  const user = useSelector(selectUserProfile);
  const renderPublicComponent: React.FC<RouteComponentProps> = props => {
    return user ? <Redirect to="/" /> : <Component {...props} />;
  };
  return <Route {...rest} render={renderPublicComponent} />;
};

export default PublicRoute;
