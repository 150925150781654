export const FETCH_PATIENT_APPOINTMENTS_START =
  'FETCH_PATIENT_APPOINTMENTS_START';
export const FETCH_PATIENT_APPOINTMENTS_SUCCESS =
  'FETCH_PATIENT_APPOINTMENTS_SUCCESS';
export const FETCH_PATIENT_APPOINTMENTS_FAILURE =
  'FETCH_PATIENT_APPOINTMENTS_FAILURE';

export const FETCH_AVAILABILITY_INFO_START = 'FETCH_AVAILABILITY_INFO_START';
export const FETCH_AVAILABILITY_INFO_SUCCESS =
  'FETCH_AVAILABILITY_INFO_SUCCESS';
export const FETCH_AVAILABILITY_INFO_FAILURE =
  'FETCH_AVAILABILITY_INFO_FAILURE';

export const FETCH_PROVIDER_APPOINTMENTS_START =
  'FETCH_PROVIDER_APPOINTMENTS_START';
export const FETCH_PROVIDER_APPOINTMENTS_SUCCESS =
  'FETCH_PROVIDER_APPOINTMENTS_SUCCESS';
export const FETCH_PROVIDER_APPOINTMENTS_FAILURE =
  'FETCH_PROVIDER_APPOINTMENTS_FAILURE';

export const BOOK_APPOINTMENT = 'BOOK_APPOINTMENT';
export const CANCEL_PATIENT_APPOINTMENT_START =
  'CANCEL_PATIENT_APPOINTMENT_START';
export const CANCEL_PATIENT_APPOINTMENT_SUCCESS =
  'CANCEL_PATIENT_APPOINTMENT_SUCCESS';
