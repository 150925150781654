import React from 'react';
import {Responsive, Segment, Container} from 'semantic-ui-react';
import HeaderMenuItems from './menu-items.component';
import {ContainerProps} from './base-layout.types';
import DesktopMenuHeader from './desktop-menu-header.styles';
import DesktopMenu from './desktop-header-menu.styles';

const DesktopContainer: React.FC<ContainerProps> = ({children, userRole}) => {
  return (
    <Responsive
      getWidth={(): number => window.innerWidth}
      minWidth={Responsive.onlyTablet.minWidth}
      style={{flex: 1, display: 'flex'}}
    >
      <Segment inverted textAlign="center" vertical style={{padding: 0}}>
        <DesktopMenu fixed="top" icon="labeled" size="small">
          <Container fluid style={{alignItems: 'center'}}>
            <DesktopMenuHeader
              header
              name="Brand Name"
              position="left"
              content="Wellnite Health"
            />
            <HeaderMenuItems userRole={userRole} />
          </Container>
        </DesktopMenu>
      </Segment>
      {children}
    </Responsive>
  );
};

export default DesktopContainer;
