import {
  GET_USER_CHAT_PROFILE_SUCCESS,
  GET_USER_CHAT_PROFILE_FAILURE,
  SET_CURRENT_CHAT_CHANNEL,
  SEND_CHAT_PUSH_NOTIFICATION,
} from '../user.constants';
import {UserActionTypes} from '../user.types';

export function getUserChatProfileSuccess(
  user: firebase.User,
): UserActionTypes {
  return {
    type: GET_USER_CHAT_PROFILE_SUCCESS,
    payload: user,
  };
}

export function getUserChatProfileFailure(user: null): UserActionTypes {
  return {
    type: GET_USER_CHAT_PROFILE_FAILURE,
    payload: user,
  };
}

export function setCurrentChatChannel(channel: {
  id: string;
  name: string;
  patientId: string;
}): UserActionTypes {
  return {
    type: SET_CURRENT_CHAT_CHANNEL,
    payload: channel,
  };
}

export function sendChatPushNotification(data: {
  content: string;
  userId: string;
}): UserActionTypes {
  return {
    type: SEND_CHAT_PUSH_NOTIFICATION,
    payload: data,
  };
}
