import React from 'react';
import {HeaderContent, HeaderSubheader} from 'semantic-ui-react';
import {Formik, FormikHelpers} from 'formik';
import {loginUserStart} from '_redux/user/auth/user-auth.actions';
import StyledHeader from '_components/common/styles/styled-form-header.styles';
import {useDispatch} from 'react-redux';
import LoginForm from './login-form.component';
import {LoginFormProps, LoginSchema} from './login.types';

const initialValues: LoginFormProps = {
  email: '',
  password: '',
  keepSignedIn: true,
};

const Login: React.FC<{redirectTo: string}> = ({redirectTo}) => {
  const dispatch = useDispatch();
  const handleSubmit = (
    values: LoginFormProps,
    {setSubmitting}: FormikHelpers<LoginFormProps>,
  ): void => {
    dispatch(loginUserStart({...values, redirectTo, setSubmitting}));
  };
  return (
    <>
      <StyledHeader textAlign="center">
        <HeaderContent>Welcome to Wellnite</HeaderContent>
        <HeaderSubheader>Welcome back, please sign in</HeaderSubheader>
      </StyledHeader>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={LoginSchema}
        component={LoginForm}
      />
    </>
  );
};

export default Login;
