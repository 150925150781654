import {listTimeZones} from 'timezone-support';
import {
  getDropdownValues,
  replaceAllCharacters,
  roundDownToNearestFive,
} from './datetime.utils';
import {Zone} from './datetime.types';

const isUnique = (value: string, index: number, self: string[]): boolean =>
  self.indexOf(value) === index;

export const getRegions = (zones: Zone[]): string[] => {
  const regions = zones
    .map(zone => zone.region)
    .filter(isUnique)
    .sort();

  return regions;
};

export const getZonesObject = (button: {
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
}): Zone[] => {
  const {year, month, day, hour, minute} = button;
  const roundedMinute = roundDownToNearestFive(minute);

  const zones = listTimeZones()
    .filter(region => {
      let invalid = false;
      const name = region.toLowerCase();
      const america = [
        'america/adak', // unknown
        'pacific/honolulu',
        'america/anchorage',
        'america/juneau',
        'america/metlakatla',
        'america/nome',
        'america/sitka',
        'america/yakutat',
        'america/los_angeles',
        'america/boise',
        'america/denver',
        'america/phoenix',
        'america/chicago',
        'america/indiana/knox',
        'america/indiana/tell_city',
        'america/menominee',
        'america/north_dakota/beulah',
        'america/north_dakota/center',
        'america/north_dakota/new_salem',
        'america/detroit',
        'america/indiana/indianapolis',
        'america/indiana/marengo',
        'america/indiana/petersburg',
        'america/indiana/vevay',
        'america/indiana/vincennes',
        'america/indiana/winamac',
        'america/kentucky/louisville',
        'america/kentucky/monticello',
        'america/new_york',
      ];

      if (america.indexOf(name) === -1) {
        invalid = true;
      }

      return !invalid;
    })
    .map(identifier => {
      const array = identifier.split('/');
      const region = array[0];
      const zone = replaceAllCharacters(array[array.length - 1], '_', ' ');
      const dropdownValues = getDropdownValues({
        year,
        month,
        day,
        hour,
        minute: roundedMinute,
        region,
        zone,
        identifier,
      });
      /*
          id needs to be in this format:
          https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
        */
      return {
        region,
        zone,
        id: identifier,
        sort: Number(dropdownValues.offset.slice(0, 3)),
        item: dropdownValues,
      };
    })
    .sort((a, b) => {
      let sort = 0;

      if (a.sort < b.sort) {
        sort = -1;
      }

      if (a.sort > b.sort) {
        sort = 1;
      }

      return sort;
    });

  return zones;
};
