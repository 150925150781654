import {SagaIterator} from 'redux-saga';
import log from 'loglevel';
import {call, put} from 'redux-saga/effects';
import requestHandler from '_services/api/axios-config';
import {HttpMethods} from '_services/api/axios.types';
import {normalize} from 'normalizr';
import * as schema from '_redux/schema';
import {toast} from 'react-toastify';
import {
  DeleteNotificationStartAction,
  GetNotificationsStartAction,
  UpdateNotificationStateStartAction,
  UpdateNotificationStartAction,
} from './notifications-actions.types';
import {
  deleteNotificationSuccess,
  getNotificationsSuccess,
  updateNotificationStateSuccess,
  updateNotificationStatusSuccess,
} from './notifications.actions';

export function* getNotificationsSaga({
  payload: {page, limit},
}: GetNotificationsStartAction): SagaIterator<void> {
  try {
    const {
      data: {
        message: {notifications, unreadCount},
      },
    } = yield call(requestHandler, {
      method: HttpMethods.GET,
      url: `/api/notifications/?page=${page}&limit=${limit}`,
    });
    const normalizedNotifications = yield call(
      normalize,
      notifications,
      schema.arrayOfNotifications,
    );
    yield put(
      getNotificationsSuccess({
        ...normalizedNotifications,
        unreadCount,
      }),
    );
  } catch (error) {
    log.warn(error);
  }
}

export function* updateNotificationStateSaga({
  payload: {notificationId, entityId},
}: UpdateNotificationStateStartAction): SagaIterator<void> {
  try {
    yield call(requestHandler, {
      method: HttpMethods.POST,
      url: `/api/notifications/notification/?notificationId=${notificationId}`,
    });
    yield put(
      updateNotificationStateSuccess({id: entityId, changes: {state: 'read'}}),
    );
  } catch (error) {
    log.warn(error);
  }
}

export function* deleteNotificationSaga({
  payload: {notificationId, entityId},
}: DeleteNotificationStartAction): SagaIterator<void> {
  try {
    yield call(requestHandler, {
      method: HttpMethods.DELETE,
      url: `/api/notifications/notification/?notificationId=${notificationId}`,
    });
    yield put(deleteNotificationSuccess(entityId));
  } catch (error) {
    log.warn(error);
  }
}

export function* updateNotificationSaga({
  payload: {
    notificationId,
    entityId,
    status,
    comment,
    shareType,
    setSubmitting,
  },
}: UpdateNotificationStartAction): SagaIterator<void> {
  try {
    const res = yield call(requestHandler, {
      method: HttpMethods.PUT,
      url: `/api/community/share-request/?notificationId=${notificationId}`,
      data: {status, comment, shareType},
    });
    yield put(
      updateNotificationStatusSuccess({
        id: entityId,
        changes: {status, comment: comment || ''},
      }),
    );

    toast.success(res.data?.message ?? `Request ${status}`);
  } catch (error) {
    log.warn(error);
  } finally {
    setSubmitting(false);
  }
}
