import React from 'react';
import {
  ErrorImageOverlay,
  ErrorImageContainer,
  ErrorImageText,
} from './styles/error-boundary.styles';

const NoMatch: React.FC = () => (
  <ErrorImageOverlay>
    <ErrorImageContainer imageUrl="https://i.imgur.com/DWO5Hzg.png" />
    <ErrorImageText>This page does not exist</ErrorImageText>
  </ErrorImageOverlay>
);

export default NoMatch;
