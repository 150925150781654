import {ReactStripeElements} from 'react-stripe-elements';

export enum StripePaymentPlanType {
  mindfulness = 'mindfulness',
  insured = 'insured',
  uninsured = 'uninsured',
  together = 'together',
  therapy = 'therapy',
}

export enum StripePaymentPlanName {
  mindfulness = 'mindfulness',
  mindfulnessBiannually = 'mindfulness_six_months',
  mindfulnessAnnually = 'mindfulness_one_year',
  uninsuredBiweekly = 'uninsured_biweekly',
  uninsured = 'uninsured',
  uninsuredBiannually = 'uninsured_six_months',
  uninsuredAnnually = 'uninsured_one_year',
  therapy = 'therapy',
  therapyBiannually = 'therapy_six_months',
  therapyAnnually = 'therapy_one_year',
  medicalCarePlusTherapy = 'medicalcare_plus_therapy',
  medicalCarePlusTherapyBiannually = 'medicalcare_plus_therapy_six_months',
  medicalCarePlusTherapyAnnually = 'medicalcare_plus_therapy_one_year',
  medicalCare = 'medicalcare',
  medicalCareBiweekly = 'medicalcare_biweekly',
  medicalCareBiannually = 'medicalcare_six_months',
  medicalCareAnnually = 'medicalcare_one_year',
  newTherapy = 'new_therapy',
  newTherapyBiannually = 'new_therapy__six_months',
  newTherapyAnnually = 'new_therapy__one_year',
  together = 'together',
  insured = 'insured',
  togetherWeekly = 'together_weekly',
  basicMarketing = 'basic_marketting',
  mindfulnessMarketing = 'mindfulness_marketting',
  insuredBiannually = 'insured_six_months',
  insuredAnnually = 'insured_one_year',
  uninsuredBiweeklyReviewed = 'uninsured_biweekly_reviewed',
  basicOld = 'basic_old',
  basic = 'basic',
  privateInsurance = 'private_insurance',
  medicaidMedicare = 'medicaid_medicare',
}

export enum StripePaymentPlanDuration {
  weekly = 'weekly',
  biweekly = 'biweekly',
  monthly = 'monthly',
  biannually = 'six_months',
  annually = 'one_year',
}

export enum StripePaymentPlanLabel {
  medical_care_plus_therapy = 'Medical Care + Therapy',
  medical_care = 'Medical Care',
  therapy = 'Therapy',
  new_medical_care_plus_therapy = 'Medical Care + Therapy (NEW)',
  new_medical_care = 'Medical Care (NEW)',
  new_therapy = 'Therapy (NEW)',

  social = 'Social',
}

export enum StripeCurrentPaymentPlanLabel {
  mindfulness = 'Medical Care + Therapy Monthly',
  mindfulness_six_months = 'Medical Care + Therapy Biannually',
  mindfulness_one_year = 'Medical Care + Therapy Annually',
  uninsured_biweekly = 'Medical Care Biweekly',
  uninsured = 'Medical Care Monthly',
  uninsured_six_months = 'Medical Care Biannually',
  uninsured_one_year = 'Medical Care Annually',
  therapy = 'Therapy Monthly',
  therapy_six_months = 'Therapy Biannually',
  therapy_one_year = 'Therapy Annually',
  medicalcare_plus_therapy = 'Medical Care + Therapy Monthly (NEW)',
  medicalcare_plus_therapy_six_months = 'Medical Care + Therapy Biannually (NEW)',
  medicalcare_plus_therapy_one_year = 'Medical Care + Therapy Annually (NEW)',
  medicalcare_biweekly = 'Medical Care Biweekly (NEW)',
  medicalcare = 'Medical Care Monthly (NEW)',
  medicalcare_six_months = 'Medical Care Biannually (NEW)',
  medicalcare_one_year = 'Medical Care Annually (NEW)',
  new_therapy = 'Therapy Monthly (NEW)',
  new_therapy_six_months = 'Therapy Biannually (NEW)',
  new_therapy_one_year = 'Therapy Annually (NEW)',
  together = 'Social',
}

export type StripePaymentCredentials = {
  stripeResponse: ReactStripeElements.TokenResponse;
  couponCode: string;
  referralCode?: string;
  paymentPlan: StripePaymentPlanName;
  paymentPlanDuration: StripePaymentPlanDuration;
};

export type UpdateStripePlanCredentials = {
  paymentPlan: StripePaymentPlanName;
  paymentPlanDuration: StripePaymentPlanDuration;
};

export type UpdateStripeCreditCardCredentials = {
  stripeResponse: ReactStripeElements.TokenResponse;
  closeModal(): void;
};
