import {
  CHECK_USER_SESSION_START,
  LOGIN_USER_START,
  CHECK_USER_SESSION_SUCCESS,
  CHECK_USER_SESSION_FAILURE,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  SIGN_OUT_START,
  SIGN_OUT_SUCCESS,
  REQUEST_RESET_PASSWORD_TOKEN,
  REQUEST_RESET_PASSWORD,
  VALIDATE_RESET_PASSWORD_TOKEN,
  GET_UPDATED_USER_START,
  GET_UPDATED_USER_SUCCESS,
  REFERRAL_MODAL_DISPLAY,
} from '../user.constants';
import {UserActionTypes} from '../user.types';
import {
  CurrentUser,
  UserRoles,
  LoginCredentials,
  ResetPasswordTokenCredentials,
  ResetPasswordCredentials,
} from './user-auth.types';

export function getUpdatedUserStart(userRole: UserRoles): UserActionTypes {
  return {
    type: GET_UPDATED_USER_START,
    payload: userRole,
  };
}

export function getUpdatedUserSuccess(user: CurrentUser): UserActionTypes {
  return {
    type: GET_UPDATED_USER_SUCCESS,
    payload: user,
  };
}

export function checkUserSessionStart(userRole: UserRoles): UserActionTypes {
  return {
    type: CHECK_USER_SESSION_START,
    payload: userRole,
  };
}

export function checkUserSessionSuccess(user: CurrentUser): UserActionTypes {
  return {
    type: CHECK_USER_SESSION_SUCCESS,
    payload: user,
  };
}

export function checkUserSessionFailure(user: null): UserActionTypes {
  return {
    type: CHECK_USER_SESSION_FAILURE,
    payload: user,
  };
}

export function loginUserStart({
  email,
  password,
  keepSignedIn,
  redirectTo,
  setSubmitting,
}: LoginCredentials): UserActionTypes {
  return {
    type: LOGIN_USER_START,
    payload: {
      email,
      password,
      keepSignedIn,
      redirectTo,
      setSubmitting,
    },
  };
}

export function loginUserSuccess(user: CurrentUser): UserActionTypes {
  return {
    type: LOGIN_USER_SUCCESS,
    payload: user,
  };
}

export function loginUserFailure(user: null): UserActionTypes {
  return {
    type: LOGIN_USER_FAILURE,
    payload: user,
  };
}

export function signOutStart(
  role: UserRoles,
  uniqueId: string,
): UserActionTypes {
  return {
    type: SIGN_OUT_START,
    payload: {
      role,
      uniqueId,
    },
  };
}

export function signOutSuccess(user: null): UserActionTypes {
  return {
    type: SIGN_OUT_SUCCESS,
    payload: user,
  };
}

export function requestPasswordResetToken({
  email,
  setSubmitting,
}: ResetPasswordTokenCredentials): UserActionTypes {
  return {
    type: REQUEST_RESET_PASSWORD_TOKEN,
    payload: {email, setSubmitting},
  };
}

export function validatePasswordResetToken(token: string): UserActionTypes {
  return {
    type: VALIDATE_RESET_PASSWORD_TOKEN,
    payload: token,
  };
}

export function referralModalDisplay(): UserActionTypes {
  return {
    type: REFERRAL_MODAL_DISPLAY,
  };
}

export function requestPasswordReset({
  password,
  confirmPassword,
  setSubmitting,
}: ResetPasswordCredentials): UserActionTypes {
  return {
    type: REQUEST_RESET_PASSWORD,
    payload: {password, confirmPassword, setSubmitting},
  };
}
