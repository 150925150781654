import styled from 'styled-components';
import {Form} from 'semantic-ui-react';
import {PRIMARY_COLOR_LIGHT} from '_helpers/style-guide';

const StyledForm = styled(Form)`
  a:focus {
    font-weight: bold !important;
  }

  text-align: left;

  .StripeElement {
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.28571429rem;
    padding: 0.8rem;
  }

  .StripeElement--focus {
    outline: none !important;
    box-shadow: 0 0 1px ${PRIMARY_COLOR_LIGHT}!important;
  }
`;

export default StyledForm;
