import {createSelector, OutputParametricSelector} from 'reselect';
import {AppState} from '_redux/root-reducer';
import {ModelTypes, AllModelsIds, ModelsById, AdminState} from './admin.types';
import {PaginatedModels} from './admin.reducer';

function getAdmin(state: AppState): AdminState {
  return state.admin;
}

function getVisibleModelIdsData(
  state: AppState,
  filter: ModelTypes,
): {ids: AllModelsIds; model: PaginatedModels<ModelsById>} {
  return {
    ids: state.admin.models.allIds[filter],
    model: state.admin.models.byId[filter],
  };
}

function getVisibleModelIsLoading(
  state: AppState,
  filter: ModelTypes,
): boolean {
  return state.admin.models.requesting[filter];
}

export const makeGetVisibleModelData = () =>
  createSelector([getVisibleModelIdsData], ({model, ids}) => {
    const entities = Array.isArray(ids)
      ? ids.map((id: string) => model.entities[id])
      : [];
    return {
      list: entities,
      pagination: model.pagination,
    };
  });

export const makeGetVisibleModelLoading = (): OutputParametricSelector<
  AppState,
  ModelTypes,
  boolean,
  (res: boolean) => boolean
> => createSelector([getVisibleModelIsLoading], isLoading => isLoading);

export const selectPrescriberToEdit = createSelector(
  getAdmin,
  admin => admin.models.edit.prescriber,
);

export const selectAdminToEdit = createSelector(
  getAdmin,
  admin => admin.models.edit.administrator,
);

export const selectCoachToEdit = createSelector(
  getAdmin,
  admin => admin.models.edit.coach,
);

export const selectTherapistToEdit = createSelector(
  getAdmin,
  admin => admin.models.edit.therapist,
);

export const selectMedicationToEdit = createSelector(
  getAdmin,
  admin => admin.models.edit.medication,
);

export const selectRatingRoom = createSelector(
  getAdmin,
  admin => admin.models.rating.roomId,
);
