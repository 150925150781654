// eslint-disable-next-line import/no-extraneous-dependencies
import log from 'loglevel';
import {Post} from '_redux/posts/posts.types';
import {NormalizedAvailabilityInfo} from '_redux/appointments/appointments.types';
import {AppCache} from './dexie-db';
import {checkIndexedDBStorageSpace} from './dexie-utils';

export class CacheService {
  private static readonly POST_PAGE_SIZE = 11;
  private db: AppCache;
  constructor() {
    this.db = new AppCache();
  }

  retrievePostsFromCache = (
    numRequest: number,
    dateOfLastPost: number,
  ): Promise<{postInfo: Post[]; hasMore: boolean} | null> => {
    return this.db.transaction('r', this.db.posts, async () => {
      try {
        let page;
        let postInfo;
        let hasMore = false;
        // Query for first page
        if (numRequest === 0) {
          page = await this.db.posts
            .orderBy('userPost.date')
            .reverse()
            .limit(CacheService.POST_PAGE_SIZE)
            .toArray();
        } else {
          // Query for other pages
          page = await this.db.posts
            .where('userPost.date')
            .below(dateOfLastPost)
            .reverse()
            .limit(CacheService.POST_PAGE_SIZE)
            .toArray();
        }
        if (page.length) {
          if (page.length === 11) {
            hasMore = true;
            postInfo = page.slice(0, page.length - 1);
          } else {
            postInfo = page;
          }
          return {postInfo, hasMore};
        } else {
          return null;
        }
      } catch (err) {
        if (
          err.name === 'QuotaExceededError' ||
          (err.inner && err.inner.name === 'QuotaExceededError')
        ) {
          log.warn('QuotaExceeded');
        } else {
          log.warn(err);
        }
        return null;
      }
    });
  };

  deletePostsInCache = (): void => {
    this.db.transaction('rw', this.db.posts, async () => {
      try {
        await this.db.posts.clear();
      } catch (err) {
        log.warn(err);
      }
    });
  };

  getAvailabilityInfoInCache = async (
    patientEmail: string,
  ): Promise<NormalizedAvailabilityInfo[]> => {
    const results: NormalizedAvailabilityInfo[] = [];
    try {
      await this.db.transaction('r', this.db.availabilityInfo, async () => {
        await this.db.availabilityInfo.each(({email, ...rest}) => {
          if (email === patientEmail) {
            results.push(rest);
          }
        });
      });
    } catch (err) {
      log.warn(err);
    }
    return results;
  };

  putAvailabilityInfoInCache = async (
    availabilityInfo: NormalizedAvailabilityInfo & {email: string},
  ): Promise<void> => {
    const isSpaceAvailable = await checkIndexedDBStorageSpace(90);
    if (isSpaceAvailable) {
      this.db.transaction('rw', this.db.availabilityInfo, async () => {
        try {
          await this.db.availabilityInfo?.put(availabilityInfo);
        } catch (err) {
          if (
            err.name === 'QuotaExceededError' ||
            (err.inner && err.inner.name === 'QuotaExceededError')
          ) {
            log.warn('QuotaExceeded');
          } else {
            log.warn(err);
          }
        }
      });
    }
  };
}
