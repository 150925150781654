import {put, call} from 'redux-saga/effects';
import {normalize} from 'normalizr';
import * as schema from '_redux/schema';
import log from 'loglevel';
import requestHandler from '_services/api/axios-config';
import {SagaIterator} from 'redux-saga';
import {toast} from 'react-toastify';
import history from '_helpers/history';
import {HttpMethods} from '_services/api/axios.types';
import {
  CreateCoachStartAction,
  FetchCoachStartAction,
  EditCoachAction,
  DeleteCoachStartAction,
} from './admin-coach-model-actions.types';
import {
  fetchCoachesSuccess,
  fetchCoachesFailure,
  fetchCoachesStart,
  createCoachSuccess,
  fetchCoachSuccess,
  deleteCoachSuccess,
} from './admin-coach-model.actions';

export function* fetchCoachesSaga(): SagaIterator<void> {
  try {
    const {
      data: {message: coaches},
    } = yield call(requestHandler, {
      method: HttpMethods.GET,
      url: '/api/admin/mentalhealthcoach/get-all-mentalhealthcoach',
    });
    const normalizedCoaches = yield call(
      normalize,
      coaches,
      schema.arrayOfCoaches,
    );
    yield put(fetchCoachesSuccess({...normalizedCoaches, filter: 'coaches'}));
  } catch (error) {
    yield put(fetchCoachesFailure());
  }
}

export function* deleteCoachSaga({
  payload: coachId,
}: DeleteCoachStartAction): SagaIterator<void> {
  try {
    yield call(requestHandler, {
      method: HttpMethods.DELETE,
      url: `/api/admin/mentalhealthcoach/delete-mentalhealthcoach/${coachId}`,
    });
    yield put(deleteCoachSuccess({id: coachId, filter: 'coaches'}));
    toast.success('Coach deleted');
  } catch (error) {
    log.warn(error);
  }
}

export function* createCoachSaga({
  payload: {
    name,
    email,
    password,
    calendarId,
    timezone,
    setSubmitting,
    closeModal,
  },
}: CreateCoachStartAction): SagaIterator<void> {
  try {
    const {
      data: {message: coach},
    } = yield call(requestHandler, {
      method: HttpMethods.POST,
      url: '/api/admin/mentalhealthcoach/register',
      data: {
        fullName: name,
        email: email.toLowerCase(),
        password,
        calendarId,
        timezone,
      },
    });
    yield put(fetchCoachesStart());
    yield put(createCoachSuccess(coach));
    yield call(closeModal);
    toast.success('Coach added');
  } catch (error) {
    yield call(setSubmitting, false);
  }
}

export function* fetchCoachSaga({
  payload: id,
}: FetchCoachStartAction): SagaIterator<void> {
  try {
    const {
      data: {message: coach},
    } = yield call(requestHandler, {
      method: HttpMethods.GET,
      url: `/api/admin/mentalhealthcoach/get-single-mentalhealthcoach/${id}`,
    });
    yield put(fetchCoachSuccess(coach));
  } catch (error) {
    log.warn(error);
  }
}

export function* editCoachSaga({
  payload: {
    name: fullName,
    email,
    calendarId,
    timezone,
    coachId: mentalHealthCoachId,
    setSubmitting,
  },
}: EditCoachAction): SagaIterator<void> {
  try {
    yield call(requestHandler, {
      method: HttpMethods.POST,
      url: '/api/admin/mentalhealthcoach/edit-mentalhealthcoach',
      data: {
        fullName,
        email: email.toLowerCase(),
        mentalHealthCoachId,
        calendarId,
        timezone,
      },
    });
    history.push('/admin/coaches');
    toast.success('Coach edited');
  } catch (error) {
    log.warn(error);
    yield call(setSubmitting, false);
  }
}
