import React from 'react';
import {FormGroup, FormField, Checkbox} from 'semantic-ui-react';
import {FormikProps, Field} from 'formik';
import StyledSegment from '_components/common/styles/styled-segment.styles';
import StyledButton from '_components/common/styles/styled-button.styles';
import StyledForm from '_components/common/styles/styled-form.styles';
import CustomFormInput from '_components/form/custom-form-input.component';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
//@ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {LoginFormProps} from './login.types';

const LoginForm: React.FC<FormikProps<LoginFormProps>> = ({
  handleSubmit,
  isValidating,
  isSubmitting,
  handleChange,
  handleBlur,
  isValid,
}) => (
  <StyledForm
    size="large"
    onSubmit={handleSubmit}
    loading={!isValidating && isSubmitting}
  >
    <StyledSegment>
      <Field
        component={CustomFormInput}
        placeholder="Email address"
        title="Email"
        autoComplete="email"
        testId="email-error"
        type="email"
        icon="mail"
        iconPosition="left"
        name="email"
        required
      />
      <Field
        component={CustomFormInput}
        placeholder="Password"
        title="Password"
        testId="password-error"
        autoComplete="current-password"
        type="password"
        icon="lock"
        iconPosition="left"
        name="password"
        required
      />
      <FormGroup unstackable>
        <FormField width={8} css="display: flex;" inline>
          <Checkbox
            onChange={handleChange}
            onBlur={handleBlur}
            id="keepSignedIn"
            name="keepSignedIn"
            defaultChecked
            data-testid="keepSignedIn"
          />
          <label htmlFor="keepSignedIn">Keep me signed in</label>
        </FormField>
        <FormField css="display: flex; justify-content: flex-end" width={8}>
          <span>
            <Link to="/forgot">Reset Password</Link>
          </span>
        </FormField>
      </FormGroup>

      <StyledButton
        disabled={!isValid || isSubmitting}
        type="submit"
        size="massive"
        fluid
        color="black"
      >
        Submit
      </StyledButton>
    </StyledSegment>
  </StyledForm>
);

export default LoginForm;
