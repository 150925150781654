import React from 'react';

type HeroProps = {
  picture: React.ReactNode;
  children: React.ReactNode;
};

const Hero: React.FC<HeroProps> = ({picture, children}) => (
  <>
    {picture}
    {children}
  </>
);

export default Hero;
