import {call, put} from 'redux-saga/effects';
import {normalize} from 'normalizr';
import * as schema from '_redux/schema';
// eslint-disable-next-line import/no-extraneous-dependencies
import log from 'loglevel';
import requestHandler from '_services/api/axios-config';
import {SagaIterator} from 'redux-saga';
import {toast} from 'react-toastify';
import history from '_helpers/history';
import {HttpMethods} from '_services/api/axios.types';
import {UserRoles} from '_redux/user/auth/user-auth.types';
import {PaginationData} from '../../../_components/admin/admin.types';
import {
  CreateTherapistStartAction,
  DeleteTherapistStartAction,
  EditTherapistAction,
  FetchPaginatedTherapistsStartAction,
  FetchTherapistsStartAction,
  FetchTherapistStartAction,
  UpdatePaymentServiceFailureAction,
  UpdatePaymentServiceStartAction,
  VerifyTherapistAction,
} from './admin-therapist-model-actions.types';
import {
  createTherapistSuccess,
  deleteTherapistSuccess,
  fetchPaginatedTherapistsFailure,
  fetchPaginatedTherapistsSuccess,
  fetchTherapistsFailure,
  fetchTherapistsStart,
  fetchTherapistsSuccess,
  fetchTherapistSuccess,
  updatePaymentServiceFailure,
  updatePaymentServiceSuccess,
} from './admin-therapist-model.actions';
import {Therapist} from './admin-therapist-model.types';

const undefinedCheck = (value: any) => {
  if (typeof value === 'string') {
    return value === 'true';
  } else {
    return undefined;
  }
};

export type FetchPaginatedTherapistsResponse = PaginationData & {
  results: Therapist[];
};

export function* fetchPaginatedTherapistsSaga({
  payload: {countryCode, searchKeywords, page, maxResults, setSubmitting},
}: FetchPaginatedTherapistsStartAction): SagaIterator<void> {
  try {
    const {
      data: {message},
    }: {data: {message: FetchPaginatedTherapistsResponse}} = yield call(
      requestHandler,
      {
        method: HttpMethods.GET,
        url: `/api/admin/therapist/paginated-therapists?searchKeywords=${searchKeywords}&maxResults=${maxResults}&page=${page}${
          countryCode ? `&countryCode=${countryCode}` : ''
        }`,
      },
    );
    const normalizedTherapists = yield call(
      normalize,
      message.results,
      schema.arrayOfTherapists,
    );

    // Ensure the entities structure is always present
    if (!normalizedTherapists.entities.therapists) {
      normalizedTherapists.entities.therapists = {};
    }

    setSubmitting(false);
    yield put(
      fetchPaginatedTherapistsSuccess({
        ...normalizedTherapists,
        filter: 'therapists',
        pagination: message,
      }),
    );
  } catch (error) {
    yield put(fetchPaginatedTherapistsFailure());
  }
}

export function* fetchTherapistsSaga({
  payload: countryCode,
}: FetchTherapistsStartAction): SagaIterator<void> {
  try {
    const {
      data: {message: therapists},
    } = yield call(requestHandler, {
      method: HttpMethods.GET,
      url: `/api/admin/therapist/all-therapists${
        countryCode ? `?countryCode=${countryCode}` : ''
      }`,
    });
    const normalizedTherapists = yield call(
      normalize,
      therapists,
      schema.arrayOfTherapists,
    );
    yield put(
      fetchTherapistsSuccess({...normalizedTherapists, filter: 'therapists'}),
    );
  } catch (error) {
    yield put(fetchTherapistsFailure());
  }
}

export function* deleteTherapistSaga({
  payload: therapistId,
}: DeleteTherapistStartAction): SagaIterator<void> {
  try {
    yield call(requestHandler, {
      method: HttpMethods.DELETE,
      url: `/api/admin/therapist/delete-therapist/${therapistId}`,
    });
    yield put(deleteTherapistSuccess({id: therapistId, filter: 'therapists'}));
    if (!history.location.pathname.includes('/admin/therapists'))
      history.push('/admin/therapists');
    toast.success('Therapist deleted');
  } catch (error) {
    log.warn(error);
  }
}

export function* verifyTherapistSaga({
  payload: therapistId,
}: VerifyTherapistAction): SagaIterator<void> {
  try {
    yield call(requestHandler, {
      method: HttpMethods.POST,
      url: `/api/admin/therapist/verify-international`,
      data: {
        therapistId,
      },
    });
    if (!history.location.pathname.includes('/admin/therapists'))
      history.push('/admin/therapists');
    toast.success('Therapist verified');
  } catch (error) {
    log.warn(error);
  }
}

export function* createTherapistSaga({
  payload: {
    name,
    email,
    password,
    calendarId,
    states,
    timezone,
    secondaryEmail,
    setSubmitting,
    closeModal,
    isTestProvider,
  },
}: CreateTherapistStartAction): SagaIterator<void> {
  try {
    const {
      data: {message: therapist},
    } = yield call(requestHandler, {
      method: HttpMethods.POST,
      url: '/api/admin/therapist/register',
      data: {
        fullName: name,
        email: email.toLowerCase(),
        password,
        calendarId,
        doctorType: UserRoles.therapist,
        states,
        timezone,
        secondaryEmail,
        isTestProvider,
      },
    });
    yield put(fetchTherapistsStart());
    yield put(createTherapistSuccess(therapist));
    yield call(closeModal);
    toast.success('Therapist added');
  } catch (error) {
    yield call(setSubmitting, false);
  }
}

export function* fetchTherapistSaga({
  payload: id,
}: FetchTherapistStartAction): SagaIterator<void> {
  try {
    const {
      data: {message: therapist},
    } = yield call(requestHandler, {
      method: HttpMethods.GET,
      url: `/api/admin/therapist/single-therapist?therapistId=${id}`,
    });
    yield put(fetchTherapistSuccess(therapist));
  } catch (error) {
    log.warn(error);
  }
}

export function* updatePaymentServiceSaga({
  payload,
}: UpdatePaymentServiceStartAction): SagaIterator<void> {
  try {
    yield call(requestHandler, {
      method: HttpMethods.POST,
      url: `/api/admin/therapist/change-payment-service`,
      data: payload,
    });
    yield put(updatePaymentServiceSuccess());
  } catch (error) {
    log.warn(error);
    yield put(updatePaymentServiceFailure({error: `${error}`}));
  }
}

export function* updatePaymentServiceSuccessSaga(): SagaIterator<void> {
  toast.success(`Default payment method updated successfully.`);
}

export function* updatePaymentServiceFailureSaga({
  payload,
}: UpdatePaymentServiceFailureAction): SagaIterator<void> {
  toast.error(`Failed to update default payment service. ${payload.error}`);
}

export function* editTherapistSaga({
  payload: {
    name: fullName,
    email,
    calendarId,
    states,
    therapistId,
    timezone,
    secondaryEmail,
    setSubmitting,
    accountType,
    isInitialTherapyBillable,
    isManual,
    isTestProvider,
    deelContractId,
  },
}: EditTherapistAction): SagaIterator<void> {
  try {
    yield call(requestHandler, {
      method: HttpMethods.POST,
      url: `/api/admin/therapist/edit?therapistId=${therapistId}`,
      data: {
        fullName,
        email: email.toLowerCase(),
        therapistId,
        calendarId,
        secondaryEmail,
        states,
        timezone,
        accountType,
        isInitialTherapyBillable: undefinedCheck(isInitialTherapyBillable),
        isManual,
        isTestProvider,
        deelContractId,
      },
    });
    history.push('/admin/therapists');
    toast.success('Therapist edited');
  } catch (error) {
    log.warn(error);
    yield call(setSubmitting, false);
  }
}
