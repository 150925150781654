import {SagaIterator} from 'redux-saga';
import {all, call, takeEvery, takeLeading} from 'redux-saga/effects';
import {
  DELETE_NOTIFICATION_START,
  GET_NOTIFICATIONS_START,
  UPDATE_NOTIFICATION_STATE_START,
  UPDATE_NOTIFICATION_START,
} from './notifications.constants';
import {
  deleteNotificationSaga,
  getNotificationsSaga,
  updateNotificationStateSaga,
  updateNotificationSaga,
} from './notifications.sagas';

export function* onGetNotificationsStart(): SagaIterator<void> {
  yield takeLeading(GET_NOTIFICATIONS_START, getNotificationsSaga);
}

export function* onUpdateNotificationStateStart(): SagaIterator<void> {
  yield takeEvery(UPDATE_NOTIFICATION_STATE_START, updateNotificationStateSaga);
}

export function* onDeleteNotificationStart(): SagaIterator<void> {
  yield takeEvery(DELETE_NOTIFICATION_START, deleteNotificationSaga);
}

export function* onUpdateNotificationStart(): SagaIterator<void> {
  yield takeEvery(UPDATE_NOTIFICATION_START, updateNotificationSaga);
}

export function* notificationsSagas(): SagaIterator<void> {
  yield all([
    call(onGetNotificationsStart),
    call(onUpdateNotificationStateStart),
    call(onDeleteNotificationStart),
    call(onUpdateNotificationStart),
  ]);
}
