import {SagaIterator} from 'redux-saga';
import {put, call} from 'redux-saga/effects';
import requestHandler from '_services/api/axios-config';
import {HttpMethods} from '_services/api/axios.types';
import {
  FetchPatientsStartAction,
  SearchPatientsStartAction,
} from './patients-list-actions.types';
import {
  fetchPatientsSuccess,
  fetchPatientsFailure,
  searchPatientsSuccess,
  searchPatientsFailure,
} from './patients-list.actions';

export function* fetchPatientsSaga({
  payload: {role, category, numRequest},
}: FetchPatientsStartAction): SagaIterator<void> {
  try {
    const {
      data: {message: patients},
    } = yield call(requestHandler, {
      method: HttpMethods.GET,
      url: `/api/${role}/patients/?numRequest=${numRequest}${
        role === 'admin' ? `&category=${category}` : ''
      }`,
    });
    if (typeof patients === 'string') {
      yield put(
        fetchPatientsSuccess({patients: [], category, numRequest, role}),
      );
    } else {
      yield put(
        fetchPatientsSuccess({
          patients: patients.patientsList,
          category,
          numRequest,
          role,
        }),
      );
    }
  } catch (error) {
    yield put(fetchPatientsFailure({patients: [], category, numRequest, role}));
  }
}

export function* searchPatientsSaga({
  payload: {userRole, searchTerm},
}: SearchPatientsStartAction): SagaIterator<void> {
  try {
    const {
      data: {message: patients},
    } = yield call(requestHandler, {
      method: HttpMethods.GET,
      url: `/api/${userRole}/patients/search/?searchTerm=${encodeURIComponent(
        searchTerm,
      )}`,
    });
    yield put(searchPatientsSuccess(patients));
  } catch (error) {
    yield put(searchPatientsFailure());
  }
}
