export const GET_NOTIFICATIONS_START = 'GET_NOTIFICATIONS_START';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE';

export const ADD_NEW_NOTIFICATION = 'ADD_NEW_NOTIFICATION';

export const UPDATE_NOTIFICATION_STATE_START =
  'UPDATE_NOTIFICATION_STATE_START';
export const UPDATE_NOTIFICATION_STATE_SUCCESS =
  'UPDATE_NOTIFICATION_STATE_SUCCESS';

export const UPDATE_NOTIFICATION_STATUS_SUCCESS =
  'UPDATE_NOTIFICATION_STATUS_SUCCESS';

export const UPDATE_SHARE_RESPONSE_NOTIFICATION_START =
  'UPDATE_SHARE_RESPONSE_NOTIFICATION_START';

export const UPDATE_SHARE_RESPONSE_NOTIFICATION_SUCCESS =
  'UPDATE_SHARE_RESPONSE_NOTIFICATION_SUCCESS';

export const UPDATE_NOTIFICATION_START = 'UPDATE_NOTIFICATION_START';
export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS';

export const DELETE_NOTIFICATION_START = 'DELETE_NOTIFICATION_START';
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
export const DELETE_NOTIFICATION_FAILURE = 'DELETE_NOTIFICATION_FAILURE';
