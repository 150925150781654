import React, {Component, ErrorInfo} from 'react';
import * as Sentry from '@sentry/browser';
import {Link, Redirect} from 'react-router-dom';
import {
  ErrorImageOverlay,
  ErrorImageContainer,
  ErrorImageText,
} from './styles/error-boundary.styles';

export class ErrorBoundary extends Component {
  state = {hasError: false, redirect: false};

  static getDerivedStateFromError(error: Error | null): {hasError: boolean} {
    Sentry.captureException(error);
    return {hasError: true};
  }

  componentDidCatch(error: Error | null, errorInfo: ErrorInfo): void {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  componentDidUpdate(): void {
    if (this.state.hasError) {
      setTimeout(() => this.setState({redirect: true}), 5000);
    }
  }

  render(): React.ReactNode {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
    return this.state.hasError ? (
      <ErrorImageOverlay>
        <ErrorImageContainer imageUrl="https://i.imgur.com/yW2W9SC.png" />
        <ErrorImageText>
          There was an error with this page. <Link to="/">Click here</Link> to
          back to the home page or wait five seconds
        </ErrorImageText>
      </ErrorImageOverlay>
    ) : (
      this.props.children
    );
  }
}
