import {put, call, cancelled} from 'redux-saga/effects';
import requestHandler from '_services/api/axios-config';
import history from '_helpers/history';
import {SagaIterator} from 'redux-saga';
import {toast} from 'react-toastify';
import {titleCaseName} from '_helpers/utils';
import {HttpMethods} from '_services/api/axios.types';
import {getUpdatedUserStart} from '../auth/user-auth.actions';
import {UserRoles} from '../auth/user-auth.types';
import {
  StripePaymentPlanDuration,
  StripePaymentPlanType,
} from './user-payment.types';
import {
  StripePaymentStartAction,
  UpdateStripeCreditCardStartAction,
  UpdateStripePlanStartAction,
} from './user-payment-actions.types';
import {
  stripePaymentSuccess,
  stripePaymentFailure,
  updateStripePlanSuccess,
  updateStripePlanFailure,
  updateStripeCreditCardSuccess,
  updateStripeCreditCardFailure,
} from './user-payment.actions';

export function* createStripeSubscriptionSaga({
  payload: {
    stripeResponse: {token},
    couponCode,
    referralCode,
    paymentPlan,
    paymentPlanDuration,
  },
}: StripePaymentStartAction): SagaIterator<void> {
  try {
    const {
      data: {message: user},
    } = yield call(requestHandler, {
      method: HttpMethods.POST,
      url: referralCode
        ? `/api/patient/stripe/create-subscription?referral=${referralCode}`
        : '/api/patient/stripe/create-subscription',
      data: {
        token,
        couponCode,
        paymentPlan:
          paymentPlanDuration === StripePaymentPlanDuration.monthly
            ? paymentPlan
            : `${paymentPlan}_${paymentPlanDuration}`,
        __subPaymentPlan: paymentPlan.endsWith('marketting')
          ? paymentPlan.startsWith('basic')
            ? StripePaymentPlanType.uninsured
            : StripePaymentPlanType.mindfulness
          : paymentPlan,
      },
    });
    yield put(stripePaymentSuccess(user));
    history.push(
      `/${
        paymentPlan.endsWith('marketting')
          ? paymentPlan.startsWith('basic')
            ? 'basic/freetrial'
            : 'mindfulness/freetrial'
          : paymentPlan
      }/thanks`,
    );
  } catch (error) {
    yield put(stripePaymentFailure());
  } finally {
    if (yield cancelled()) {
      yield put(stripePaymentFailure());
    }
  }
}

export function* updateStripeCreditCardSaga({
  payload: {
    stripeResponse: {token},
    closeModal,
  },
}: UpdateStripeCreditCardStartAction): SagaIterator<void> {
  try {
    yield call(requestHandler, {
      method: HttpMethods.POST,
      url: '/api/patient/stripe/update-card',
      data: {
        token: {...token},
      },
    });
    yield put(getUpdatedUserStart(UserRoles.member));
    yield put(updateStripeCreditCardSuccess());
    yield call(closeModal);
    toast.success('Card has been added!');
  } catch (error) {
    yield put(updateStripeCreditCardFailure());
  } finally {
    if (yield cancelled()) {
      yield put(updateStripeCreditCardFailure());
    }
  }
}

export function* updateStripePlanSaga({
  payload: {paymentPlan, paymentPlanDuration},
}: UpdateStripePlanStartAction): SagaIterator<void> {
  try {
    yield call(requestHandler, {
      method: HttpMethods.POST,
      url: '/api/patient/stripe/update-plan',
      data: {
        paymentPlan:
          paymentPlanDuration === StripePaymentPlanDuration.monthly
            ? paymentPlan
            : `${paymentPlan}_${paymentPlanDuration}`,
        __subPaymentPlan: paymentPlan,
      },
    });
    history.push('/plans/update-success');
    yield put(getUpdatedUserStart(UserRoles.member));
    yield put(updateStripePlanSuccess());
    toast.success(
      `Plan has been updated to ${titleCaseName(
        paymentPlanDuration.replace(/_/g, ' '),
      )} ${titleCaseName(paymentPlan)}!`,
    );
  } catch (error) {
    yield put(updateStripePlanFailure());
  } finally {
    if (yield cancelled()) {
      yield put(updateStripePlanFailure());
    }
  }
}
