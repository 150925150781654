import {takeEvery, takeLatest, call, all} from 'redux-saga/effects';
import {SagaIterator} from 'redux-saga';
import {
  DELETE_MEDICATION_START,
  FETCH_MEDICATIONS_START,
  FETCH_COACHES_START,
  DELETE_COACH_START,
  FETCH_ADMINISTRATORS_START,
  DELETE_ADMINISTRATOR_START,
  FETCH_PRESCRIBERS_START,
  DELETE_PRESCRIBER_START,
  CREATE_MEDICATION,
  CREATE_COACH_START,
  CREATE_ADMINISTRATOR,
  CREATE_PRESCRIBER_START,
  FETCH_PRESCRIBER_START,
  EDIT_PRESCRIBER,
  FETCH_ADMINISTRATOR_START,
  EDIT_ADMINISTRATOR,
  FETCH_COACH_START,
  EDIT_COACH,
  FETCH_MEDICATION_START,
  EDIT_MEDICATION,
  DELETE_THERAPIST_START,
  FETCH_THERAPISTS_START,
  CREATE_THERAPIST_START,
  FETCH_THERAPIST_START,
  EDIT_THERAPIST,
  VERIFY_THERAPIST,
  UPDATE_PAYMENT_SERVICE_START,
  UPDATE_PAYMENT_SERVICE_SUCCESS,
  UPDATE_PAYMENT_SERVICE_FAILURE,
  FETCH_THERAPISTS_PAGINATED_START,
  FETCH_PRESCRIBERS_PAGINATED_START,
} from './admin.constants';
import {
  deleteMedicationSaga,
  fetchMedicationsSaga,
  createMedicationSaga,
  fetchMedicationSaga,
  editMedicationSaga,
} from './medications/admin-medication-model.sagas';

import {
  fetchPrescribersSaga,
  deletePrescriberSaga,
  createPrescriberSaga,
  fetchPrescriberSaga,
  editPrescriberSaga,
  fetchPaginatedPrescribersSaga,
} from './prescribers/admin-prescriber-model.sagas';

import {
  fetchCoachesSaga,
  deleteCoachSaga,
  createCoachSaga,
  fetchCoachSaga,
  editCoachSaga,
} from './coaches/admin-coach-model.sagas';

import {
  fetchAdminsSaga,
  deleteAdminSaga,
  fetchAdminSaga,
  editAdminSaga,
  createAdminSaga,
} from './administrators/admin-administrator-model.sagas';
import {
  fetchPaginatedTherapistsSaga,
  fetchTherapistsSaga,
  deleteTherapistSaga,
  createTherapistSaga,
  fetchTherapistSaga,
  editTherapistSaga,
  verifyTherapistSaga,
  updatePaymentServiceSaga,
  updatePaymentServiceSuccessSaga,
  updatePaymentServiceFailureSaga,
} from './therapists/admin-therapist-model.sagas';

export function* onFetchPaginatedPrescribersStart(): SagaIterator<void> {
  yield takeLatest(
    FETCH_PRESCRIBERS_PAGINATED_START,
    fetchPaginatedPrescribersSaga,
  );
}

export function* onFetchPaginatedTherapistsStart(): SagaIterator<void> {
  yield takeLatest(
    FETCH_THERAPISTS_PAGINATED_START,
    fetchPaginatedTherapistsSaga,
  );
}

export function* onFetchTherapistsStart(): SagaIterator<void> {
  yield takeLatest(FETCH_THERAPISTS_START, fetchTherapistsSaga);
}

export function* onDeleteTherapistStart(): SagaIterator<void> {
  yield takeEvery(DELETE_THERAPIST_START, deleteTherapistSaga);
}

export function* onVerifyTherapist(): SagaIterator<void> {
  yield takeEvery(VERIFY_THERAPIST, verifyTherapistSaga);
}

export function* onCreateTherapist(): SagaIterator<void> {
  yield takeEvery(CREATE_THERAPIST_START, createTherapistSaga);
}

export function* onFetchTherapistStart(): SagaIterator<void> {
  yield takeLatest(FETCH_THERAPIST_START, fetchTherapistSaga);
}

export function* onUpdatePaymentServiceStart(): SagaIterator<void> {
  yield takeLatest(UPDATE_PAYMENT_SERVICE_START, updatePaymentServiceSaga);
}

export function* onUpdatePaymentServiceSuccess(): SagaIterator<void> {
  yield takeLatest(
    [UPDATE_PAYMENT_SERVICE_SUCCESS],
    updatePaymentServiceSuccessSaga,
  );
}

export function* onUpdatePaymentServiceFailure(): SagaIterator<void> {
  yield takeLatest(
    [UPDATE_PAYMENT_SERVICE_FAILURE],
    updatePaymentServiceFailureSaga,
  );
}

export function* onEditTherapist(): SagaIterator<void> {
  yield takeLatest(EDIT_THERAPIST, editTherapistSaga);
}

export function* onFetchMedicationsStart(): SagaIterator<void> {
  yield takeLatest(FETCH_MEDICATIONS_START, fetchMedicationsSaga);
}

export function* onDeleteMedicationStart(): SagaIterator<void> {
  yield takeEvery(DELETE_MEDICATION_START, deleteMedicationSaga);
}

export function* onCreateMedication(): SagaIterator<void> {
  yield takeEvery(CREATE_MEDICATION, createMedicationSaga);
}

export function* onFetchMedicationStart(): SagaIterator<void> {
  yield takeLatest(FETCH_MEDICATION_START, fetchMedicationSaga);
}

export function* onEditMedication(): SagaIterator<void> {
  yield takeLatest(EDIT_MEDICATION, editMedicationSaga);
}

export function* onFetchCoachesStart(): SagaIterator<void> {
  yield takeLatest(FETCH_COACHES_START, fetchCoachesSaga);
}

export function* onDeleteCoachStart(): SagaIterator<void> {
  yield takeEvery(DELETE_COACH_START, deleteCoachSaga);
}

export function* onCreateCoachStart(): SagaIterator<void> {
  yield takeEvery(CREATE_COACH_START, createCoachSaga);
}

export function* onFetchCoachStart(): SagaIterator<void> {
  yield takeLatest(FETCH_COACH_START, fetchCoachSaga);
}

export function* onEditCoach(): SagaIterator<void> {
  yield takeLatest(EDIT_COACH, editCoachSaga);
}

export function* onFetchAdminsStart(): SagaIterator<void> {
  yield takeLatest(FETCH_ADMINISTRATORS_START, fetchAdminsSaga);
}

export function* onDeleteAdminStart(): SagaIterator<void> {
  yield takeEvery(DELETE_ADMINISTRATOR_START, deleteAdminSaga);
}

export function* onCreateAdmin(): SagaIterator<void> {
  yield takeEvery(CREATE_ADMINISTRATOR, createAdminSaga);
}

export function* onFetchAdministratorStart(): SagaIterator<void> {
  yield takeLatest(FETCH_ADMINISTRATOR_START, fetchAdminSaga);
}

export function* onEditAdministrator(): SagaIterator<void> {
  yield takeLatest(EDIT_ADMINISTRATOR, editAdminSaga);
}

export function* onFetchPrescribersStart(): SagaIterator<void> {
  yield takeLatest(FETCH_PRESCRIBERS_START, fetchPrescribersSaga);
}

export function* onDeletePrescriberStart(): SagaIterator<void> {
  yield takeEvery(DELETE_PRESCRIBER_START, deletePrescriberSaga);
}

export function* onCreatePrescriberStart(): SagaIterator<void> {
  yield takeEvery(CREATE_PRESCRIBER_START, createPrescriberSaga);
}

export function* onFetchPrescriberStart(): SagaIterator<void> {
  yield takeLatest(FETCH_PRESCRIBER_START, fetchPrescriberSaga);
}

export function* onEditPrescriber(): SagaIterator<void> {
  yield takeLatest(EDIT_PRESCRIBER, editPrescriberSaga);
}

export function* adminSagas(): SagaIterator<void> {
  yield all([
    call(onFetchPaginatedTherapistsStart),
    call(onFetchPaginatedPrescribersStart),
    call(onFetchTherapistsStart),
    call(onDeleteTherapistStart),
    call(onCreateTherapist),
    call(onFetchTherapistStart),
    call(onEditTherapist),
    call(onFetchMedicationsStart),
    call(onDeleteMedicationStart),
    call(onCreateMedication),
    call(onFetchMedicationStart),
    call(onEditMedication),
    call(onFetchCoachesStart),
    call(onDeleteCoachStart),
    call(onCreateCoachStart),
    call(onFetchCoachStart),
    call(onEditCoach),
    call(onFetchAdminsStart),
    call(onDeleteAdminStart),
    call(onCreateAdmin),
    call(onFetchAdministratorStart),
    call(onEditAdministrator),
    call(onFetchPrescribersStart),
    call(onDeletePrescriberStart),
    call(onCreatePrescriberStart),
    call(onFetchPrescriberStart),
    call(onEditPrescriber),
    call(onVerifyTherapist),
    call(onUpdatePaymentServiceStart),
    call(onUpdatePaymentServiceSuccess),
    call(onUpdatePaymentServiceFailure),
  ]);
}
