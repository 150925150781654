import {UserActionTypes} from '../user.types';
import {
  STRIPE_PAYMENT_START,
  STRIPE_PAYMENT_FAILURE,
  STRIPE_PAYMENT_SUCCESS,
  UPDATE_STRIPE_CREDIT_CARD_START,
  UPDATE_STRIPE_CREDIT_CARD_SUCCESS,
  UPDATE_STRIPE_CREDIT_CARD_FAILURE,
  UPDATE_STRIPE_PLAN_START,
  UPDATE_STRIPE_PLAN_SUCCESS,
  UPDATE_STRIPE_PLAN_FAILURE,
} from '../user.constants';
import {MemberProfile} from '../auth/user-auth.types';
import {
  StripePaymentCredentials,
  UpdateStripeCreditCardCredentials,
  UpdateStripePlanCredentials,
} from './user-payment.types';

export function stripePaymentStart({
  stripeResponse,
  couponCode,
  referralCode,
  paymentPlan,
  paymentPlanDuration,
}: StripePaymentCredentials): UserActionTypes {
  return {
    type: STRIPE_PAYMENT_START,
    payload: {
      stripeResponse,
      couponCode,
      referralCode,
      paymentPlan,
      paymentPlanDuration,
    },
  };
}

export function stripePaymentSuccess(user: MemberProfile): UserActionTypes {
  return {
    type: STRIPE_PAYMENT_SUCCESS,
    payload: user,
  };
}

export function stripePaymentFailure(): UserActionTypes {
  return {
    type: STRIPE_PAYMENT_FAILURE,
  };
}

export function updateStripeCreditCardStart({
  stripeResponse,
  closeModal,
}: UpdateStripeCreditCardCredentials): UserActionTypes {
  return {
    type: UPDATE_STRIPE_CREDIT_CARD_START,
    payload: {
      stripeResponse,
      closeModal,
    },
  };
}

export function updateStripeCreditCardSuccess(): UserActionTypes {
  return {
    type: UPDATE_STRIPE_CREDIT_CARD_SUCCESS,
  };
}

export function updateStripeCreditCardFailure(): UserActionTypes {
  return {
    type: UPDATE_STRIPE_CREDIT_CARD_FAILURE,
  };
}

export function updateStripePlanStart({
  paymentPlan,
  paymentPlanDuration,
}: UpdateStripePlanCredentials): UserActionTypes {
  return {
    type: UPDATE_STRIPE_PLAN_START,
    payload: {
      paymentPlan,
      paymentPlanDuration,
    },
  };
}

export function updateStripePlanSuccess(): UserActionTypes {
  return {
    type: UPDATE_STRIPE_PLAN_SUCCESS,
  };
}

export function updateStripePlanFailure(): UserActionTypes {
  return {
    type: UPDATE_STRIPE_PLAN_FAILURE,
  };
}
