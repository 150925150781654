import {Segment} from 'semantic-ui-react';
import styled from 'styled-components';

const StyledSegment = styled(Segment)<{
  border?: string;
  display?: string;
  maxWidth?: string;
  flexWrap?: string;
}>`
  border: ${(props): string => props.border || 'none!important'};
  display: ${props => props.display}, 
  max-width: ${props => props.maxWidth}; 
  flex-wrap: ${props => props.flexWrap};
`;

export default StyledSegment;
