import {MemberProfile} from '_redux/user/auth/user-auth.types';
import {
  DOWNLOAD_PATIENT_DETAILS,
  VIEW_PATIENT_START,
  VIEW_PATIENT_SUCCESS,
  VIEW_PATIENT_FAILURE,
  GET_STATE_PRESCRIBERS_START,
  GET_STATE_PRESCRIBERS_SUCCESS,
  UPDATE_PATIENT_START,
  UPDATE_PATIENT_SUCCESS,
  UPDATE_PATIENT_PRESCRIBER_START,
  CANCEL_PATIENT_PLAN,
  UPDATE_PATIENT_PLAN,
  CLEAR_PATIENT,
  CLEAR_STATE_PRESCRIBERS,
  VIEW_PATIENT_DETAILS_PDF,
  CLEAR_PATIENT_PDF_DETAILS,
  UPDATE_PATIENT_THERAPIST_START,
  DELETE_PATIENT_START,
  SET_MEMBER_RECURRING_CHARGE,
  SET_MEMBER_CURRENT_CHARGE,
  UPDATE_PATIENT_INSURANCE_START,
  UPDATE_PATIENT_INSURANCE_SUCCESS,
  UPDATE_PATIENT_INSURANCE_FAILURE,
} from '../patients.constants';
import {PatientsActionTypes} from '../patients.types';
import {
  DownloadPatientDetailsData,
  ViewPatientDetailsData,
  PrescribersInPatientState,
  UpdatePatientCredentials,
  UpdatePatientPlanCredentials,
  DeletePatientAccount,
  SetMemberRecurringCharges,
  SetMemberCurrentCharges,
  UpdatePatientInsurance,
} from './patients-profile.types';

export function downloadPatientDetails(
  params: DownloadPatientDetailsData,
): PatientsActionTypes {
  return {
    type: DOWNLOAD_PATIENT_DETAILS,
    payload: params,
  };
}

export function viewPatientDetailsPDF(pdf: Blob): PatientsActionTypes {
  return {
    type: VIEW_PATIENT_DETAILS_PDF,
    payload: pdf,
  };
}

export function clearPatientDetailsPDF(): PatientsActionTypes {
  return {
    type: CLEAR_PATIENT_PDF_DETAILS,
    payload: null,
  };
}

export function viewMemberProfileStart({
  role,
  patientEmail,
}: ViewPatientDetailsData): PatientsActionTypes {
  return {
    type: VIEW_PATIENT_START,
    payload: {
      role,
      patientEmail,
    },
  };
}

export function viewMemberProfileSuccess(
  patient: MemberProfile,
): PatientsActionTypes {
  return {
    type: VIEW_PATIENT_SUCCESS,
    payload: patient,
  };
}

export function viewMemberProfileFailure(): PatientsActionTypes {
  return {
    type: VIEW_PATIENT_FAILURE,
  };
}

export function clearPatient(): PatientsActionTypes {
  return {
    type: CLEAR_PATIENT,
    payload: null,
  };
}

export function getPrescribersInPatientStateStart(
  state: string,
): PatientsActionTypes {
  return {
    type: GET_STATE_PRESCRIBERS_START,
    payload: state,
  };
}

export function getPrescribersInPatientStateSuccess(
  prescribers: PrescribersInPatientState,
): PatientsActionTypes {
  return {
    type: GET_STATE_PRESCRIBERS_SUCCESS,
    payload: prescribers,
  };
}

export function clearPrescribersInState(): PatientsActionTypes {
  return {
    type: CLEAR_STATE_PRESCRIBERS,
    payload: [],
  };
}

export function updateMemberProfileStart(
  values: UpdatePatientCredentials,
): PatientsActionTypes {
  return {
    type: UPDATE_PATIENT_START,
    payload: values,
  };
}

export function setMemberRecurringCharges(
  values: SetMemberRecurringCharges,
): PatientsActionTypes {
  return {
    type: SET_MEMBER_RECURRING_CHARGE,
    payload: values,
  };
}

export function setMemberCurrentCharges(
  values: SetMemberCurrentCharges,
): PatientsActionTypes {
  return {
    type: SET_MEMBER_CURRENT_CHARGE,
    payload: values,
  };
}

export function deletePatientAccount(
  values: DeletePatientAccount,
): PatientsActionTypes {
  return {
    type: DELETE_PATIENT_START,
    payload: values,
  };
}

export function updateMemberProfileSuccess(
  patient: MemberProfile,
): PatientsActionTypes {
  return {
    type: UPDATE_PATIENT_SUCCESS,
    payload: patient,
  };
}

export function updatePatientPrescriberStart(
  values: UpdatePatientCredentials,
): PatientsActionTypes {
  return {
    type: UPDATE_PATIENT_PRESCRIBER_START,
    payload: values,
  };
}

export function updatePatientTherapistStart(
  values: UpdatePatientCredentials,
): PatientsActionTypes {
  return {
    type: UPDATE_PATIENT_THERAPIST_START,
    payload: values,
  };
}

export function cancelPatientPlan({
  patientId,
  patientEmail,
}: {
  patientId: string;
  patientEmail: string;
}): PatientsActionTypes {
  return {
    type: CANCEL_PATIENT_PLAN,
    payload: {patientId, patientEmail},
  };
}

export function updatePatientPlan({
  patientId,
  patientEmail,
  paymentPlan,
  couponCode,
  paymentPlanDuration,
  setSubmitting,
}: UpdatePatientPlanCredentials): PatientsActionTypes {
  return {
    type: UPDATE_PATIENT_PLAN,
    payload: {
      patientId,
      patientEmail,
      couponCode,
      paymentPlan,
      paymentPlanDuration,
      setSubmitting,
    },
  };
}

export function updatePatientInsurance(values: UpdatePatientInsurance) {
  return {
    type: UPDATE_PATIENT_INSURANCE_START,
    payload: values,
  };
}

export function updatePatientInsuranceSuccess() {
  return {
    type: UPDATE_PATIENT_INSURANCE_SUCCESS,
  };
}

export function updatePatientInsuranceFailure() {
  return {
    type: UPDATE_PATIENT_INSURANCE_FAILURE,
  };
}
