export const CHECK_USER_SESSION_START = 'CHECK_USER_SESSION_START';
export const CHECK_USER_SESSION_SUCCESS = 'CHECK_USER_SESSION_SUCCESS';
export const CHECK_USER_SESSION_FAILURE = 'CHECK_USER_SESSION_FAILURE';

export const LOGIN_USER_START = 'LOGIN_USER_START';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';

export const SIGN_OUT_START = 'SIGN_OUT_START';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';

export const GET_USER_CHAT_PROFILE_SUCCESS = 'GET_USER_CHAT_PROFILE_SUCCESS';
export const GET_USER_CHAT_PROFILE_FAILURE = 'GET_USER_CHAT_PROFILE_FAILURE';

export const STRIPE_PAYMENT_START = 'STRIPE_PAYMENT_START';
export const STRIPE_PAYMENT_SUCCESS = 'STRIPE_PAYMENT_SUCCESS';
export const STRIPE_PAYMENT_FAILURE = 'STRIPE_PAYMENT_FAILURE';

export const UPDATE_STRIPE_CREDIT_CARD_START =
  'UPDATE_STRIPE_CREDIT_CARD_START';
export const UPDATE_STRIPE_CREDIT_CARD_SUCCESS =
  'UPDATE_STRIPE_CREDIT_CARD_SUCCESS';
export const UPDATE_STRIPE_CREDIT_CARD_FAILURE =
  'UPDATE_STRIPE_CREDIT_CARD_FAILURE';

export const UPDATE_STRIPE_PLAN_START = 'UPDATE_STRIPE_PLAN_START';
export const UPDATE_STRIPE_PLAN_SUCCESS = 'UPDATE_STRIPE_PLAN_SUCCESS';
export const UPDATE_STRIPE_PLAN_FAILURE = 'UPDATE_STRIPE_PLAN_FAILURE';

export const UPDATE_ACCOUNT_START = 'UPDATE_ACCOUNT_START';
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS';

export const CLEAR_NOTIFICATIONS_START = 'CLEAR_NOTIFICATIONS_START';
export const CLEAR_NOTIFICATIONS_SUCCESS = 'CLEAR_NOTIFICATIONS_SUCCESS';
export const CLEAR_NOTIFICATIONS_FAILURE = 'CLEAR_NOTIFICATIONS_FAILURE';

export const REQUEST_RESET_PASSWORD_TOKEN = 'REQUEST_RESET_PASSWORD_TOKEN';
export const VALIDATE_RESET_PASSWORD_TOKEN = 'VALIDATE_RESET_PASSWORD_TOKEN';
export const REQUEST_RESET_PASSWORD = 'REQUEST_RESET_PASSWORD';

export const GET_UPDATED_USER_START = 'GET_UPDATED_USER_START';
export const GET_UPDATED_USER_SUCCESS = 'GET_UPDATED_USER_SUCCESS';

export const SET_CURRENT_CHAT_CHANNEL = 'SET_CURRENT_CHAT_CHANNEL';
export const SEND_CHAT_PUSH_NOTIFICATION = 'SEND_CHAT_PUSH_NOTIFICATION';

export const LEAVE_VIDEO_CALL = 'LEAVE_VIDEO_CALL';

export const REFERRAL_MODAL_DISPLAY = 'REFERRAL_MODAL_DISPLAY';

export const SAVE_PROVIDER_VIDEO_CALL_JOIN_LOG_START =
  'SAVE_PROVIDER_VIDEO_CALL_JOIN_LOG_START';
export const SAVE_PROVIDER_VIDEO_CALL_JOIN_LOG_SUCCESS =
  'SAVE_PROVIDER_VIDEO_CALL_JOIN_LOG_SUCCESS';
export const SAVE_PROVIDER_VIDEO_CALL_JOIN_LOG_FAILURE =
  'SAVE_PROVIDER_VIDEO_CALL_JOIN_LOG_FAILURE';

export const SAVE_PROVIDER_VIDEO_CALL_LEAVE_LOG_START =
  'SAVE_PROVIDER_VIDEO_CALL_LEAVE_LOG_START';
export const SAVE_PROVIDER_VIDEO_CALL_LEAVE_LOG_SUCCESS =
  'SAVE_PROVIDER_VIDEO_CALL_LEAVE_LOG_SUCCESS';
export const SAVE_PROVIDER_VIDEO_CALL_LEAVE_LOG_FAILURE =
  'SAVE_PROVIDER_VIDEO_CALL_LEAVE_LOG_FAILURE';
