import React from 'react';
import {FormField, Label, Icon, Input} from 'semantic-ui-react';
import {FieldProps} from 'formik';
import {SemanticICONS} from 'semantic-ui-react/dist/commonjs/generic';
import {UserFormProps} from './form.types';

type CustomFormInputProps = {
  placeholder: string;
  title: string;
  autoComplete?: string | undefined;
  testId: string;
  type: string;
  required?: boolean;
  icon?: SemanticICONS;
  list?: string;
  iconPosition?: 'left' | undefined;
  isEdit?: boolean;
};

const CustomFormInput: React.FC<FieldProps<UserFormProps> &
  CustomFormInputProps> = ({
  field,
  form: {touched, errors},
  placeholder,
  title,
  autoComplete = undefined,
  testId,
  type,
  required = false,
  icon,
  list,
  isEdit = false,
  iconPosition = undefined,
}) => {
  const errorAndTouched = isEdit
    ? !!errors[field.name]
    : !!errors[field.name] && !!touched[field.name];

  return (
    <FormField required={required} error={errorAndTouched}>
      {errorAndTouched ? (
        <Label basic color="red" data-testid={testId} pointing="below">
          {errors[field.name]}
        </Label>
      ) : null}
      <label htmlFor={field.name}>
        {title} {required ? '(required)' : ''}
      </label>
      <Input
        type={type}
        {...field}
        id={field.name}
        fluid
        iconPosition={iconPosition}
        placeholder={placeholder}
      >
        {icon ? <Icon name={icon} /> : null}
        <input list={list} autoComplete={autoComplete} />
      </Input>
    </FormField>
  );
};

export default CustomFormInput;
