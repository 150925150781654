import {Header} from 'semantic-ui-react';
import styled from 'styled-components';

const StyledHeader = styled(Header)`
  font-size: 3.5rem !important;

  .uppercase {
    text-transform: uppercase;
  }
`;

export default StyledHeader;
