import {combineReducers} from 'redux';
import {
  FETCH_POSTS_SUCCESS,
  SET_POST_LAST_FETCHED,
  SET_HAS_MORE_POSTS,
  FETCH_POSTS_REQUESTING,
  DELETE_POST_SUCCESS,
} from './posts.constants';
import {
  PostsDictionary,
  PostsIdList,
  PostUpdateStateActions,
  PostMetaUpdateStateActions,
  FetchPostsRequestingAction,
} from './posts.types';

function postsById(
  state: PostsDictionary = {},
  {type, payload}: PostUpdateStateActions,
): PostsDictionary {
  switch (type) {
    case FETCH_POSTS_SUCCESS: {
      if (typeof payload !== 'string') {
        return {
          ...state,
          ...payload.entities.posts,
        };
      }
      return state;
    }
    case DELETE_POST_SUCCESS: {
      if (typeof payload === 'string') {
        const stateCopy = {...state};
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const {[payload]: _, ...rest} = stateCopy;
        return {
          ...rest,
        };
      }
      return state;
    }
    default:
      return state;
  }
}

function allPostsIds(
  state: PostsIdList = [],
  {type, payload}: PostUpdateStateActions,
): PostsIdList {
  switch (type) {
    case FETCH_POSTS_SUCCESS: {
      if (typeof payload !== 'string') {
        const stateCopy = [...state, ...payload.result];
        return Array.from(new Set(stateCopy));
      }
      return state;
    }
    case DELETE_POST_SUCCESS: {
      if (typeof payload === 'string') {
        return state.filter(id => id !== payload);
      }
      return state;
    }
    default:
      return state;
  }
}

function postsMeta(
  state: {hasMore: boolean; lastFetched: number | null} = {
    hasMore: true,
    lastFetched: null,
  },
  {type, payload}: PostMetaUpdateStateActions,
): {hasMore: boolean; lastFetched: number | null} {
  switch (type) {
    case SET_POST_LAST_FETCHED: {
      if (typeof payload === 'number' || payload == null) {
        return {
          ...state,
          lastFetched: payload,
        };
      }
      return state;
    }
    case SET_HAS_MORE_POSTS: {
      if (typeof payload === 'number' || payload === null) {
        return state;
      }
      return {
        ...state,
        hasMore: payload,
      };
    }
    default:
      return state;
  }
}

function postsRequesting(
  state = false,
  {type, payload}: FetchPostsRequestingAction,
): boolean {
  switch (type) {
    case FETCH_POSTS_REQUESTING:
      return payload;
    default:
      return state;
  }
}

const postsReducer = combineReducers({
  byId: postsById,
  allIds: allPostsIds,
  meta: postsMeta,
  requesting: postsRequesting,
});

// const suppliersById = (state = {}, action) => {
//     switch (action.type) {
//         case SuppliersActionTypes.DELETE_UNIT_SUPPLIER_SUCCESS: {
//             const stateCopy = { ...state }
//             delete stateCopy[action.payload]
//             return {
//                 ...stateCopy,
//             }
//         }
//         case SuppliersActionTypes.EDIT_UNIT_SUPPLIER_SUCCESS: {
//             const stateCopy = { ...state }
//             const supplierProfile = {
//                 ...stateCopy[action.payload.id].suppliers.profile,
//             }
//             const updatedProfile = Object.assign(supplierProfile, {
//                 ...action.payload.profile,
//             })
//             stateCopy[action.payload.id].suppliers.profile = updatedProfile
//             return {
//                 ...stateCopy,
//             }
//         }
//         case SuppliersActionTypes.RATE_UNIT_SUPPLIER_SUCCESS: {
//             const stateCopy = { ...state }
//             stateCopy[action.payload.id].suppliers.supplyRating =
//                 action.payload.rating
//             return {
//                 ...stateCopy,
//             }
//         }
//         default:
//             return state
//     }
// }

// const allSupplierIds = (state = [], action) => {
//     switch (action.type) {
//         case SuppliersActionTypes.GET_UNIT_SUPPLIERS_SUCCESS:
//             return action.payload.result
//         case SuppliersActionTypes.DELETE_UNIT_SUPPLIER_SUCCESS:
//             return state.filter(id => id !== action.payload)
//         default:
//             return state
//     }
// }

export default postsReducer;
