import {Grid} from 'semantic-ui-react';
import styled from 'styled-components';

const StyledGrid = styled(Grid)<{paddingtop?: string}>`
  margin: 0 !important;
  width: 100%;
  padding-top: ${(props): string =>
    props.paddingtop ? props.paddingtop : props.theme.space.XXXL} !important;

  @media only screen and (max-width: 767px) {
    padding-top: ${(props): string =>
      props.paddingtop ? props.paddingtop : props.theme.space.L} !important;
  }
`;

export default StyledGrid;
