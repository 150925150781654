import {put, call, fork} from 'redux-saga/effects';
import Cookies from 'js-cookie';
import log from 'loglevel';
import requestHandler from '_services/api/axios-config';
import history from '_helpers/history';
import {SagaIterator} from 'redux-saga';
import {toast} from 'react-toastify';
import {
  signOutFirebaseUser,
  offNotificationListenerFromFirebase,
} from '_services/firebase/firebase.utils';
import {getIndexPageRedirectRoute, isCoach} from '_helpers/utils';
import {deleteCachePosts} from '_redux/posts/posts.actions';
import {HttpMethods} from '_services/api/axios.types';
import {getNotificationsStart} from '_redux/notifications/notifications.actions';
import {
  CheckUserSessionStartAction,
  LoginUserStartAction,
  SignOutStartAction,
  ResetPasswordTokenAction,
  ResetPasswordAction,
  ValidateResetPasswordTokenAction,
  GetUpdatedUserStartAction,
} from './user-auth-actions.types';
import {
  checkUserSessionSuccess,
  checkUserSessionFailure,
  loginUserSuccess,
  loginUserFailure,
  signOutSuccess,
  getUpdatedUserSuccess,
} from './user-auth.actions';

export function* getUpdatedUserSaga({
  payload: userRole,
}: GetUpdatedUserStartAction): SagaIterator<void> {
  try {
    const {
      data: {message: user},
    } = yield call(requestHandler, {
      method: HttpMethods.GET,
      url: `/api/${userRole}/auth`,
    });
    yield put(getUpdatedUserSuccess(user));
  } catch (error) {
    log.warn(error);
  }
}

export function* checkUserSessionSaga({
  payload: userRole,
}: CheckUserSessionStartAction): SagaIterator<void> {
  try {
    const {
      data: {message: user},
    } = yield call(requestHandler, {
      method: HttpMethods.GET,
      url: `/api/${userRole}/auth`,
    });

    yield put(checkUserSessionSuccess(user));
    yield fork(Cookies.set, 'userRole', user.role);
    if (isCoach(user)) {
      yield put(getNotificationsStart({page: 0, limit: 10}));
    }
  } catch (error) {
    yield put(checkUserSessionFailure(null));
    yield fork(Cookies.remove, 'userRole');
    yield fork(signOutFirebaseUser);
  }
}

export function* loginUserSaga({
  payload: {email, password, keepSignedIn, redirectTo, setSubmitting},
}: LoginUserStartAction): SagaIterator<void> {
  try {
    const {
      data: {message: user},
    } = yield call(requestHandler, {
      method: HttpMethods.POST,
      url: '/api/login',
      data: {
        email: email.toLowerCase(),
        password,
        keepSignedIn,
      },
    });

    if (user.role === 'therapist' || user.role === 'prescriber') {
      toast.error('Please login via www.wellnite.co instead.');
      yield call(setSubmitting, false);
      return;
    }

    yield put(loginUserSuccess(user));
    toast.success('You are logged in');
    yield fork(Cookies.set, 'userRole', user.role);
    if (!redirectTo) {
      redirectTo = yield call(getIndexPageRedirectRoute, user);
    }
    history.push(redirectTo);
  } catch (error) {
    yield call(setSubmitting, false);
    yield put(loginUserFailure(null));
  }
}

export function* signOutStartSaga({
  payload: {role, uniqueId},
}: SignOutStartAction): SagaIterator<void> {
  try {
    yield call(requestHandler, {
      method: HttpMethods.GET,
      url: `/api/${role}/logout`,
    });
    yield fork(Cookies.remove, 'userRole');
    yield fork(
      offNotificationListenerFromFirebase,
      'notifications',
      uniqueId || '',
    );
    yield fork(signOutFirebaseUser);
    yield put(signOutSuccess(null));
    if (/^(admin|patient)$/.test(role)) {
      yield put(deleteCachePosts());
    }
    history.push('/login');
  } catch (error) {
    log.warn(error);
  }
}

export function* requestResetPasswordTokenSaga({
  payload: {email, setSubmitting},
}: ResetPasswordTokenAction): SagaIterator<void> {
  try {
    yield call(requestHandler, {
      method: HttpMethods.POST,
      url: '/api/forgot',
      data: {email: email.toLowerCase()},
    });
    history.push('/login');
    toast.success(`A mail has been sent to ${email.toLowerCase()}`);
  } catch (error) {
    yield call(setSubmitting, false);
  }
}

export function* validateResetPasswordTokenSaga({
  payload: token,
}: ValidateResetPasswordTokenAction): SagaIterator<void> {
  try {
    yield call(requestHandler, {
      method: HttpMethods.POST,
      url: `/api/forgot/token/${token}`,
    });
    history.push('/forgot/reset-password');
  } catch (error) {
    history.push('/forgot');
  }
}

export function* requestResetPasswordSaga({
  payload: {password: newPassword, confirmPassword, setSubmitting},
}: ResetPasswordAction): SagaIterator<void> {
  try {
    yield call(requestHandler, {
      method: HttpMethods.POST,
      url: '/api/forgot/reset_password',
      data: {newPassword, confirmPassword},
    });
    history.push('/login');
    toast.success(
      'Your password has been updated. You can now use it to login',
    );
  } catch (error) {
    yield call(setSubmitting, false);
  }
}
