import {createSelector} from 'reselect';
import {AppState} from '_redux/root-reducer';
import {
  getUserRoleForHeaderMenu,
  isMember,
  isDoctor,
  isTherapist,
  isCoach,
  isAdmin,
} from '_helpers/utils';
import {UserState} from './user.types';

function getUser(state: AppState): UserState {
  return state.user;
}

export const selectUserProfile = createSelector(getUser, user => user.current);

export const selectUserEmail = createSelector(
  getUser,
  user => user.current?.email,
);

export const selectPatientId = createSelector(getUser, user => {
  if (user.current && isMember(user.current)) return user.current.patientId;
  return '';
});

export const selectPrescriberId = createSelector(getUser, user => {
  if (user.current && isDoctor(user.current)) return user.current.prescriberId;
  return '';
});

export const selectTherapistId = createSelector(getUser, user => {
  if (user.current && isTherapist(user.current))
    return user.current.therapistId;
  return '';
});

export const selectCoachId = createSelector(getUser, user => {
  if (user.current && isCoach(user.current))
    return user.current.mentalHealthCoachId;
  return '';
});

export const selectUniqueId = createSelector(getUser, user => {
  if (user.current) {
    return isCoach(user.current)
      ? user.current.mentalHealthCoachId
      : isTherapist(user.current)
      ? user.current.therapistId
      : isDoctor(user.current)
      ? user.current.prescriberId
      : isAdmin(user.current)
      ? user.current.adminId
      : user.current.patientId;
  }
  return '';
});

export const selectUserRoleForHeader = createSelector(getUser, user =>
  getUserRoleForHeaderMenu(user.current),
);

export const selectUserIsRequesting = createSelector(
  getUser,
  user => user.requesting,
);

export const selectUserSessionIsRequesting = createSelector(
  getUser,
  user => user.sessionRequesting,
);

export const selectUserChatProfile = createSelector(
  getUser,
  user => user.chat.profile,
);

export const selectUserChatChannel = createSelector(
  getUser,
  user => user.chat.currentChannel,
);

export const selectUserReferralModalDisplay = createSelector(
  getUser,
  user => user.referralModal,
);
