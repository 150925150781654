import React from 'react';
import {ListItem, List} from 'semantic-ui-react';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
//@ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import styled from 'styled-components';
import {PRIMARY_COLOR} from '_helpers/style-guide';

type PlanDescriptionsProps = {
  descriptions: string[];
};

const PlanDescriptions: React.FC<PlanDescriptionsProps> = ({descriptions}) => (
  <List
    as="ol"
    css={`
      text-align: left;
      font-style: italic;
      color: ${PRIMARY_COLOR};
      font-size: 1rem;
      list-style-type: none;
      &&&& li {
        padding: 0.6rem;
      }
      &&&& li::marker {
        content: '-';
      }
    `}
  >
    {descriptions.map((listItem, index) => (
      <ListItem as="li" key={`${index}OfListItem`}>
        {listItem}
      </ListItem>
    ))}
  </List>
);

export default PlanDescriptions;
