import React from 'react';
import {SvgIcon} from './SvgIcon';

export const CopyIcon: SvgIcon = ({
  width = 25,
  height = 13,
  strokeColor = 'text-white',
  strokeWidth = 1.2,
  fillColor = '#00000000',
  classes,
  onClick,
}) => (
  <svg
    data-cy="copy-link"
    onClick={onClick}
    width={width}
    height={height}
    fill={fillColor}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={`stroke-current ${strokeColor} ${classes}`}
  >
    <path
      strokeWidth={strokeWidth}
      d="M6.5 15.25C5.5335 15.25 4.75 14.4665 4.75 13.5V6.75C4.75 5.64543 5.64543 4.75 6.75 4.75H13.5C14.4665 4.75 15.25 5.5335 15.25 6.5"
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      strokeWidth={strokeWidth}
      d="M8.75 10.75C8.75 9.64543 9.64543 8.75 10.75 8.75H17.25C18.3546 8.75 19.25 9.64543 19.25 10.75V17.25C19.25 18.3546 18.3546 19.25 17.25 19.25H10.75C9.64543 19.25 8.75 18.3546 8.75 17.25V10.75Z"
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
